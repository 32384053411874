import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { environment } from 'src/environments/environment';
import { MeetingApiService } from '../../../services/meeting-api/meeting-api.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';

@Component({
  selector: 'app-onboarding-stripe',
  templateUrl: './onboarding-stripe.component.html',
  styleUrls: ['./onboarding-stripe.component.scss'],
})
export class OnboardingStripeComponent implements OnInit {
  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  hasCode = false;

  stripe: any;
  paymentCardNumber: any;
  paymentCardExpiry: any;
  paymentCardCvc: any;

  error: string;
  isValidCardNumber: boolean = false;
  isValidCardExpiry: boolean = false;
  isValidCardCvc: boolean = false;


  disabledButton = false;
  loading = false;

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    public onboardingStateService: OnboardingStateService,
    private renderer2: Renderer2,
    private meetingService: MeetingApiService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe((event) => {
      this.handleForm(event);
    });
  }

  ngOnInit(): void {
    let script1 = this.renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.async = true;
    script1.src = `https://js.stripe.com/v3`;
    // script1.src = "../../../../assets/libraries/doctorGo_webcare_we2_v20201123a.js";
    this.renderer2.appendChild(document.head, script1);

    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: '--',
          screen_class: '--',
        },
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding Payment Started', null);

    const isStripeLoaded = new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window['Stripe']) {
          resolve(true);
          clearInterval(interval);
        }
      }, 1000);
    });

    if (isPlatformBrowser(this.platformId)) {
      isStripeLoaded.then(async () => {
        this.stripe = window['Stripe'](environment.pkStripe);

        // Create an elements group from the Stripe instance, passing the clientSecret (obtained in step 2) and appearance (optional).
        const elements = this.stripe.elements();

        // Create and mount the Payment Element
        this.paymentCardNumber = elements.create('cardNumber', {placeholder: 'Número de la tarjeta'});
        this.paymentCardNumber.mount('#payment-element');

        this.paymentCardExpiry = elements.create('cardExpiry', {placeholder: 'Fecha de caducidad'});
        this.paymentCardExpiry.mount('#payment-card-expiry');

        this.paymentCardCvc = elements.create('cardCvc', {placeholder: 'CVV'});
        this.paymentCardCvc.mount('#payment-card-cvc');


        this.paymentCardNumber.on('change', (event: any) => {
          this.isValidCardNumber = !event.error && event.complete === true;
        });

        this.paymentCardExpiry.on('change', (event: any) => {
          this.isValidCardExpiry = !event.error && event.complete === true;
        });

        this.paymentCardCvc.on('change', (event: any) => {
          this.isValidCardCvc = !event.error && event.complete === true;
        });

      });
    }
  }

  handleForm($e) {
    this.disabledButton = true;
    this.error = '';
    this.loading = true;
    this.segmentService.sendSegmentEvent('Onboarding Payment Submitted', null);

    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.paymentCardNumber,
        billing_details: {
          name: this.onboardingStateService.personalDataForm.controls.name
            .value,
          // address: 'Dirección de prueba',
          email: this.onboardingStateService.contactForm.controls.email.value,
          phone: this.onboardingStateService.contactForm.controls.phone.value,
        },
      })
      .then((result) => {
        const code = this.onboardingStateService.getCode()
        this.meetingService
          .processPaymentMethod(
            result.paymentMethod.id,
            this.onboardingStateService.dniForm.controls.document.value,
            code
          )
          .subscribe(
            (response) => {
              this.disabledButton = false;
              this.segmentService.sendSegmentEvent('Cuenta Verificada - SIGNUP', null);
              this.continue.emit(true);
            },
            (err) => {
              this.loading = false;
              this.disabledButton = false;
              this.error =
                'Hubo un error al procesar el pago. Inténtalo de nuevo';
              this.segmentService.sendSegmentEvent(
                'Onboarding Payment Error',
                err.error
              );
            }
          );

        // Handle result.error or result.paymentMethod
      })
      .catch(err => {
        this.disabledButton = false;
        this.loading = false;
      })
  }

  ngOnDestroy() {
    this.renderer2.removeChild(document.head, document.head.lastChild);
  }
}
