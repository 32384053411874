<section class="gap-16 w-100 d-flex flex-column">
  <ng-container *ngIf="promo || promoVitaldent">
    <div class="header">
      <div class="title">{{ header.title }}</div>
      <div class="body">
        <div>
          {{ header.desc }}
        </div>
      </div>
    </div>
    <app-onboarding-blue-box class="blue-box">
      <div class="item" *ngFor="let feature of features">
        <div>
          <img src="../../../../../../assets/images/onboarding/check.svg" alt="N/A" />
        </div>
        <div class="item-text">
          <div class="bolded">
            <b>{{ feature.title }}</b>
          </div>
          {{ feature.desc }}
        </div>
      </div>
    </app-onboarding-blue-box>
  </ng-container>

  <ng-container *ngIf="invEmp">
    <app-alert-screen class="h-100" [title]="title"></app-alert-screen>
  </ng-container>

  <app-onboarding-form-error *ngIf="error">{{ error }}</app-onboarding-form-error>
</section>



<app-sign-up-footer buttonText="CONTINUAR Y ACCEDER"
  (buttonClick)="continueDebouncer.next()" [buttonValid]="true"></app-sign-up-footer>
