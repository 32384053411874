import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';

@Component({
  selector: 'app-universal-app',
  templateUrl: './universal-app.component.html',
  styleUrls: ['./universal-app.component.scss'],
})
export class UniversalAppComponent implements OnInit {
  constructor(
    private router: Router,
    private utils: FunctionUtilsService
  ) { }

  ngOnInit(): void {
    if (!this.utils.getIsMobile()) {
      this.router.navigateByUrl('/')
    }
  }
}
