<main class="breadcrumb-doctorgo" [ngClass]="display ? 'd-flex show' : 'd-none hide'">
  <section class="container d-flex p-4 gap-4 bg-gray align-items-center">
    <span>
      <a href="/">Inicio </a>
    </span>
    <span> /</span>

    <span *ngFor="let item of data; let i = index">
      <a class="selected" *ngIf="i === data.length - 1">{{ item.title }}</a>
      <a [href]="item.route" *ngIf="i !== data.length - 1">{{item.title}}</a>
      <span *ngIf="i !== data.length - 1"> /</span>
    </span>
  </section>
</main>
