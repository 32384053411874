import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnboardingTokens, RootUserData } from 'src/server/interfaces/meetingInterfaces';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { UserType } from '../../types/onboardingTypes';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStateService {
  MOBILE_PATTERN = /^\d{9}$/;
  DNI_PATTERN = /^[XYZxyz]?\d{7,8}[A-Za-z]$/;
  personalDataForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    surname1: new FormControl(null, [Validators.required]),
    surname2: new FormControl(null),
    birthDate: new FormControl(null, [Validators.required]),
    sex: new FormControl(null, [Validators.required]),
  });
  dniForm = new FormGroup({
    nieDni: new FormControl('dni', [Validators.required]),
    document: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.DNI_PATTERN),
    ]),
  });
  contactForm = new FormGroup({
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.MOBILE_PATTERN),
    ]),
    email: new FormControl(null, [Validators.required]),
  });

  communicationsForm = new FormGroup({
    privacityPolicies: new FormControl(null, [Validators.requiredTrue]),
    contractConditions: new FormControl(null, [Validators.requiredTrue]),
    communications: new FormControl(null),
  });

  form = new FormGroup({
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.MOBILE_PATTERN),
    ]),
    document: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.DNI_PATTERN),
    ]),
    nieDni: new FormControl('dni', [Validators.required]),
  });

  verificationForm = new FormGroup({
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.MOBILE_PATTERN),
    ]),
    nid: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.DNI_PATTERN),
    ]),
    nid_type: new FormControl('dni', [Validators.required]),
    user_type: new FormControl(null, [Validators.required]),
    terms: new FormControl(null, [Validators.required]),
    invitation: new FormControl(null, [Validators.required]),
  });

  promotionForm = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });

  onboardingTokens: OnboardingTokens;
  isYoigo = new BehaviorSubject<boolean>(false);
  userResponse: RootUserData;
  metadataMonthPrice: { price: number; trialText: string } | null = null;
  hideBackButton = false;
  userType: UserType = UserType.standard;

  stackType = '';

  constructor(private segmentService: SegmentService) {

    this.isYoigo
    .pipe(distinctUntilChanged())
    .subscribe(isYoigo => {


    this.segmentService.sendSegmentIdentifyEvent(this.dniForm.value.document || null, {
      isYoigo: isYoigo,
    });

  })
}

  // return 1 if m and 0 if f
  getGender(st: string) {
    if (st === 'm') {
      return 1;
    } else if (st === 'f') {
      return 0;
    } else return undefined;
  }

  /**
   * Return invitation or promotion code set by user
   */
  getCode() {
    if (this.promotionForm.value.code) {
      return this.promotionForm.value.code;
    }

    if (this.verificationForm.value.invitation) {
      return this.verificationForm.value.invitation;
    }

    return '';
  }

  getMonth(verbosity: boolean = false) {
    if (this.metadataMonthPrice) {
      return this.metadataMonthPrice.trialText;
    }

    if (this.isYoigo.value) return verbosity ? `2 meses` : '';

    return verbosity ? `1 mes` : '';
  }

  getPrice() {
    if (this.metadataMonthPrice) {
      return this.metadataMonthPrice.price;
    }

    return 10;
  }

  getPriceWithoutPromotion() {
    return 10;
  }
}
