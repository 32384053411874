<section class="gap-32 text-center d-flex flex-column">
  <h1 class="fs-20">Añade los datos de tu tarjeta</h1>

  <section class="gap-8 text-center d-flex flex-column">
    <span class="fs-14">
      Para finalizar tu subscripción y comenzar a disfrutar de todos los
      servicios de DoctorGO.
    </span>
  </section>

  <form class="d-flex flex-column gap-16">
    <div class="form-field">
      <div id="payment-element" class="stripe-custom-element"></div>
    </div>
    <div class="d-flex gap-16">
      <div class="form-field">
        <div id="payment-card-expiry" class="stripe-custom-element"></div>
      </div>
      <div class="form-field">
        <div id="payment-card-cvc" class="stripe-custom-element"></div>
      </div>
    </div>
    <app-onboarding-form-error *ngIf="error">
      {{ error }}
    </app-onboarding-form-error>

    <div class="href-stripe">
      <label>
        El pago se realizará de forma segura gracias a la plataforna
        <img src="/assets/images/onboarding/stripe.svg" alt="" />.
      </label>
    </div>
  </form>
</section>

<app-sign-up-footer
  (buttonClick)="continueDebouncer.next($event)"
  [buttonText]="loading ? '<img class=spinner-onboarding width=26 src=/assets/images/Spinner.svg alt=Cargando />' : 'CONTINUAR'"
  [buttonValid]="
    isValidCardNumber && isValidCardExpiry && isValidCardCvc && !disabledButton && !loading
  "
></app-sign-up-footer>
