import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[focusAfter]',
})
export class FocusAfterDirective implements OnInit {
  @Input() focusAfter: number = 0;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, this.focusAfter);
  }
}
