import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding-wellcome',
  templateUrl: './onboarding-wellcome.component.html',
  styleUrls: ['./onboarding-wellcome.component.scss'],
})
export class OnboardingWellcomeComponent implements OnInit {
  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    private onboardingStateService: OnboardingStateService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueClick();
    });
  }

  ngOnInit(): void {
    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'CUENTA CREADA',
          screen_class: 'CUENTA CREADA',
        },
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding Activation Started', {
      data: {
        screen: 'bullets',
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding Finished', {
      data: {
        finished_reason: 'SIGNUP',
      },
    });
  }

  continueClick() {
    // this.continue.emit(true);

    const event = {
      action: Actions.CLOSE,
      payload: {
        session_token:
          this.onboardingStateService.onboardingTokens.user.session_token,
        user_hash: this.onboardingStateService.onboardingTokens.user.user_hash,
        user_token: this.onboardingStateService.onboardingTokens.user.user_token,
        action: "doctorgo://www.doctorgo.es/app/chat?speciality=1"
      },
    }

    this.webviewEvents.emitEvent(event);
    this.segmentService.sendSegmentEvent('Onboarding Activation Submitted', {
      data: {
        nativeAction: event.action
      }
    });

  }
}
