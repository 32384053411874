import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient) {}

  sendData(data: number) {
    return this.http.post(
      `${environment.backendUrlApi}/contract/microsite-web`,
      data,
      { responseType: 'text' }
    );
  }

  sendDataEmail(data: any) {
    return this.http.post(`${environment.backendUrlApi}/sendmail`, data, {
      responseType: 'text',
    });
  }

  sendContractStartedEmail(data: any) {
    if (!data['email']) data['email'] = '';

    return this.http.post(`${environment.backendUrlApi}/sendmail`, data, {
      responseType: 'text',
    });
  }

  sendHiringData(data: any) {
    return this.http.post(`api/hiring/send-register-data`, {
      data,
      responseType: 'text',
    });
  }
}
