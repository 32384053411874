import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class OnboardingInterceptor implements HttpInterceptor {


  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (isPlatformBrowser(this.platformId)) {
      if (req.url.includes('api/onboarding')){
        const token = localStorage.getItem('onboarding-token');
        if (token) {
          const headers = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });

          return next.handle(headers);
        } else {
          return next.handle(req);
        }
      } else {
        return next.handle(req)
      }

    } else {
      return next.handle(req);
    }

  }



}
