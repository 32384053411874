import { AppPriceComponent } from './components/app-price-component/app-price-component.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogEntriesComponent } from './components/blogentries/blogentries.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CallmeFormComponent } from './components/callme-form/callme-form.component';
import { CommentsComponent } from '../components/home-v3/comments/comments.component';
import { CommonModule } from '@angular/common';
import { DeviceStoresComponent } from './components/device-stores/device-stores.component';
import { FamilyInsuranceDkvComponent } from './components/family-insurance-dkv/family-insurance-dkv.component';
import { FooterComponent } from './components/footer/footer.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { DanaModalMasInfoComponent } from './components/dana-modal-mas-info/dana-modal-mas-info.component';
import { HealthZigzagComponent } from '../components/online-health-v2/health-zigzag/health-zigzag.component';
import { IconTitleComponent } from './components/icon-title/icon-title.component';
import { IconTitleInlineComponent } from './components/icon-title-inline/icon-title-inline.component';
import { InsuranceComponentComponent } from './components/insurance-component/insurance-component.component';
import { IsaludBannerComponent } from './components/isalud-banner/isalud-banner.component';
import { MaterialModuleShared } from './material.module';
import { NavbarDesktopComponent } from './components/navbar-desktop/navbar-desktop.component';
import { NavbarMobileComponent } from './components/navbar-mobile/navbar-mobile.component';
import { NgModule } from '@angular/core';
import { NoYoigoClientComponent } from './components/no-yoigo-client/no-yoigo-client.component';
import { PetBannerComponent } from './components/pet-banner/pet-banner.component';
import { PetsBannerComponent } from './components/pets-banner/pets-banner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SafePipe } from '../components/entrena-virtual/pipes/pipe-safe';
import { SwiperModule } from 'swiper/angular';
import { TelemedicineComponentComponent } from './components/telemedicine-component/telemedicine-component.component';
import { TestItModalComponent } from './components/test-it-modal/test-it-modal.component';
import { VitaldentAppsBannerComponent } from './components/vitaldent-apps-banner/vitaldent-apps-banner.component';
import { VitaldentHomeBannerComponent } from './components/vitaldent-home-banner/vitaldent-home-banner.component';

@NgModule({
  declarations: [
    NavbarDesktopComponent,
    NavbarMobileComponent,
    FooterComponent,
    DeviceStoresComponent,
    IconTitleComponent,
    CallmeFormComponent,
    NoYoigoClientComponent,
    TestItModalComponent,
    IconTitleInlineComponent,
    GenericModalComponent,
    DanaModalMasInfoComponent,
    SafePipe,
    CommentsComponent,
    InsuranceComponentComponent,
    TelemedicineComponentComponent,
    BreadcrumbComponent,
    BlogEntriesComponent,
    FamilyInsuranceDkvComponent,
    BlogComponent,
    PetsBannerComponent,
    AppPriceComponent,
    VitaldentHomeBannerComponent,
    VitaldentAppsBannerComponent,
    IsaludBannerComponent,
    PetBannerComponent,
    HealthZigzagComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModuleShared,
    ReactiveFormsModule,
    SwiperModule,
  ],
  exports: [
    NavbarDesktopComponent,
    NavbarMobileComponent,
    FooterComponent,
    DeviceStoresComponent,
    IconTitleComponent,
    CallmeFormComponent,
    NoYoigoClientComponent,
    TestItModalComponent,
    GenericModalComponent,
    DanaModalMasInfoComponent,
    IconTitleInlineComponent,
    SafePipe,
    CommentsComponent,
    InsuranceComponentComponent,
    TelemedicineComponentComponent,
    BreadcrumbComponent,
    BlogEntriesComponent,
    FamilyInsuranceDkvComponent,
    BlogComponent,
    PetsBannerComponent,
    AppPriceComponent,
    VitaldentHomeBannerComponent,
    VitaldentAppsBannerComponent,
    IsaludBannerComponent,
    PetBannerComponent,
    HealthZigzagComponent,
  ],
})
export class SharedModule {}
