import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-blue-box',
  templateUrl: './onboarding-blue-box.component.html',
  styleUrls: ['./onboarding-blue-box.component.scss'],
})
export class OnboardingBlueBoxComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;

  constructor() {}

  ngOnInit() {}
}
