<div>
    <div class="blue-nav"></div>
    <div class="main-nav">
        <div class="" *ngIf="getDeviceType === 'md' || getDeviceType === 'sm'">
            <span class="" style="padding: 1rem;">
                <img src="../../../assets/images/navbar-mobile/menu.svg" alt="imagen no disponible"
                class="header__mid__menu-icon" />
            </span>
        </div>
        <div class="content">
            <div class="logo-doctor">
                <img src="../../../assets/images/doctor-go-logo.svg" width="45" height="auto" />
            </div>
            <div class="logo-yoigo" *ngIf="getDeviceType != 'sm'">
                <img src="../../../assets/images/logonavbar.svg" height="35" width="auto" />
            </div>
        </div>
    </div>

    <div class="header__container" *ngIf="!isBigScreen">
        <div class="header-form-watch">
            <div class="header__container__text header-responsive mt-3 mb-0" style="margin-inline: 24px;">
                <div class="header-title-box" style="gap:24px">
                    <div *ngIf="getDeviceType !== 'sm'" class="d-flex flex-column header-title-inner-text" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                        <span class="header-title header-title-font">
                            {{ "El " | uppercase }}
                            <span class="header-title-font light-blue">{{"reloj de teleasistencia 24h." | uppercase }}</span>
                        </span>
                        <span class="header-title header-title-font">
                            {{"Que cuida de ti y de tus mayores." | uppercase }}
                        </span>
                    </div>
                    <div *ngIf="getDeviceType === 'sm'" class="d-flex flex-column header-title-inner-text" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                        <span class="header-title header-title-font">
                            {{ "El " | uppercase }}
                            <span class="header-title-font light-blue">{{"reloj de" | uppercase }}</span>
                        </span>
                        <span class="header-title header-title-font">
                            <span class="header-title-font light-blue">{{"teleasistencia 24h." | uppercase}}</span>
                        </span>
                        <span class="header-title header-title-font">
                            {{"Que cuida de ti" | uppercase}}
                        </span>
                        <span class="header-title header-title-font">
                           {{ "y de tus mayores." | uppercase }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="images-responsive">
                <div *ngIf="getDeviceType === 'sm'" style="display: flex; justify-content: center; align-items: center;">
                    <img class="header-img-price" src="../../assets/images/form-watch/Precioprice-responsive.png" width="330" height="270"/>
                </div>
                <div style="display: flex; justify-content: flex-end; align-items: end;">
                    <img class="header-img" src="../../../../assets/images/onboarding/watch-header.svg" alt="no disponible"/>
                </div>
                <div *ngIf="getDeviceType !== 'sm'" style="display: flex; justify-content: center; align-items: center;">
                    <img class="header-img-price" src="../../assets/images/form-watch/Precioprice-normal.png" width="330" height="270"/>
                </div>
            </div>
        </div>
    </div>
    <div class="header__container" style="width: 100vw;" *ngIf="isBigScreen">
        <div style="display: flex; justify-content: center; align-items: center; max-width: 1600px;">
            <div class="header-form-watch">
                <div class="header__container__text header-responsive mt-3 mb-0" style="margin-inline: 24px;">
                    <div class="header-title-box" style="gap:24px">
                        <div *ngIf="getDeviceType !== 'sm'" class="d-flex flex-column header-title-inner-text" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                            <span class="header-title header-title-font">
                                {{ "El " | uppercase }}
                                <span class="header-title-font light-blue">{{"reloj de teleasistencia 24h." | uppercase }}</span>
                            </span>
                            <span class="header-title header-title-font">
                                {{"Que cuida de ti y de tus mayores." | uppercase }}
                            </span>
                        </div>
                        <div *ngIf="getDeviceType === 'sm'" class="d-flex flex-column header-title-inner-text" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                            <span class="header-title header-title-font">
                                {{ "El " | uppercase }}
                                <span class="header-title-font light-blue">{{"reloj de" | uppercase }}</span>
                            </span>
                            <span class="header-title header-title-font">
                                <span class="header-title-font light-blue">{{"teleasistencia 24h." | uppercase}}</span>
                            </span>
                            <span class="header-title header-title-font">
                                {{"Que cuida de ti" | uppercase}}
                            </span>
                            <span class="header-title header-title-font">
                            {{ "y de tus mayores." | uppercase }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="images-responsive">
                    <div *ngIf="getDeviceType === 'sm'" style="display: flex; justify-content: center; align-items: center;">
                        <img class="header-img-price" src="../../assets/images/form-watch/Precioprice-responsive.png" width="330" height="270"/>
                    </div>
                    <div style="display: flex; justify-content: flex-end; align-items: end;">
                        <img class="header-img" src="../../../../assets/images/onboarding/watch-header.svg" alt="no disponible"/>
                    </div>
                    <div *ngIf="getDeviceType !== 'sm'" style="display: flex; justify-content: center; align-items: center;">
                        <img class="header-img-price" src="../../assets/images/form-watch/Precioprice-normal.png" width="330" height="270"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-more-info-container">
        <div class="header-llamadme">
            <span class="vertical-align bold">TE LLAMAMOS GRATIS</span>
            <form (ngSubmit)="sendPhone()" [formGroup]="phoneForm" class="d-flex gap-8">
                <input type="text" formControlName="name" class="form-control" placeholder="Introduce tu nombre"/>
                <input type="number" formControlName="phone" minlength="9" maxlength="9" class="form-control" placeholder="Introduce tu número"/>
                <button class="llamadme-button" type="submit">{{correoEnviado ? "¡ENVIADO!" : "¡LLAMADME GRATIS!"}}</button>
            </form>
            <span *ngIf="getDeviceType === 'xxl' || getDeviceType === 'xl' || getDeviceType === 'sm'" class="vertical-align horario-text thin">De lunes a viernes de 10:00h a 18:00h</span>
        </div>
        <span *ngIf="getDeviceType === 'l' || getDeviceType === 'md'" class="vertical-align horario-text thin">De lunes a viernes de 10:00h a 18:00h</span>
        <span class="small-text">En Yoigo vamos a tratar tus datos para informarte de nuestros servicios. Puedes ejercitar tus derechos con privacidad en <a class="bold" href="mailto:yoigo@yoigo.com">yoigo@yoigo.com</a> Más info en <a class="bold" href="../../../assets/documents/docs-watch/Textos_Proteccion_Datos_Reloj_Teleasistencia_24H_20231018.pdf">políticas de privacidad</a>.</span>
    </div>

    <div style="background-color: #F2F2F2; padding-bottom: 16px; position: relative; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="header-title-box d-flex flex-column align-items-center pt-5">
            <div class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px" *ngIf="getDeviceType == 'l' || getDeviceType == 'xl' || getDeviceType == 'xxl'">
                <span class="header-title header-title-font">
                    ¿QUÉ INCLUYE EL SERVICIO CON
                    <span class="header-title-font light-blue">RELOJ TELEASISTENCIA LAS 24H</span>
                    ?
                </span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center;" *ngIf="getDeviceType == 'md'">
                <span style="width: fit-content;" class="header-title header-title-font">¿QUÉ INCLUYE EL SERVICIO CON</span>
                <span style="width: fit-content;" class="header-title header-title-font light-blue">RELOJ TELEASISTENCIA LAS 24H?</span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center;" *ngIf="getDeviceType == 'sm'">
                <span style="width: fit-content;" class="header-title header-title-font">¿QUÉ INCLUYE EL</span>
                <span style="width: fit-content;" class="header-title header-title-font">SERVICIO CON
                    <span style="width: fit-content;" class="header-title-font light-blue">RELOJ</span>
                </span>
                <span style="width: fit-content;" class="header-title header-title-font">
                    <span style="width: fit-content;" class="header-title-font light-blue">TELEASISTENCIA LAS 24H?</span>
                </span>
            </div>
            <div class="header-title-text flex">
                Un Reloj, que permite a nuestros mayores sentirse protegidos y que proporciona la tranquilidad a los
                familiares de estar conectados las 24h a través de la APP que incorpora el servicio.
            </div>
        </div>
        <div style="max-width: 1368px;" class="d-flex flex-wrap justify-center items-center" *ngIf="getDeviceType == 'xl' || getDeviceType == 'xxl'">
            <div class="included-item" alt="First slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon mb-2">
                            <img src="../../../../assets/images/onboarding/SOS-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-text">
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Botón SOS</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                emergencia.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-item" alt="Second slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon">
                            <img src="../../../../assets/images/onboarding/caidas-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                            <strong>Detección de caidas</strong>
                        </div>
                        <div class="card-content d-flex flex-column align-items-center text-center">
                            Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                            emergencia.
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-item" alt="Third slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon">
                            <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                            <strong>Aviso a emergencias y familiares</strong>
                        </div>
                        <div class="card-content d-flex flex-column align-items-center text-center">
                            Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                            emergencia.
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-item" alt="Fourth slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon">
                            <img src="../../../../assets/images/onboarding/geolocalizacion-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                            <strong>Geolocalización</strong>
                        </div>
                        <div class="card-content d-flex flex-column align-items-center text-center">
                            El reloj indica a los familiares través de la APP, dónde se encuentra el usuario
                            del reloj en todo momento.
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-item" alt="Fifth slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon">
                            <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                            <strong>Agenda y Llamadas</strong>
                        </div>
                        <div class="card-content d-flex flex-column align-items-center text-center">
                            El reloj dispone de altavoz y micrófono para hablar con el centro de emergencias
                            o con los familiares.
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-item" alt="Sixth slide">
                <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                    <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                        <div class="card-icon">
                            <img src="../../../../assets/images/onboarding/avisos-icono.svg"
                                alt="no disponible" />
                        </div>
                        <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                            <strong>Comunicados y avisos</strong>
                        </div>
                        <div class="card-content d-flex flex-column align-items-center text-center">
                            Mediante vibración y pitidos para que nuestros mayores no se queden nunca, por
                            ejemplo, sin batería.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="(getDeviceType == 'md' || getDeviceType == 'sm' || getDeviceType == 'l')">
            <ngb-carousel>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="First slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded"
                                style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon mb-2">
                                    <img src="../../../../assets/images/onboarding/SOS-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-text">
                                    <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                        <strong>Botón SOS</strong>
                                    </div>
                                    <div class="card-content d-flex flex-column align-items-center text-center">
                                        Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                        emergencia.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Second slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/caidas-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Detección de caidas</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                    emergencia.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Third slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Aviso a emergencias y familiares</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                    emergencia.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Fourth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/geolocalizacion-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Geolocalización</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    El reloj indica a los familiares través de la APP, dónde se encuentra el usuario
                                    del reloj en todo momento.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Fifth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Agenda y Llamadas</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    El reloj dispone de altavoz y micrófono para hablar con el centro de emergencias
                                    o con los familiares.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Sixth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/avisos-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Comunicados y avisos</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Mediante vibración y pitidos para que nuestros mayores no se queden nunca, por
                                    ejemplo, sin batería.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>

    <div class="container-flex" style="background-color:  #1EA8C7; padding-bottom: 16px;">
        <div class="container-price container">
            <div class="container-img-price" *ngIf="getDeviceType !== 'sm'">
                <img class="footer-img-phone" src="../../assets/images/form-watch/Appbottom-mobile.png" />
                <img class="footer-img-watch" src="../../assets/images/form-watch/Mask groupprice-image.png" />
            </div>
            <div class="container-info-price">
                <div *ngIf="getDeviceType !== 'sm'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                    <span class="header-title header-title-font">
                        {{"El " | uppercase }}
                        <span class="header-title-font light-blue">{{"reloj de teleasistencia 24h." | uppercase }}</span>
                    </span>
                    <span class="header-title header-title-font">
                        {{"Que cuida de ti y de tus mayores." | uppercase }}
                    </span>
                </div>
                <div *ngIf="getDeviceType === 'sm'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                    <span class="header-title header-title-font">
                        {{"El " | uppercase }}
                        <span class="header-title-font light-blue">{{"reloj de" | uppercase }}</span>
                    </span>
                    <span class="header-title header-title-font">
                        <span class="header-title-font light-blue">{{"teleasistencia 24h." | uppercase}}</span>
                    </span>
                    <span class="header-title header-title-font">
                        {{"Que cuida de ti" | uppercase}}
                    </span>
                    <span class="header-title header-title-font">
                       {{ "y de tus mayores." | uppercase }}
                    </span>
                </div>
                <div id="prices" class="d-flex">
                    <span class="price-big">19,90</span>
                    <div class="d-flex flex-column">
                        <span class="price-other">€/mes</span>
                        <span class="small-text">IVA INCL</span>
                    </div>
                </div>
                <span class="servicio-asistencia-text">CUOTA DEL SERVICIO ASISTENCIA 24H</span>
                <span class="rotated-text header-title header-title-font">¡RELOJ GRATIS!</span>
                <button class="llamadme-gratis-price" (click)="openModal(content)">¡LLAMADME GRATIS!</button>
            </div>
        </div>
    </div>

    <div class="footer">
        <div class="footer-container">
            <hr />
            <div class="other-info">
                <div>
                    <a href="#">
                        <img src="../../assets/images/form-watch/Logo Yoigo SVAlogo-blanco.png" />
                    </a>
                </div>

                <div class="left-footer d-flex">
                    <div class="cookies-informacion-legal d-flex">
                      <a target="_blank" href="/assets/documents/docs-watch/Textos_Proteccion_Datos_Reloj_Teleasistencia_24H_20231018.pdf">Proteccion de datos</a>
                      <div class="vertical-sep"></div>
                      <a target="_blank" href="https://doctorgo.es/cookies">Cookies</a>
                    </div>
                    <div *ngIf="(getDeviceType !== 'md' && getDeviceType !== 'sm')" class="vertical-sep"></div>
                    <label class="footer-text">© Grupo MASMOVIL 2023</label>
                </div>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header" style="border: none; justify-content: flex-end; padding: 24px">
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
                <img src="../../assets/images/form-watch/iconclose.png" width="12" height="12" />
            </button>
        </div>
        <div class="modal-body" style="padding: 0 24px 24px 24px;">
            <div class="d-flex" style="flex-direction: column; gap: 16px; justify-content: center; align-items: center;">
                <img src="../../assets/images/form-watch/Asesoricon.png" width="120" height="120" />
                <span class="modal-title-text">¿TE LLAMAMOS GRATIS?</span>
                <form class="d-flex flex-column" style="width: 100%; gap: 8px" (ngSubmit)="sendPhone()" [formGroup]="phoneForm">
                    <input type="text" formControlName="name" class="form-control" placeholder="Introduce tu nombre"/>
                    <input formControlName="phone" class="form-control" type="number" placeholder="Introduce tu número de telefono" min="600000000" max="999999999" />
                    <button [disabled]="correoEnviado" class="llamadme-button" type="submit">{{ correoEnviado ? "¡ENVIADO!" : "¡LLAMADME GRATIS!"}}</button>
                </form>
                <span class="small-text" style="font-size: 12px;">De lunes a domingo de 9:00 a 23:00</span>
                <span class="small-text">En Yoigo vamos a tratar tus datos para informarte de nuestros servicios. Puedes ejercitar tus derechos con privacidad en <a class="bold" href="mailto:yoigo@yoigo.com">yoigo@yoigo.com</a> Más info en <a class="bold" href="/assets/documents/docs-watch/Textos_Proteccion_Datos_Reloj_Teleasistencia_24H_20231018.pdf">políticas de privacidad</a>.</span>
            </div>
        </div>
    </ng-template>
</div>