import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertScreenComponent } from './steps/shared/alert-screen/alert-screen.component';
import { CommonModule } from '@angular/common';
import { FocusAfterDirective } from 'src/app/directives/focusAfter.directive';
import { InitialStepComponent } from './steps/screens/initial-step/initial-step.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { NextFocusIdDirective } from 'src/app/directives/nextFocus.directive';
import { NgModule } from '@angular/core';
import { NoSpacesDirective } from 'src/app/directives/no-spaces.directive';
import { OnboardingBlueBoxComponent } from './steps/shared/onboarding-blue-box/onboarding-blue-box.component';
import { OnboardingConfirmComponent } from './steps/screens/onboarding-confirm/onboarding-confirm.component';
import { OnboardingErrorScreenComponent } from './steps/shared/onboarding-error-screen/onboarding-error-screen.component';
import { OnboardingFormErrorComponent } from './steps/shared/onboarding-form-error/onboarding-form-error.component';
import { OnboardingInvitationComponent } from './steps/screens/onboarding-invitation/onboarding-invitation.component';
import { OnboardingInvitationConfirmedComponent } from './steps/screens/onboarding-invitation-confirmed/onboarding-invitation-confirmed.component';
import { OnboardingRegisterComponent } from './steps/screens/onboarding-register/onboarding-register.component';
import { OnboardingStripeComponent } from './steps/screens/onboarding-stripe/onboarding-stripe.component';
import { OnboardingWellcomeComponent } from './steps/screens/onboarding-wellcome/onboarding-wellcome.component';
import { PresentationStepComponent } from './steps/screens/presentation-step/presentation-step.component';
import { RouterModule } from '@angular/router';
import { SelectorContinueComponent } from './steps/screens/initial-step/components/selector-continue/selector-continue.component';
import { SignUpFooterComponent } from './steps/shared/sign-up-footer/sign-up-footer.component';
import { SignUpMeetingComponent } from './sign-up-meeting.component';
import { YoigoInfoComponent } from './steps/screens/yoigo-info/yoigo-info.component';
import { YoigoSmsComponent } from './steps/screens/yoigo-sms/yoigo-sms.component';
import { routes } from './sign-up-meeting.routing';

@NgModule({
  declarations: [
    SignUpMeetingComponent,
    YoigoInfoComponent,
    YoigoSmsComponent,
    PresentationStepComponent,
    InitialStepComponent,
    SelectorContinueComponent,
    SignUpFooterComponent,
    OnboardingRegisterComponent,
    OnboardingBlueBoxComponent,
    OnboardingConfirmComponent,
    OnboardingWellcomeComponent,
    FocusAfterDirective,
    NextFocusIdDirective,
    NoSpacesDirective,
    OnboardingInvitationComponent,
    OnboardingInvitationConfirmedComponent,
    OnboardingStripeComponent,
    OnboardingFormErrorComponent,
    OnboardingErrorScreenComponent,
    AlertScreenComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
})
export class SignUpMeetingModule {}
