import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { Subject } from 'rxjs';
import { UserType } from '../../../types/onboardingTypes';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-invitation-confirmed',
  templateUrl: './onboarding-invitation-confirmed.component.html',
  styleUrls: ['./onboarding-invitation-confirmed.component.scss'],
})
export class OnboardingInvitationConfirmedComponent implements OnInit {
  @Output() continue = new EventEmitter<boolean>();
  continueDebouncer: Subject<any> = new Subject<any>();

  error: string;

  codeType: 'promocional' | 'de invitación' = 'promocional';

  header = {
    title: '¡Código canjeado con éxito!',
    desc: `Tu código ${this.codeType} ha sido validado.\n Éstas son sus ventajas:`,
  };

  features: { title: string; desc: string }[] = [];

  codeTypeTitle: 'Invitación validada' | 'Código validado' =
    'Invitación validada';

  title: string = `¡${this.codeTypeTitle} con éxito!`;

  promo = false;
  invEmp = true;
  promoVitaldent = false;

  constructor(public onboardingStateService: OnboardingStateService) {


  }

  ngOnInit() {

    if (this.onboardingStateService.userType === UserType.promo) {

      this.promo = true;
      this.promoVitaldent = false;
      this.invEmp = false;

    }

    if (this.onboardingStateService.userType === UserType.vitaldent) {

      this.promoVitaldent = true;
      this.invEmp = false;
      this.promo = false;

    }

    if (this.onboardingStateService.userType === UserType.employee) {

      this.codeTypeTitle = 'Código validado'
      this.title = `¡${this.codeTypeTitle} con éxito!`;

      this.invEmp = true;
      this.promoVitaldent = false;
      this.promo = false;

    }

    const fst = {
      title: 'Empieza hoy GRATIS',
      desc: `Todos los servicios gratis durante ${this.onboardingStateService.getMonth(
        true
      )}.`,
    };

    if (
      this.onboardingStateService.userType === UserType.invitation ||
      this.onboardingStateService.userType === UserType.employee
    ) {
      fst.desc = `Todos los servicios GRATIS.`;
    }

    const scd = {
      title: 'Si decides quedarte',
      desc: `La suscripción incluye todos los servicios para 2 adultos + niños, por tan sólo ${this.onboardingStateService.getPrice()}€/mes.`,
    };

    this.features = [
      fst,
      scd,
      {
        title: 'Y sin compromiso',
        desc: 'Cancela tu suscripción cuando quieras, sin ningún tipo de penalización.',
      },
    ];

    if (
      this.onboardingStateService.userType === UserType.invitation ||
      this.onboardingStateService.userType === UserType.employee
    ) {
      this.features = [fst, this.features[2]];
    }

    let goBack = true;

    if (!this.onboardingStateService.promotionForm.controls.code.value) {
      this.codeType = 'de invitación';
    }

    if (
      this.onboardingStateService.userType === UserType.invitation ||
      this.onboardingStateService.userType === UserType.employee ||
      this.onboardingStateService.userType === UserType.vitaldent
    ) {
      this.onboardingStateService.isYoigo.next(false);
      goBack = false;
    }

    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continue.emit(goBack);
    });
  }
}
