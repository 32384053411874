import * as moment from 'moment';

import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CustomerBody } from 'src/server/interfaces/meetingInterfaces';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';
import { MeetingApiService } from '../../../services/meeting-api/meeting-api.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { Subject } from 'rxjs';
import { UserType } from '../../../types/onboardingTypes';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-register',
  templateUrl: './onboarding-register.component.html',
  styleUrls: ['./onboarding-register.component.scss'],
})
export class OnboardingRegisterComponent implements OnInit {
  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();
  @Output() continueCode = new EventEmitter();
  continueCodeDebouncer: Subject<any> = new Subject<any>();

  checkboxes = [
    {
      text: 'He leido y acepto la <a href="/assets/documents/salud-online/Politica-de-privacidad-y-condiciones-de-uso-App-Salud-Online.pdf">política de privacidad y condiciones de uso</a> y declaro ser mayor de 16 años.',
      value: false,
      required: true,
    },
    {
      text: 'Doy mi consentimiento a las condiciones del contrato y solicito la activación del servicio.',
      value: false,
      required: true,
    },
    {
      text: 'Autorizo el envío de comunicaciones electrónicas de MeetingDoctors, S.L.',
      value: false,
      required: false,
    },
  ];

  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 16));
  error: string;

  hasCode = false;

  success = false;
  promo: any;
  loading = false;

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    private meetingApiService: MeetingApiService,
    public onboardingStateService: OnboardingStateService,
    private meetingApi: MeetingApiService,
    private utils: FunctionUtilsService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueClick();
    });
    this.continueCodeDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.onboardingStateService.hideBackButton = false;
      this.continueCode.emit();
    });
  }

  ngOnInit(): void {
    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'DATOS USUARIO',
          screen_class: 'DATOS USUARIO',
        },
      },
    });
    this.segmentService.sendSegmentEvent('Onboarding Form Started', null);
  }

  continueClick() {

    this.error = '';
    this.loading = true;

    const dniDocument = this.onboardingStateService.dniForm.value.document.toUpperCase().padStart(9, '0') || ''

    this.segmentService.sendSegmentEvent('Onboarding Form Submitted', null);
    this.segmentService.sendSegmentIdentifyEvent(
     dniDocument,
      {
        personalDataForm: this.onboardingStateService.personalDataForm.value,
        dniForm: this.onboardingStateService.dniForm.value,
        contactForm: this.onboardingStateService.contactForm.value,
        // Checkboxes form
      }
    );

    const data: CustomerBody = {
      firstname: this.onboardingStateService.personalDataForm.value.name || '',
      surname:
        this.onboardingStateService.personalDataForm.value.surname1 ||
        '' +
          ' ' +
          this.onboardingStateService.personalDataForm.value.surname2 ||
        '',
      number: this.onboardingStateService.contactForm.value.phone || '',
      email: this.onboardingStateService.contactForm.value.email || '',
      nid: dniDocument,
      nid_type: this.onboardingStateService.dniForm.value.nieDni || '',
      gender: Number(this.onboardingStateService.personalDataForm.value.sex),
      birthdate: moment(
        this.onboardingStateService.personalDataForm.value.birthDate
      ).format('YYYY-MM-DD'),
      invitation_code: this.onboardingStateService.verificationForm.value.invitationCode || '',
      promotion_code: this.onboardingStateService.promotionForm.value.code || '',
      status: 2,

      securityPolice: this.checkboxes[0].value,
      contractConditions: this.checkboxes[1].value,
      communications: this.checkboxes[2].value,
      isYoigo: this.onboardingStateService.isYoigo.value,
      /*additional_data: JSON.stringify(this.onboardingStateService.userResponse.userData.additional_data),
      companyGroupCode: this.onboardingStateService.userResponse.userData.companyGroupCode,
      company_group_code: this.onboardingStateService.userResponse.userData.companyGroupCode,
      description: this.onboardingStateService.userResponse.userData.description*/
    };

    this.meetingApiService.customers(data).subscribe(
      (res: any) => {

        if (res.sva) {

          const domSRC = new DOMParser().parseFromString(res.sva, 'text/xml');
          const json = this.utils.xml2json(domSRC);

          this.segmentService.sendSegmentEvent('Onboarding Form Create SVA Success', {
            data: {
              sva: json,
              subType: res.subType
            }
          });
        }

        if (res.promotions) {

          const ob = {}

          res.promotions.forEach((promotion) => {
            ob[promotion.realName] = promotion;
          });

          this.segmentService.sendSegmentEvent('Onboarding Form Create Partner Promotions Success', {
            data: {
             ...ob
            }
          });
        }

         if (res.svaError) {
          this.segmentService.sendSegmentEvent('Onboarding Form Create SVA Error', {
            data: {
              error: res.svaError
            }
          });
         }

         if (res.promotionError) {
          this.segmentService.sendSegmentEvent('Onboarding Form Create Partner Promotions Error', {
            data: {
              error: res.promotionError
            }
          });
         }

         if (res.responseDisableMasStack) {
          this.segmentService.sendSegmentEvent('Onboarding Form Disable SVA', {
            data: {
              responseDisableMasStack: res.responseDisableMasStack,
              document: dniDocument,
              phone: this.onboardingStateService.contactForm.value.phone,
              stack: this.onboardingStateService.stackType,
              userType: this.onboardingStateService.userType
            }
          });
        }

         if (this.onboardingStateService.stackType === 'mas-stack' && !res.sva && !res.svaError && this.onboardingStateService.userType === UserType.standard) {
          this.segmentService.sendSegmentEvent('Onboarding Form Create SVA Error', {
            data: {
              error: 'No se ha podido crear el SVA',
              document: dniDocument,
              phone: this.onboardingStateService.contactForm.value.phone,
              stack: this.onboardingStateService.stackType,
              userType: this.onboardingStateService.userType
            }
          });
         }

         if (!this.needToPay()) {
          this.segmentService.sendSegmentEvent('Cuenta Verificada - SIGNUP', null);
        }

       // console.log('res', res);
        this.continue.emit(this.needToPay());
      },
      (err) => {
        this.error =
          'Hubo un error al guardar la información. Vuelve a intentarlo.';
        console.log('err', err);
        this.segmentService.sendSegmentEvent('Onboarding Form Error', {
          data: {
            error: err,
            data: data
          },
        });

        this.loading = false;
      }
    );
  }

  needToPay() {


      if (this.onboardingStateService.userType === 3){
        return false;
      }

      const existsStripeAdditionalData =
        (
          this.onboardingStateService.userResponse.userData.additional_data &&
          this.onboardingStateService.userResponse.userData.additional_data.stripe &&
          this.onboardingStateService.userResponse.userData.additional_data.stripe.subscriptionId
          ) || false;

      if (
        this.onboardingStateService.userResponse.userData.user_type === 'primary'
      ) {
        return (
          this.onboardingStateService.isYoigo.value === false && !existsStripeAdditionalData
        );
      }

      return false;

  }

  hasCodeClick() {
    this.hasCode = !this.hasCode;

    if (this.hasCode === false) {
      this.error = '';
    }

    this.webviewEvents.emitEvent({
      action: Actions.CLICK,
      payload: this.hasCode
        ? 'TENGO CODIGO PROMOCIONAL'
        : 'NO TENGO CODIGO PROMOCIONAL',
    });
  }
}
