import { GeneralEventModel } from "./generaleventmodel";

export class ServiceItem extends GeneralEventModel {


  constructor(partial: Partial<ServiceItem> = null) {
    super()
    Object.assign(this, partial);
  }
}
