import { Events } from "../constants/events";
import { Ecommerce } from "./ecommerce"
import { EcommerceProductsHiring } from "./ecommerceproducthiring";
import { EcommerceProductsPaymentType } from "./ecommerceproductpaymenttype";
import { EcommerceProductsPurchase } from "./ecommerceproductpurchase";
import { EcommerceProductsClick } from "./ecommerceproductsclick";
import { EcommerceProductsShow } from "./ecommerceproductsshow";
import { EcommercePromotions } from "./ecommercepromotions";
import { EventDetail } from './eventdetail';

export class General {
  event: Events = 'add_payment_info';
  eventModel: EcommerceProductsClick |
  EcommerceProductsPurchase |
  EcommerceProductsHiring |
  EcommerceProductsPaymentType |
  EcommerceProductsShow |
  EcommercePromotions;

}
