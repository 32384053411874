<section class="onboarding-invitation">
  <article class="text">
    <div class="title">Canjear código</div>
    <div class="description">
      ¿Tienes un código de <b>DoctorGO</b>? ¡Qué bien! Danos primero el código y después rellenamos los datos de tu perfil.
    </div>
  </article>

  <section class="code-section d-flex flex-column gap-8">
    <div class="code-label">Introduce tu código</div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Introduce tu código</mat-label>
      <input [(ngModel)]="codeValue" matInput />

    </mat-form-field>
  </section>
</section>

<app-onboarding-form-error *ngIf="error">{{error}}</app-onboarding-form-error>

<app-sign-up-footer
[buttonText]="loading ? '<img class=spinner-onboarding width=26 src=/assets/images/Spinner.svg alt=Cargando />' : 'CONTINUAR'"
[buttonValid]="!loading"
(buttonClick)="continueDebouncer.next()"
  [buttonValid]="codeValue ? true : false"></app-sign-up-footer>
