<section
  class="container [class.pt-5]=!steps.stepWellcome.active
  [class.pb-4]=!steps.stepWellcome.active pt-4 content d-flex flex-column align-items-center"
>
  <!-- TODO: Renderizar los pasos de forma dinámica -->

  <article class="h-100 d-flex flex-column justify-content-between">
    <section
      class="container gap-32 h-100 d-flex flex-column align-items-center"
    >
      <header
        class="relative gap-8 d-flex justify-content-center align-items-center w-100 mt-3"
        *ngIf="
            (_stepsHistory.length > 1 &&
            steps.stepFirst.active === false &&
            steps.stepInvitationConfirmed.active === false &&
            steps.stepRegister.active === false &&
            onboardingStateService.hideBackButton === false) || steps.stepWellcome.active === false"
      >

        <span
          style="position: absolute; left: 0"
          *ngIf="
            _stepsHistory.length > 1 &&
            steps.stepFirst.active === false &&
            steps.stepInvitationConfirmed.active === false &&
            steps.stepRegister.active === false &&
            onboardingStateService.hideBackButton === false
          "
          (click)="goBack()"
          >Volver</span
        >
        <img  width="38px" height="35px" [src]="returnLogo()" alt="Logo" *ngIf="steps.stepWellcome.active === false"/>
      </header>

      <section class="w-100 h-100 d-flex">
        <app-presentation-step
          @onOff
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepPresentation.active === true"
          [ngClass]="isBack ? 'animate__animated animate__fadeInLeft' : ''"
          (continue)="onPresentationStep()"
        ></app-presentation-step>

        <app-yoigo-info
          @onOff
          class="gap-16 h-100 d-flex flex-column"
          *ngIf="steps.stepAuth.active === true"
          (continue)="onLogin($event)"
          (continueInvitation)="onFirstStep('invitation')"
        ></app-yoigo-info>

        <app-onboarding-invitation
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepInvitation.active === true"
          (continue)="onInvitation($event)"
        ></app-onboarding-invitation>

        <app-onboarding-invitation-confirmed
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepInvitationConfirmed.active === true"
          (continue)="onInvitationConfirmed($event)"
        ></app-onboarding-invitation-confirmed>

        <!-- [isClient]="isClient"
          [yoigoForm]="form"
           -->

        <app-yoigo-sms
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          *ngIf="steps.stepMFA.active === true"
          class="gap-32 h-100 d-flex flex-column"
          (continue)="onSMSChecked($event)"
        ></app-yoigo-sms>

        <app-onboarding-register
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepRegister.active === true"
          (continue)="onRegistered($event)"
          (continueCode)="onRegistered('code')"
        ></app-onboarding-register>

        <app-onboarding-stripe
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepPayment.active === true"
          (continue)="onDoPayment()"
        >
        </app-onboarding-stripe>

        <app-onboarding-confirm
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepConfirm.active === true"
          (continue)="onConfirmed()"
        ></app-onboarding-confirm>

        <app-onboarding-wellcome
          @onOff
          [ngClass]="
            isBack
              ? 'animate__animated animate__fadeInLeft'
              : 'animate__animated animate__fadeInRight'
          "
          class="gap-32 h-100 d-flex flex-column"
          *ngIf="steps.stepWellcome.active === true"
          (continue)="onConfirmed()"
        ></app-onboarding-wellcome>
      </section>
    </section>
  </article>
</section>
