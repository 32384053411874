<div class="header">
  <div class="title">Bienvenido a DoctorGO</div>
  <div class="body">
    <div>
      Rellena tus datos para crear tu perfil y gestionar todos los servicios de DoctorGO.
    </div>
  </div>
</div>
<app-onboarding-blue-box *ngIf="onboardingStateService.isYoigo.value === true"
  ><b>DNI del titular del contrato de Yoigo:</b>
  {{ onboardingStateService.dniForm.value.document }}</app-onboarding-blue-box
>
<div class="personal-data">
  <div class="title-form">Datos personales</div>
  <form
    [formGroup]="onboardingStateService.personalDataForm"
    class="d-flex flex-column"
  >
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input
        id="nameInput"
        nextFocusId="surname1Input"
        [focusAfter]="750"
        matInput
        formControlName="name"
      />

      <mat-error>
        Introduce tu nombre para que podamos dirigirnos a ti.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Primer apellido</mat-label>
      <input
        id="surname1Input"
        nextFocusId="surname2Input"
        matInput
        formControlName="surname1"
      />

      <mat-error> Introduce tu apellido para crearte una cuenta. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Segundo apellido</mat-label>
      <input
        id="surname2Input"
        nextFocusId="dateInput"
        matInput
        formControlName="surname2"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Fecha de nacimiento</mat-label>
      <input
        noSpaces
        id="dateInput"
        nextFocusId="sexInput"
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        formControlName="birthDate"
      />
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-error> Por favor selecciona tu fecha de nacimiento. </mat-error>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Sexo</mat-label>
      <mat-select id="sexInput" nextFocusId="dniNieInput" formControlName="sex">
        <mat-option value="0">Masculino</mat-option>
        <mat-option value="1">Femenino</mat-option>
      </mat-select>
      <mat-error>Selecciona una opción para poder continuar.</mat-error>
      <mat-hint>ⓘ El sexo que aparece en tu DNI/NIE/Pasaporte</mat-hint>
    </mat-form-field>
  </form>
</div>
<div class="dni">
  <div class="title-form">Documento de identificación</div>
  <form [formGroup]="onboardingStateService.dniForm" class="d-flex flex-column">
    <article class="gap-8 d-flex justify-content-between">
      <mat-form-field [style.width]="'80px'" appearance="outline">
        <mat-label>DNI</mat-label>
        <mat-select
          id="dniNieInput"
          nextFocusId="documentInput"
          formControlName="nieDni"
        >
          <mat-option value="dni">DNI</mat-option>
          <mat-option value="nie">NIE</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [style.width.px]="220" appearance="outline">
        <mat-label>DNI del titular</mat-label>
        <input
          noSpaces
          id="documentInput"
          nextFocusId="emailInput"
          matInput
          formControlName="document"
        />
        <mat-error>Introduce el número de identidad.</mat-error>
      </mat-form-field>
    </article>
  </form>
</div>
<div class="contact">
  <div class="title-form">Contacto</div>
  <form
    [formGroup]="onboardingStateService.contactForm"
    class="d-flex flex-column"
  >
    <mat-form-field appearance="outline">
      <mat-label>Email de contacto</mat-label>
      <input
        noSpaces
        id="emailInput"
        nextFocusId="phoneInput"
        matInput
        type="email"
        formControlName="email"
      />
      <mat-error>Introduce tu email para enviarte la factura.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Teléfono de contacto</mat-label>
      <input
        noSpaces
        id="phoneInput"
        matInput
        type="number"
        formControlName="phone"
      />
      <mat-error>Introduce tu número de teléfono</mat-error>
    </mat-form-field>
  </form>
</div>

<app-onboarding-form-error *ngIf="error">{{ error }}</app-onboarding-form-error>

<div class="promo" *ngIf="promo" [innerHTML]="promo"></div>

<app-sign-up-footer
  [checkboxes]="checkboxes"
  [buttonText]="loading ? '<img class=spinner-onboarding width=26 src=/assets/images/Spinner.svg alt=Cargando />' : 'CONTINUAR'"
  [buttonValid]="
    onboardingStateService.personalDataForm.valid === true &&
    onboardingStateService.dniForm.valid === true &&
    onboardingStateService.contactForm.valid === true && !loading
  "
  (buttonClick)="continueDebouncer.next()"
></app-sign-up-footer>
