import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, PLATFORM_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BookingComponent } from './components/universal/booking/booking.component';
import { BreadcrumbResolver } from './resolvers/BreadcrumbResolver.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChatSpeciality66Component } from './components/universal/chat-speciality66/chat-speciality66.component';
import { ChatSpeciality67Component } from './components/universal/chat-speciality67/chat-speciality67.component';
import { ChatSpeciality68Component } from './components/universal/chat-speciality68/chat-speciality68.component';
import { ClientesVitaldentComponent } from './components/clientes-vitaldent/clientes-vitaldent.component';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './components/universal/contact/contact.component';
import { Covid19Component } from './components/universal/covid19/covid19.component';
import { CovidComponent } from './components/services/covid/covid.component';
import { DentalComponent } from './components/specialities-insurance/dental/dental.component';
import { ElectronicPrescriptionComponent } from './components/services/electronic-prescription/electronic-prescription.component';
import { EntrenavirtualComponent } from './components/universal/entrenavirtual/entrenavirtual.component';
import { FamilyInsuranceModule } from './components/family-insurance/family-insurance.module';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FormWatchComponent } from './components/form-watch/form-watch.component';
import { GetOfferComponent } from './components/get-offer/get-offer.component';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { GyneacologyInsuranceComponent } from './components/specialities-insurance/gyneacology-insurance/gyneacology-insurance.component';
import { HealthProgrammesComponent } from './components/health-programmes/health-programmes.component';
import { HealthTestComponent } from './components/health-test/health-test.component';
import { HealthyLifeComponent } from './components/services/healthy-life/healthy-life.component';
import { IsCustomerService } from './services/isCustomer/is-customer.service';
import {LayoutModule} from '@angular/cdk/layout'
import { MatStepperModule } from '@angular/material/stepper';
import { MedicalHistoryComponent } from './components/universal/medical-history/medical-history.component';
import { MyAccountComponent } from './components/universal/my-account/my-account.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NutritionDieteticsComponent } from './components/specialities/nutrition-dietetics/nutrition-dietetics.component';
import { OnboardingInterceptor } from './interceptors/http.interceptor';
import { OnlineMedicalAssistanceComponent } from './components/services/online-medical-assistance/online-medical-assistance.component';
import { OphthalmologyComponent } from './components/specialities-insurance/ophthalmology/ophthalmology.component';
import { OurOfferComponent } from './components/our-offer/our-offer.component';
import { PeadiatricsInsuranceComponent } from './components/specialities-insurance/peadiatrics-insurance/peadiatrics-insurance.component';
import { PediatricianOnlineComponent } from './components/services/pediatrician-online/pediatrician-online.component';
import { ProfileComponent } from './components/universal/profile/profile.component';
import { QrWatchComponent } from './components/qr-watch/qr-watch.component';
import { QsComponent } from './components/universal/qs/qs.component';
import { ReactiveFormsModule } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SafePipe } from './pipes/sanitizeUrl.pipe';
import { SanitizeDatePipe } from './pipes/sanitizeDate';
import { SegmentService } from './services/segment/segment.service';
import { SharedModule } from './shared/shared.module';
import { SignUpMeetingModule } from './components/sign-up-meeting/sign-up-meeting.module';
import { SignupProcessComponent } from './components/signup-process/signup-process.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TelefarmaciaComponent } from './components/universal/telefarmacia/telefarmacia.component';
import { ToastrModule } from 'ngx-toastr';
import { UniversalAppComponent } from './components/universal/universal-app/universal-app.component';

// import { SegmentModule } from 'ngx-segment-analytics';


@NgModule({
  declarations: [
    QrWatchComponent,
    AppComponent,
    OurOfferComponent,
    GetOfferComponent,
    FaqsComponent,
    OnlineMedicalAssistanceComponent,
    CovidComponent,
    ElectronicPrescriptionComponent,
    PediatricianOnlineComponent,
    HealthyLifeComponent,
    NotFoundPageComponent,
    SignupProcessComponent,
    NutritionDieteticsComponent,
    HealthProgrammesComponent,
    HealthTestComponent,
    Covid19Component,
    ChatSpeciality66Component,
    ChatSpeciality67Component,
    ChatSpeciality68Component,
    MedicalHistoryComponent,
    QsComponent,
    ContactComponent,
    UniversalAppComponent,
    TelefarmaciaComponent,
    EntrenavirtualComponent,
    BookingComponent,
    DentalComponent,
    GyneacologyInsuranceComponent,
    PeadiatricsInsuranceComponent,
    OphthalmologyComponent,
    SanitizeDatePipe,
    StepperComponent,
    ProfileComponent,
    MyAccountComponent,
    FormWatchComponent,
    SafePipe,
    ClientesVitaldentComponent

  ],
  imports: [
    LayoutModule,
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FamilyInsuranceModule,
    MatStepperModule,
    SignUpMeetingModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgbModule,
    // SegmentModule.forRoot({ apiKey: 'wE4J07LEBzUo4LWb4KkaqiVO7gLEcXnP', debug: true, loadOnInitialization: true })
  ],
  providers: [
    SanitizeDatePipe, IsCustomerService, BreadcrumbResolver,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OnboardingInterceptor,
      multi: true,
      deps: [PLATFORM_ID]
    },
    GoogleAnalyticsService,
    SegmentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
