import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CookiesConfig } from './services/analytics/analytics.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { param } from 'jquery';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'masmovil-doctor-go';
  public modalOpen1 = false;
  public modalOpen2 = false;
  public hide = false;

  public showHeaderAndFooter: Promise<boolean>
  private subscriber: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: string
  ) { }

  ngOnInit() {
    this.setCommonCompsVisibility();
  }

  ngAfterViewInit() {

    this.activatedRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        if (isPlatformBrowser(this.platformId)){
          sessionStorage.setItem('query_params', JSON.stringify(params));
        }
      }
    })
  }

  setCommonCompsVisibility() {
    this.subscriber = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        // console.log(event['url'])
        this.showHeaderAndFooter = Promise.resolve(
          event['url'] !== '/entrena-virtual' &&
          !event['url'].includes('/sign-up-meeting') &&
          event['url'] !== '/yoigo-salud' &&
          !event['url'].includes('/lead-vitaldent') &&
          event['url'] !== '/yoigo-telefarmacia-movil' &&
          !event['url'].includes('/webviews/unsubscribe') &&
          event['url'] !== '/primeros-pasos-watch' &&
          event['url'] !== '/reloj-teleasistencia' &&
          event['url'] !== '/clientes-vitaldent' &&
          event['url'] !== '/eu'
          );
      });
  }

  unsubscribeSubscriber() {
    this.subscriber.unsubscribe();
  }

  hideRouter(event: boolean) {
    this.hide = event;
  }


  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)){
      sessionStorage.removeItem('query_params');
    }
  }

}
