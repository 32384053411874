import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../components/specialities-insurance/index/index.module').then(
        (m) => m.IndexModule
      ),
  },

  {
    path: 'post-contratacion',
    loadChildren: () =>
      import('../../components/posthiring/posthiring.module').then(
        (m) => m.PostHiringModule
      ),
  },

  {
    path: 'dental',
    data: {
      title: 'Dental'
    },
    loadChildren: () =>
      import('../specialities-insurance/dental-v2/dental-v2.module').then(
        m => m.DentalV2Module
      )
  },
  {
    path: 'dermatologia',
    data: {
      title: 'Dermatología'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/dermatology-v2/dermatology-v2.module').then(
        m => m.DermatologyV2Module
      )
  },
  {
    path: 'medicina-general',
    data: {
      title: 'Medicina general'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/general-medicine/general-medicine.module').then(
        m => m.GeneralMedicineModule
      )
  },
  {
    path: 'ginecologico',
    data: {
      title: 'Ginecológico'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/gynaecology-v2/gynaecology-v2.module').then(
        m => m.GynaecologyV2Module
      )
  },
  {
    path: 'enfermeria',
    data: {
      title: 'Enfermería'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/nursing/nursing.module').then(
        m => m.NursingModule
      )
  },
  {
    path: 'oftalmologia',
    data: {
      title: 'Oftalmología'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/ophthalmology-v2/ophthalmology-v2.module').then(
        m => m.OphthalmologyV2Module
      )
  },
  {
    path: 'ninos',
    data: {
      title: 'Pediatría'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/pediatrics/pediatrics.module').then(
        m => m.PediatricsModule
      )
  },
  {
    path: 'traumatologia',
    data: {
      title: 'Traumatología'
    },
    loadChildren: () =>
      import('../../components/specialities-insurance/traumatology/traumatology.module').then(
        m => m.TraumatologyModule
      )
  },

  // LANDINGS:
  {
    path: 'clientes',
    loadChildren: () =>
      import('../../components/specialities-insurance/index/index.module').then(
        (m) => m.IndexModule
      ),
  },
  {
    path: 'mobile',
    loadChildren: () =>
      import('../mobile-view/mobile-view.module').then(
        (m) => m.MobileViewModule
      ),
  },
  {
    path: 'mobile-2',
    loadChildren: () =>
      import('../mobile-view/mobile-view.module').then(
        (m) => m.MobileViewModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FamilyInsuranceRoutingModule { }
