import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeDate',
})
export class SanitizeDatePipe implements PipeTransform {
  transform(spanishDate: string) {
    console.warn('SPANISH DATE', spanishDate);
    
    const cleaned = spanishDate.replace('-', '/').replace(' ', '').trim();
    console.warn('SPANISH DATE 2', cleaned);
    const split = cleaned.split('/');
    console.warn('SPANISH DATE 3', split);
    const standard = `${split[0]}/${split[1]}/${split[2]}`;
    console.warn('SPANISH DATE 4', standard);
    const sanitizedDate = new Date(standard);

    return sanitizedDate;
  }
}
