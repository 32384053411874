import {
  Component,
  HostListener,
  OnInit,
  AfterViewInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { IsCustomerService } from '../../../services/isCustomer/is-customer.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { FunctionUtilsService } from '../../../services/function-utils.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-navbar-desktop',
  templateUrl: './navbar-desktop.component.html',
  styleUrls: ['./navbar-desktop.component.scss'],
})
export class NavbarDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  actualURL: any;

  @Output() unsubscribeEventNavbar = new EventEmitter<any>();

  @ViewChild('navbarToggler')
  navbarToggler!: ElementRef<any>;
  @ViewChild('callMe') private callMe!: ElementRef;
  @ViewChild('callMeMob') private callMeMob!: ElementRef;
  private readonly unsubscribe$: Subject<void> = new Subject();
  modalOpen: boolean = false;
  menuOpen: boolean = false;
  clickEvent: any;
  trackBySide: any;

  showTestItModal = false;

  dropdown = false;
  dropdown2 = false;

  isMobile: boolean;
  isCustomer: boolean = true;
  numberOfMonths: string;
  hideHeaderByside = false;
  isHiring = false;
  isBySideLoaded = false;
  isRenderedDialogs = false;
  isMobileViewComponent = false;
  displayFuet = false;
  isLandingPage = false;
  codeCopiedToClipboard = false;
  isOnlineHealthLanding = false;

  hasQueryParam = false;
  queryParamValue: Params;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // Muestra u oculta la "mosca":
      if (
        !this.router.url.includes('/') &&
        !this.router.url.includes('/seguros-medicos/mobile') &&
        !this.router.url.includes('/seguros-medicos/seguro-integral-dkv') &&
        !this.router.url.includes('/seguros-medicos/mobile-2') &&
        !this.router.url.includes('/seguros-medicos/clientes') &&
        !this.router.url.includes('/cuadro-medico-dkv')
      ) {
        const elem = document.getElementsByClassName(
          'bwc_lateral'
        ) as HTMLCollectionOf<HTMLElement>;
        let scrollOffset = $event.target.children[0].scrollTop;
        if (elem[0] !== undefined) {
          if (scrollOffset >= 295) {
            elem[0].style.display = 'block';
          } else {
            elem[0].style.display = 'none';
          }
        }
      }
    }
  }

  constructor(
    private router: Router,
    private utils: FunctionUtilsService,
    private analyticsService: AnalyticsService,
    private renderer2: Renderer2,
    public isCustomerService: IsCustomerService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: string
  ) { }

  ngOnInit(): void {

    this.unsubscribeEventNavbar.emit('');

    this.isMobile = this.utils.getIsMobile()

    let firstTime = true;

    this.isHiring = this.router.url.includes('/contratacion');

    this.checkOnlineHealthLanding()

    this.activatedRoute.queryParams.subscribe((qp: Params) => {

      if (firstTime && qp.utm) {
        this.hasQueryParam = Object.entries(qp)[0][0] === 'utm' || false;
        this.queryParamValue = qp;

        firstTime = false;

        return;
      }

      if (this.hasQueryParam) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: this.queryParamValue,
          queryParamsHandling: 'merge',
        });
      }
    });

    this.isMobileViewComponent =
      this.router.url.includes('/seguros-medicos/mobile') ||
      this.router.url.includes('/seguros-medicos/mobile-2');

    // this.displayFuet = this.router.url === '/' || this.router.url === '/?cliente=true';

    this.isLandingPage = this.router.url.includes('/seguros-medicos/clientes');

    this.isCustomerService.isCustomer$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer) => {
        this.isCustomer = customer;
      });

    if (isPlatformBrowser(this.platformId)) {
      this.actualURL = location.pathname;
    }
  }

  ngAfterViewInit() {

    this.router.events.subscribe((val: any) => {

      // this.displayFuet = this.router.url === '/';

      this.isLandingPage = this.router.url.includes(
        '/seguros-medicos/clientes'
      );

      this.isMobileViewComponent =
        this.router.url.includes('/seguros-medicos/mobile') ||
        this.router.url.includes('/seguros-medicos/mobile-2');

     // if (!this.isBySideLoaded) this.loadBySideLibrary();

     // if (!this.isRenderedDialogs) {
    //    this.renderDialogsBySide();
    //  }

      this.clickEvent = this.analyticsService.getPromoCTA();

      if (val instanceof NavigationEnd) {

        this.checkOnlineHealthLanding()

        this.isHiring = val.url.includes('/contratacion');

        this.hideHeaderByside = !val.url.includes('/seguros-medicos');

        this.dropdown = false;

        this.utils.setIsIndexPage(
          val.url === '/ayuda' || val.url === '/oferta-clientes-yoigo'
        );

        if (this.actualURL !== val.url) {
          if (isPlatformBrowser(this.platformId)) {
            if (!val.url.includes('/ayuda/')) {
              window.scrollTo(0, 0);
            }
          }

          this.actualURL = val.url;

          if (this.utils.getIsMobile() && this.menuOpen)
            this.navbarToggler.nativeElement.click();

          this.addBysidePhone();
        }

        if (
          val.url.includes('/seguros-medicos') ||
          val.url === '/' ||
          val.url.includes('/seguros-medicos?cliente=false') ||
          val.url.includes('/?cliente=false')
        ) {
          this.utils.setIsIndexPage(true);
        }

        // COLOCAR
        if (isPlatformBrowser(this.platformId)) {
          const elem = document.getElementsByClassName(
            'bwc_lateral'
          ) as HTMLCollectionOf<HTMLElement>;
          let scrollOffset = window.document.children[0].scrollTop;
          let lateral = new Promise(function (resolve, reject) {
            let interval = setInterval(function () {
              if (elem[0]) {
                clearInterval(interval);
                resolve(true);
              }
            }, 1000);
          });

          lateral.then(() => {
            if (
              !this.router.url.includes('/seguros-medicos') &&
              !this.router.url.includes('/')
            ) {
              scrollOffset = window.document.children[0].scrollTop;
              if (scrollOffset >= 295) {
                elem[0].style.display = 'block';
              } else {
                elem[0].style.display = 'none';
              }
            }
          });
        }
      }
    });

   // if (isPlatformBrowser(this.platformId)) {
   //   if (!this.isBySideLoaded) this.loadBySideLibrary();

    //  this.renderDialogsBySide();
   // }

  }

  openByside() {
    this.utils.hireOnline(1059);
  }

  clickDropDown() {
    this.dropdown = !this.dropdown;
  }

  clickDropDown2() {
    this.dropdown2 = !this.dropdown2
  }

  openMenu() {
    this.menuOpen = !this.menuOpen;
    this.menuOpen
      ? this.renderer2.addClass(document.body, 'scrollOff')
      : this.renderer2.removeClass(document.body, 'scrollOff');
  }

  closeMenu() {
    this.navbarToggler.nativeElement.click();
  }

  openModal() {
    this.utils.hireOnline(199);
  }

  openModalTryApp() {
    this.analyticsService.sendCustomEvent({
      eventAction: 'Accion/Mas Info',
      element: 'PRUEBA GRATIS LA APP',
    });

    this.showTestItModal = true;
  }

  onClickEvent(e: any) {
    this.numberOfMonths = this.isCustomer ? '2 meses' : '1 MES';
    if (this.router.url.includes('/')) {
      this.showTestItModal = true;
    } else {
      this.utils.hireOnline(1059);
    }
    if (isPlatformBrowser(this.platformId)) {
      if (this.analyticsService.getIsFirstTimeIWant()) {
        this.analyticsService.sendCustomEvent({
          eventAction: 'Accion/Mas Info',
          element: 'MÁS INFO',
        });
      }
    }
  }

  loadBySideLibrary() {
    if (this.displayFuet || this.isLandingPage) {
      return;
    }

    let script = this.renderer2.createElement('script');
    script.text = '';

    script.text = `
          var bysideWebcare_webcare_id = "BA07BC42F0";
          var bysideWebcare_privacy = 1;
          var bysideWebcare_lang = "es";
        `;

    this.renderer2.appendChild(document.head, script);
    let script1 = this.renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.async = true;
    script1.src = `//bywe2.byside.com/agent/byside_webcare_we2.js`;
    // script1.src = "../../../../assets/libraries/doctorGo_webcare_we2_v20201123a.js";
    this.renderer2.appendChild(document.head, script1);

    this.isBySideLoaded = true;
  }

  doctorGoBanner = null;

  renderDialogsBySide() {
    if (this.displayFuet || this.isLandingPage || this.isHiring) {
      return;
    }

    // if (!this.isBySideLoaded) this.loadBySideLibrary();

    if (isPlatformBrowser(this.platformId)) {
      let step1 = new Promise(function (resolve, reject) {
        let interval = setInterval(function () {
          if (window['bysideWebcare_placeholder_loadnow']) {
            clearInterval(interval);
            resolve(true);
          }
        }, 1000);
      });

      let step2 = new Promise(function (resolve, reject) {
        let interval = setInterval(function () {
          /*if (
            document.getElementById('bw_widget_9604_1966') ||
            document.getElementById('bw_widget_9612_1966')
          ) {

          }*/

          clearInterval(interval);
          resolve(true);

        }, 1000);
      });

      step1
        .then(() => {

          document.getElementById('callMe1').innerHTML = '';

          window['bysideWebcare_placeholder_loadnow'](
            'doctorgo_900',
            undefined,
            'callMe1'
          );

          new Promise((resolve, reject) => {
            let interval = setInterval(() => {

              if (this.isHiring) {
                clearInterval(interval)
                return;
              }

              if (document.querySelector('#callMe1').querySelector('.bwc_doctorgo_900')) {

                const el = document.querySelector('#callMe1')
                  .querySelector('.bwc_doctorgo_900')

                if (this.doctorGoBanner) {
                  el.replaceWith(this.doctorGoBanner)
                  this.doctorGoBanner = null;
                }

                clearInterval(interval);
                resolve(true);
              }
            }, 100);
          });

          return step2;
        })
        .then(() => {
          this.router.events.subscribe((val) => {
            this.checkOnlineHealthLanding()

            if (val instanceof NavigationEnd) {

              if (val.url.includes('contratacion'))
                return;

              this.isMobileViewComponent =
                val.url.includes('/seguros-medicos/mobile') ||
                val.url.includes('/seguros-medicos/mobile-2');


              Array.from(document.querySelectorAll('[id*="webcarePopup"]')).map((item) => item.remove())

              Array.from(document.querySelectorAll('script')).filter(scr => { return scr.innerHTML.includes('webcareslotcontainer') })
                .forEach(script => script.remove())

              Array.from(document.querySelectorAll('style')).filter(scr => { return scr.innerHTML.includes('webcareslotcontainer') })
                .forEach(style => style.remove());


              window['bysideWebcareTrackSubPage'](window.location.href, true);

              document.getElementById('callMe1').innerHTML = '';

              window['bysideWebcare_placeholder_loadnow'](
                'doctorgo_900',
                undefined,
                'callMe1'
              );

              new Promise((resolve, reject) => {
                let interval = setInterval(() => {
                  if (document.querySelector('#callMe1').querySelector('.bwc_doctorgo_900')) {
                    const el = document.querySelector('#callMe1')
                      .querySelector('.bwc_doctorgo_900')

                    if (this.doctorGoBanner) {
                      el.replaceWith(this.doctorGoBanner)
                      this.doctorGoBanner = null;
                    }
                    clearInterval(interval);
                    resolve(true);
                  }
                }, 100);
              });

              if (this.displayFuet || this.isLandingPage) {
                return;
              }
            }
          });
        });

      this.isRenderedDialogs = true;
    }
  }

  appendBySideHTML(value) {
    if (value !== '') {
      const htmlId = this.isMobile ? 'phcoMobile' : 'phco';
      const html = document.getElementById(htmlId);

      if (html) {
        html.innerHTML = this.isMobile
          ? `
          <div class="d-flex align-items-center"><p class="w-100 text-white mr-2 mr-md-4 fs-14">
          Llámanos gratis
          <a href="tel:${value}" class="btn p-0 text-white fs-14 fs-sm-16" role="button">
            <strong>${value}</strong>
          </a>
          <button id="modalBtn" type="button" class="click-to-call-cta d-flex align-items-center pt-2 pb-2 pl-2 pr-2 pl-sm-4 pr-sm-4 align-content-center text-white bg-primary-main-dark" (click)="openModal()">
            <span class="icon-icn-devices d-flex align-items-center mr-1 text-white"></span>
            Te llamamos
          </button>
          </div>`
          : `
          <div id="modalBtn" class="d-flex align-items-center" (click)="openModal()">
          <p class="w-100 text-white mr-2 mr-md-4 fs-14">Llámanos gratis
            <span class="btn p-0 text-white fs-14 fs-sm-16" role="button">
              <strong>${value}</strong>
            </span>
          </p>
          <button type="button" class="click-to-call-cta d-flex align-items-center pt-2 pb-2 pl-2 pr-2 pl-sm-4 pr-sm-4 align-content-center text-white bg-primary-main-dark">
            <span class="icon-icn-devices d-flex align-items-center mr-1 text-white"></span>
            Te llamamos
          </button>
          </div>`;

        html.innerHTML = this.isMobile
          ? `
          <div class="d-flex align-items-center"><p class="w-100 text-gray mr-2 mr-md-4 fs-18">
            Llámanos gratis
            <a href="tel:${value}" class="btn p-0 text-gray fs-18 fs-sm-16" role="button">
              <strong>${value}</strong>
            </a>
          </p>
          <button id="modalBtn" type="button" class="click-to-call-cta d-flex align-items-center pt-1 pb-1 pl-1 pr-1 pl-sm-1 pr-sm-1 align-content-center text-gray bg-border-black fs-20 weight-700" (click)="openModal()">
            <span class="icon-icn-devices d-flex align-items-center mr-1 text-gray"></span>
            Te llamamos
          </button>
          </div>`
          : `
          <div id="modalBtn" class="d-flex align-items-center" (click)="openModal()"><p class="w-100 text-gray mr-2 mr-md-4 fs-18">
            Llámanos gratis
            <span class="btn p-0 text-gray fs-18 fs-sm-16" role="button">
              <strong>${value}</strong>
            </span>
            <button type="button" class="click-to-call-cta d-flex align-items-center pt-1 pb-1 pl-1 pr-1 pl-sm-1 pr-sm-1 align-content-center text-gray bg-border-black fs-20 weight-700">
              <span class="icon-icn-devices d-flex align-items-center mr-1 text-gray"></span>
              Te llamamos
            </button>
          </div>`;

        document.getElementById('modalBtn').addEventListener('click', (evt) => {
          this.utils.hireOnline(199);
        });
      }
    }
  }

  addNavBarPhoneContent() {
    const phCo = document.getElementById('phco');
    phCo ? phCo.remove() : '';

    let phoneContentMobile = this.renderer2.createElement('p');
    phoneContentMobile.id = 'phcoMobile';

    let phoneContent = this.renderer2.createElement('p');
    phoneContent.id = 'phco';

    let step = new Promise((resolve) => {
      let interval = setInterval(function () {
        if (document.getElementById('bwc_calltracker_ga')) {
          clearInterval(interval);
          resolve(true);
        }
      }, 1000);
    });

    step.then(() => {
      if (isPlatformBrowser(this.platformId)) {
        document
          .getElementsByClassName('wb_calltracker_phone')[0]
          .addEventListener('DOMSubtreeModified', (event) => {
            this.appendBySideHTML(event.target['innerText']);
          });
      }

      if (!this.isHiring) {
        this.renderer2.appendChild(this.callMe.nativeElement, phoneContent);
      } else {
        this.renderer2.appendChild(
          this.callMe.nativeElement,
          !this.isMobile ? phoneContentMobile : phoneContent
        );
      }
    });
  }

  addBysidePhone() {
    if (isPlatformBrowser(this.platformId)) {
      const bysideTel = document.getElementsByClassName('tel-byside')[0];
      let dynamicPhoneHtml;
      if (
        document.getElementsByClassName('wb_calltracker_phone')[0] !== undefined
      ) {
        dynamicPhoneHtml = document.getElementsByClassName(
          'wb_calltracker_phone'
        )[0].innerHTML;
      }

      if (bysideTel)
        bysideTel.innerHTML = `Llámanos gratis al ${dynamicPhoneHtml}`;
    }
  }

  sendCustomEvent(ctaText: string, position: string) {
    const dataToSend = {
      eventAction: 'Accion/Abrir C2C',
      eventLabel: 'Abrir',
      ctaText,
      position
    }
    this.analyticsService.sendCustomEvent(dataToSend)
    this.utils.hireOnline(1059);
  }

  copyToClipboard() {
    navigator.clipboard.writeText('REQUETESANO');

    this.codeCopiedToClipboard = true;
    setTimeout(() => {
      this.codeCopiedToClipboard = false;
    }, 1500);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkOnlineHealthLanding() {
    this.router.url.includes('') ? this.isOnlineHealthLanding = true : this.isOnlineHealthLanding = false
  }
}
