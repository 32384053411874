import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MeetingApiService } from '../../../services/meeting-api/meeting-api.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { Subject } from 'rxjs';
import { VerificationBody } from 'src/server/interfaces/meetingInterfaces';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-yoigo-info',
  templateUrl: './yoigo-info.component.html',
  styleUrls: ['./yoigo-info.component.scss'],
})
export class YoigoInfoComponent implements OnInit {

  @Output() continue = new EventEmitter<{
    form: FormGroup;
    isClient: boolean;
  }>();
  continueDebouncer: Subject<any> = new Subject<any>();
  @Output() continueInvitation = new EventEmitter<boolean>();
  continueInvitationDebouncer: Subject<any> = new Subject<any>();


  checkboxes = [
    {
      text: 'He leido y acepto la <a href="/assets/documents/salud-online/Politica-de-privacidad-y-condiciones-de-uso-App-Salud-Online.pdf">política de privacidad y condiciones de uso.</a>',
      value: false,
      required: true,
    },
  ];

  error = '';
  autofocus = true;
  loading = false;



  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private meetingService: MeetingApiService,
    private segmentService: SegmentService,
    public onboardingStateService: OnboardingStateService,
    private http: HttpClient,
  ) {
    // this.onboardingStateService.verificationForm.controls.invitation.setValue(true); // Establecer en true para que lo haga como si has introducido el código
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueClick();
    });

    this.continueInvitationDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueInvitation.emit();
    });

  }

  ngOnInit(): void {

    this.segmentService.sendSegmentEvent('Onboarding Started', null);

    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'DATOS ACCESO',
          screen_class: 'DATOS ACCESO',
        },
      },
    });

    this.onboardingStateService.hideBackButton = true;

    this.segmentService.sendSegmentEvent(
      'Onboarding Verification Request Started',
      null
    );
  }

  continueClick() {
    this.error = '';
    this.loading = true;

    this.meetingService.validateMasStack(this.onboardingStateService.form.controls.phone.value)
    .subscribe(response => {

      const body: VerificationBody = {
        phone: this.onboardingStateService.form.controls.phone.value,
        nid: this.onboardingStateService.form.controls.document.value,
        nid_type: this.onboardingStateService.form.controls.nieDni.value,
        user_type: response.isYoigo
          ? 'yoigo'
          : 'noYoigo',
        terms: '', // this.onboardingStateService.form.controls.terms.value,
        invitation_code:
          this.onboardingStateService.verificationForm.controls.invitation.value,
      };

      this.onboardingStateService.isYoigo.next(response.isYoigo)

      this.meetingService.validate(body).subscribe(
        (r) => {

          this.webviewEvents.emitEvent({
            action: Actions.CLICK,
            payload: this.onboardingStateService.isYoigo.value
              ? 'CONTINUAR COMO CLIENTE'
              : 'CONTINUAR COMO NO CLIENTE',
          });


          this.segmentService.sendSegmentIdentifyEvent(this.onboardingStateService.form.value.document.toUpperCase().padStart(9, '0'), {
            isYoigo: this.onboardingStateService.isYoigo.value,
            phone: this.onboardingStateService.form.value.phone,
            dninie: this.onboardingStateService.form.value.nieDni,
            document: this.onboardingStateService.form.value.document.toUpperCase().padStart(9, '0'),
            stack: response.stack
          });

          this.onboardingStateService.stackType = response.stack;

          this.segmentService.sendSegmentEvent('Onboarding Started as', {
            data: { isYoigo: response.isYoigo, stack: response.stack },
          });

          this.segmentService.sendSegmentEvent(
            'Onboarding Verification Request Submitted',
            {
              data: {
                phone: this.onboardingStateService.form.controls.phone.value,
                nieDni: this.onboardingStateService.form.controls.nieDni.value,
                document: this.onboardingStateService.form.controls.document.value,
                isClient: this.onboardingStateService.isYoigo.value,
              },
            }
          );

          const data = {
            document: this.onboardingStateService.form.value.document.toUpperCase(),
            phone: this.onboardingStateService.form.value.phone,
          };

          this.meetingService.sendVerificationCode(data).subscribe(
            (responseSendCode) => {
             // console.log(response);
             // todo : send success to segment

              this.segmentService.sendSegmentEvent('Onboarding OTP Success', {data: responseSendCode})
              this.onboardingStateService.hideBackButton = true;

              this.continue.emit({
                form: this.onboardingStateService.form.value,
                isClient: this.onboardingStateService.isYoigo.value,
              });

            },
            (err) => {

              this.error = 'Hubo un error al enviar el código de verificación. Vuelve a intentarlo más tarde.';
              this.loading = false;

              this.segmentService.sendSegmentEvent('Onboarding OTP Send Code Error', {
                data: {error: err, data},
              });

            }
          );

        },
        (err) => {
          this.segmentService.sendSegmentEvent(
            'Onboarding Verification Request Error',
            {
              data: {
                payload: {
                  email: this.onboardingStateService.form.value.email,
                  phone: this.onboardingStateService.form.value.phone,
                  dninie: this.onboardingStateService.form.value.dninie,
                  document: this.onboardingStateService.form.value.document.toUpperCase().padStart(9, '0'),
                  isClient: this.onboardingStateService.isYoigo.value,
                },

                error: err

              },
            }
          );

          this.error = 'Hubo un error al verificar los datos de usuario. Vuelve a intentarlo.';

          this.loading = false;

        }
      );

    }, err => {

      if (err.status === 401) {
        const error = err.error;

        this.segmentService.sendSegmentEvent('Onboarding Invalid Token Error', {
          data: {
            message: error.error,
            jwt: localStorage.getItem('onboarding-token') || '',
          }
        })
      }

      this.loading = false;

    })


  }
}
