<footer class="footer-container" *ngIf="!isHiring">
  <div class="banner" *ngIf="!isIndexPageResult && !isMobileViewComponent">

    <div *ngIf="!isLandingPage && canRenderFooter" class="banner-container container">
      <div class="banner-content w-100 d-flex align-items-center justify-content-center">
        <div class="d-flex flex-column">
          <p>Prueba {{isCustomer ? '2 meses' : '1 mes'}} gratis la app de telemedicina DoctorGO, sin compromiso y
            cancela cuando quieras.</p>
        </div>

        <button class="loquiero btn text-blue-text bg-white" role="button" (click)="onClickEvent($event)">{{ isCustomer
          ? 'PRUEBA 2 meses GRATIS' : 'PRUEBA 1 MES GRATIS'}}</button>
      </div>
    </div>
  </div>
  <div class="container-accordion-wrapper">
    <div class="container-accordion d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start">

      <!-- General footer sections -->
      <div id="accordionFooter" class="d-lg-none">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0 w-100">
              <button type="button"
                class="btn btn-link collapsed d-flex justify-content-between align-content-center w-100"
                data-toggle="collapse" data-target="#collapseFooterOne" aria-expanded="false"
                aria-controls="collapseFooterOne">
                TELEMEDICINA
                <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
              </button>
            </h5>
          </div>

          <div id="collapseFooterOne" class="collapse collapse" aria-labelledby="headingOne"
            data-parent="#accordionFooter">
            <div class="card-body">
              <ul>
                <li><a href="/alergologo">Alergología</a></li>
                <li><a href="/cardiologo">Cardiología</a></li>
                <li><a href="/dermatologo">Dermatología</a></li>
                <li><a href="/ginecologo">Ginecología</a></li>
                <li><a href="/nutricionista">Nutricionista</a></li>
                <li><a href="/pediatra">Pediatría</a></li>
                <li><a href="/psicologo">Psicología</a></li>
                <li><a href="/sexologo">Sexología</a></li>
                <li><a href="/medico-general-online">Médico General</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingSix">
            <h5 class="mb-0 w-100">
              <button type="button"
                class="btn btn-link collapsed d-flex justify-content-between align-content-center w-100"
                data-toggle="collapse" data-target="#collapseFooterSix" aria-expanded="false"
                aria-controls="collapseFooterSix">
                NUTRICIÓN Y FITNESS
                <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
              </button>
            </h5>
          </div>
          <div id="collapseFooterSix" class="collapse" aria-labelledby="headingThree" data-parent="#accordionFooter">
            <div class="card-body">
              <ul>
                <li><a class="text-white fs-16" href="/nutricionista">Nutricionista</a></li>
                <li><a class="text-white fs-16" href="/entrenador-personal-online">Entrenador personal</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0 w-100">
              <button type="button"
                class="text-left btn btn-link collapsed d-flex justify-content-between align-content-center w-100"
                data-toggle="collapse" data-target="#collapseFooterThree" aria-expanded="false"
                aria-controls="collapseFooterThree">
                TELEFARMACIA Y RECETA ELECTRÓNICA
                <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
              </button>
            </h5>
          </div>
          <div id="collapseFooterThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionFooter">
            <div class="card-body">
              <ul>
                <li><a href="telefarmacia-y-receta-electronica" target="_blank">TELEFARMACIA Y RECETA
                    ELECTRÓNICA</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0 w-100">
              <button type="button"
                class="btn btn-link collapsed d-flex justify-content-between align-content-center w-100"
                data-toggle="collapse" data-target="#collapseFooterThree" aria-expanded="false"
                aria-controls="collapseFooterThree">
                BLOG
                <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
              </button>
            </h5>
          </div>
          <div id="collapseFooterThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionFooter">
            <div class="card-body">
              <ul>
                <li><a href="https://doctorgo.es/blog/" target="_blank">Blog DoctorGO</a></li>
              </ul>
            </div>
          </div>
        </div>



        <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0 w-100">
              <button type="button"
                class="btn btn-link collapsed d-flex justify-content-between align-content-center w-100"
                data-toggle="collapse" data-target="#collapseFooterFour" aria-expanded="false"
                aria-controls="collapseFooterFour">
                CONTACTO Y AYUDA
                <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
              </button>
            </h5>
          </div>
          <div id="collapseFooterFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionFooter">
            <div class="card-body">
              <ul>
                <li><a href="/contacto" target="_blank">Contacto</a></li>
                <li><a href="/ayuda" target="_blank">Ayuda</a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="card">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0 w-100">
                <button type="button" class="btn btn-link collapsed d-flex justify-content-between align-content-center w-100" data-toggle="collapse" data-target="#collapseFooterFour" aria-expanded="false" aria-controls="collapseFooterFour">
                  AYUDA
                  <span class="icon-icn-arrow-down d-flex align-items-center fs-24 text-white"></span>
                </button>
              </h5>
            </div> -->
        <!-- <div id="collapseFooterFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionFooter">
              <div class="card-body"> -->
        <!-- <ul>
                  <li><a href="ayuda">¿Salud online o seguro familiar?</a></li>
                  <li><a href="ayuda">¿Qué es un plan de salud?</a></li>
                  <li><a href="ayuda">¿Me puedo dar de baja cuando quiera?</a></li>
                </ul> -->
        <!-- <ul>
                  <li><a href="ayuda">FAQs</a></li>
                </ul>
              </div>
            </div> -->
        <!-- </div> -->
      </div>


      <!-- Footer sections on responsive -->
      <div class="sections-desktop d-none d-lg-flex flex-row justify-content-between w-100">
        <div class="d-flex flex-column">
          <h4 class="fs-18 text-white">TELEMEDICINA</h4>
          <ul>
            <li><a class="text-white fs-16" href="/alergologo">Alergología</a></li>
            <li><a class="text-white fs-16" href="/cardiologo">Cardiología</a></li>
            <li><a class="text-white fs-16" href="/dermatologo">Dermatología</a></li>

            <li><a class="text-white fs-16" href="/ginecologo">Ginecología</a></li>
            <li><a class="text-white fs-16" href="/pediatra">Pediatría</a></li>
            <li><a class="text-white fs-16" href="/psicologo">Psicología</a></li>
            <li><a class="text-white fs-16" href="/sexologo">Sexología</a></li>
            <li><a class="text-white fs-16" href="/medico-general-online">Médico General</a></li>

          </ul>
        </div>

        <div class="d-flex flex-column">
          <h4 class="fs-18 text-white">NUTRICIÓN Y FITNESS</h4>
          <ul>
            <li><a class="text-white fs-16" href="/nutricionista">Nutricionista</a></li>
            <li><a class="text-white fs-16" href="/entrenador-personal-online">Entrenador personal</a></li>
          </ul>
        </div>

        <div class="d-flex flex-column">
          <a href="telefarmacia-y-receta-electronica">
            <h4 class="fs-18 text-white">TELEFARMACIA Y RECETA ELECTRÓNICA</h4>
          </a>
        </div>

        <div class="d-flex flex-column">
          <h4 class="fs-18 text-white">BLOG</h4>
          <ul>
            <li><a class="text-white fs-16" href="https://doctorgo.es/blog/" target="_blank">Blog DoctorGO</a></li>
          </ul>
        </div>
        <div class="d-flex flex-column">
          <h4 class="fs-18 text-white">CONTACTO Y AYUDA</h4>
          <ul>
            <li><a class="text-white fs-16" href="/contacto" target="_blank">Contacto</a></li>
            <li><a class="text-white fs-16" href="/ayuda" target="_blank">Ayuda</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="apps pb-5 pt-3">
      <p class="apps__title mb-3">
        Descarga gratis la App de Salud Online:
      </p>
      <div class="apps__img-box">
        <a href="https://play.google.com/store/apps/details?id=com.yoigo.doctorgo&hl=es_419&gl=US" target="_blank"
          rel="noopener noreferrer" class="apps__img-box__link-box"><img src="../../../assets/images/home-v3/play.svg"
            alt="imagen no disponible" class="apps__img-box__link-box__img"></a>
        <a href="https://apps.apple.com/es/app/telemedicina-24h-doctorgo/id1527729464" target="_blank"
          rel="noopener noreferrer" class="apps__img-box__link-box"><img
            src="../../../assets/images/home-v3/applestore.svg" alt="imagen no disponible"
            class="apps__img-box__link-box__img"></a>
      </div>
    </div>
    <div class="social-media-wrapper d-flex flex-column flex-lg-row justify-content-between align-items-start">
      <div class="social-media w-100 d-flex flex-row social-media-justify-content gap-40">
        <a href="https://twitter.com/DoctorGo_" rel="nofollow" target="_blank">
          <!-- <span class="icon-icn-twitter text-white fs-40 mr-4"></span> -->
          <img src="../../../../assets/images/x.svg" alt="no disponible">
        </a>
        <a href="https://www.facebook.com/DoctorGO.es" rel="nofollow" target="_blank">
          <!-- <span class="icon-icn-facebook text-white fs-40 mr-4"></span> -->
          <img src="../../../../assets/images/facebook.svg" alt="no disponible">
        </a>
        <a href="https://instagram.com/doctorgo.es?utm_medium=copy_link" rel="nofollow" target="_blank">
          <img src="../../../../assets/images/newinstagram.svg" alt="no disponible">
        </a>
        <a href="https://www.youtube.com/playlist?list=PLmJsu6HoS0VEW2QnXVM4kjV3QZAquvecu" rel="nofollow"
          target="_blank" class="d-flex flex-row justify-content-center align-items-center">
          <img src="../../../../assets/images/newyoutube.svg" class="youtube" alt="no disponible">
        </a>
      </div>

      <div class="legal-links d-flex flex-column flex-md-row align-items-center w-100">
        <div class="d-flex flex-row justify-content-around align-content-center">
          <nav class="nav d-flex flex-row justify-content-start w-100" aria-label="">
            <a href="/informacion-legal" rel="nofollow" class="smaller-title pr-2 pl-2 addLine">
              Información Legal
            </a>
            <a href="/cookies" rel="nofollow" class="smaller-title pr-2 pl-2 addLine">
              Cookies
            </a>
            <a href="/legal" rel="nofollow" class="smaller-title pr-2 pl-2">
              Bases sorteos / Promos
            </a>
          </nav>
        </div>
        <span class="smaller-title d-flex align-items-center pl-2">&copy; Grupo MASMOVIL {{actualYear}}</span>
      </div>
    </div>

  </div>

</footer>

<app-test-it-modal *ngIf="showTestItModal" (close)="showTestItModal=false" [numberOfMonths]="numberOfMonths">
  <app-test-it-modal>
