import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { FormService } from '../../../services/form/form.service';

@Component({
  selector: 'app-callme-form',
  templateUrl: './callme-form.component.html',
  styleUrls: ['./callme-form.component.scss'],
})
export class CallmeFormComponent implements OnInit, OnDestroy {
  @Input()
  from!: string;
  @Input()
  isEmail!: boolean;
  @Input()
  lgRow!: boolean;
  @Input()
  blackWhite!: boolean;

  callMeForm!: FormGroup;
  dataToSend: any;
  responseOK = false;
  responseKO = false;
  timeoutOK: any;

  constructor(
    private analyticsService: AnalyticsService,
    private formService: FormService,
  ) { }

  ngOnInit(): void {
    this.callMeForm =
      !this.isEmail && !this.blackWhite
        ? new FormGroup({
          phone: new FormControl('', [
            Validators.required,
            Validators.pattern(/^(\+34|0034|34)?[67]\d{8}$/),
          ]),
        })
        : new FormGroup({
          email: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
          ]),
        });

    if (this.blackWhite && !this.isEmail) {
      this.callMeForm =
        this.blackWhite && !this.isEmail
          ? new FormGroup({
            phone: new FormControl('', [Validators.required]),
            accept: new FormControl(false, [Validators.requiredTrue]),
          })
          : new FormGroup({
            email: new FormControl('', [
              Validators.required,
              Validators.email,
            ]),
            accept: new FormControl(false, [Validators.requiredTrue]),
          });
    }
  }

  onSubmit = () => {
    if (!this.isEmail) {
      this.sendPhone();
      this.analyticsService.sendCustomEvent({
        eventAction: 'Accion/Solicitud Informacion',
        eventLabel: 'Telefono',
        element: 'Enviar',
      });
    } else {
      this.sendEmail();
      this.analyticsService.sendCustomEvent({
        eventAction: 'Accion/Solicitud Informacion',
        eventLabel: 'Email',
        element: 'Enviar',
      });
    }
  };

  sendPhone() {
    this.dataToSend = {
      msisdn: Number(this.callMeForm.get('phone').value),
    };

    this.formService.sendData(this.dataToSend).subscribe(
      (response) => {
        this.responseOK = true;
        this.timeoutOK = setTimeout(() => {
          this.responseOK = false;
          this.callMeForm.reset();
        }, 5000);
      },
      (error) => {
        this.responseKO = true;
        this.timeoutOK = setTimeout(() => {
          this.responseKO = false;
        }, 5000);
      }
    );
  }

  sendEmail() {
    this.dataToSend = {
      msisdn: this.callMeForm.get('email').value,
    };

    this.formService.sendData(this.dataToSend).subscribe(
      (response) => {
        // console.log('Response email', response);
      },
      (error) => {
        console.error('Error response email:', error);
      }
    );
  }

  ngOnDestroy() {
    if (this.timeoutOK) clearTimeout(this.timeoutOK);
  }
}
