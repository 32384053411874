<div>
    <div class="blue-nav"></div>
    <div class="main-nav">
        <div class="">
            <span class="mobile-icon" style="padding: 1rem;">
                <img src="../../../assets/images/navbar-mobile/menu.svg" alt="imagen no disponible"
                class="header__mid__menu-icon" />
            </span>
        </div>
        <div class="content">
            <div class="logo-doctor">
                <img src="../../../assets/images/doctor-go-logo.svg" width="45" height="auto" />
            </div>
            <div class="logo-yoigo" *ngIf="getDeviceType !== 'sm'">
                <img src="../../../assets/images/logonavbar.svg" height="35" width="auto" />
            </div>
        </div>
    </div>
    <div class="header__container" style="width: 100vw;" *ngIf="isBigScreen">
        <div style="max-width: 1600px; display: flex; align-items: end; justify-content: center;">
            <div class="header__container__text header-responsive mt-3 mb-0" style="margin-inline: 24px;">
                <div class="header-title-box" style="gap:24px">
                    <div *ngIf="getDeviceType == 'xxl'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                        <span class="header-title header-title-font">
                            {{ "Bienvenido al " | uppercase }}
                            <span class="header-title-font light-blue">{{"reloj de teleasistencia 24h." | uppercase }}</span>
                        </span>
                        <span class="header-title header-title-font">
                            {{"Que cuida de ti y de tus mayores." | uppercase }}
                        </span>
                    </div>
                    <div *ngIf="(getDeviceType == 'xl' || getDeviceType == 'l' || getDeviceType == 'md')" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                        <span class="header-title header-title-font">
                            {{"Bienvenido al" | uppercase}}
                            <span class="header-title-font light-blue" style="margin-left: 0;">{{"reloj de" | uppercase}}</span>
                        </span>
                        <span class="header-title header-title-font">
                            <span class="header-title-font light-blue" style="margin-left: 0;;">{{"teleasistencia 24h" | uppercase}}</span>
                            {{"Que cuida" | uppercase}}
                        </span>
                        <span class="header-title header-title-font">
                            {{"de ti y de tus mayores." | uppercase}}
                        </span>
                    </div>
                    <div *ngIf="getDeviceType == 'sm'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                        <span class="header-title header-title-font">
                            {{"Bienvenido al" | uppercase}}
                            <span class="header-title-font light-blue" style="margin-left: 0;">{{"reloj de" | uppercase}}</span>
                        </span>
                        <span class="header-title header-title-font">
                            <span class="header-title-font light-blue" style="margin-left: 0;;">{{"teleasistencia 24h" | uppercase}}</span>
                        </span>
                        <span class="header-title header-title-font">
                            {{"Que cuida de ti y" | uppercase}}
                        </span>
                        <span class="header-title header-title-font">
                            {{"de tus mayores." | uppercase}}
                        </span>
                    </div>
                    <div class="header-title-text flex" style="text-align: start; font-weight: bold; color: white; line-height: 24px;">
                        Un Reloj, que permite a nuestros mayores sentirse protegidos y que proporciona la tranquilidad a los
                        familiares de estar conectados las 24h a través de la APP que incorpora el servicio.
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: flex-end; align-items: end;">
                <img class="header-img" src="../../../../assets/images/onboarding/watch-header.svg" alt="no disponible"/>
            </div>
        </div>
    </div>


    <div class="header__container" *ngIf="!isBigScreen">
        <div class="header__container__text header-responsive mt-3 mb-0" style="margin-inline: 24px;">
            <div class="header-title-box" style="gap:24px">
                <div *ngIf="getDeviceType == 'xxl'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                    <span class="header-title header-title-font">
                        {{ "Bienvenido al " | uppercase }}
                        <span class="header-title-font light-blue">{{"reloj de teleasistencia 24h." | uppercase }}</span>
                    </span>
                    <span class="header-title header-title-font">
                        {{"Que cuida de ti y de tus mayores." | uppercase }}
                    </span>
                </div>
                <div *ngIf="(getDeviceType == 'xl' || getDeviceType == 'l' || getDeviceType == 'md')" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                    <span class="header-title header-title-font">
                        {{"Bienvenido al" | uppercase}}
                        <span class="header-title-font light-blue" style="margin-left: 0;">{{"reloj de" | uppercase}}</span>
                    </span>
                    <span class="header-title header-title-font">
                        <span class="header-title-font light-blue" style="margin-left: 0;;">{{"teleasistencia 24h" | uppercase}}</span>
                        {{"Que cuida" | uppercase}}
                    </span>
                    <span class="header-title header-title-font">
                        {{"de ti y de tus mayores." | uppercase}}
                    </span>
                </div>
                <div *ngIf="getDeviceType == 'sm'" class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px">
                    <span class="header-title header-title-font">
                        {{"Bienvenido al" | uppercase}}
                        <span class="header-title-font light-blue" style="margin-left: 0;">{{"reloj de" | uppercase}}</span>
                    </span>
                    <span class="header-title header-title-font">
                        <span class="header-title-font light-blue" style="margin-left: 0;;">{{"teleasistencia 24h" | uppercase}}</span>
                    </span>
                    <span class="header-title header-title-font">
                        {{"Que cuida de ti y" | uppercase}}
                    </span>
                    <span class="header-title header-title-font">
                        {{"de tus mayores." | uppercase}}
                    </span>
                </div>
                <div class="header-title-text flex" style="text-align: start; font-weight: bold; color: white; line-height: 24px;">
                    Un Reloj, que permite a nuestros mayores sentirse protegidos y que proporciona la tranquilidad a los
                    familiares de estar conectados las 24h a través de la APP que incorpora el servicio.
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: flex-end; align-items: end;">
            <img class="header-img" src="../../../../assets/images/onboarding/watch-header.svg" alt="no disponible"/>
        </div>
    </div>

    <div style="background-color: #F2F2F2; padding-bottom: 16px; position: relative;">
        <div class="header-title-box d-flex flex-column align-items-center pt-5">
            <div class="d-flex flex-column" style="justify-content: flex-start; align-items: flex-start; gap: 16px" *ngIf="getDeviceType == 'l' || getDeviceType == 'xl' || getDeviceType == 'xxl'">
                <span class="header-title header-title-font">
                    ¿QUÉ INCLUYE EL SERVICIO CON
                    <span class="header-title-font light-blue">RELOJ TELEASISTENCIA LAS 24H</span>
                    ?
                </span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center;" *ngIf="getDeviceType == 'md'">
                <span style="width: fit-content;" class="header-title header-title-font">¿QUÉ INCLUYE EL SERVICIO CON</span>
                <span style="width: fit-content;" class="header-title header-title-font light-blue">RELOJ TELEASISTENCIA LAS 24H?</span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center;" *ngIf="getDeviceType == 'sm'">
                <span style="width: fit-content;" class="header-title header-title-font">¿QUÉ INCLUYE EL</span>
                <span style="width: fit-content;" class="header-title header-title-font">SERVICIO CON
                    <span style="width: fit-content;" class="header-title-font light-blue">RELOJ</span>
                </span>
                <span style="width: fit-content;" class="header-title header-title-font">
                    <span style="width: fit-content;" class="header-title-font light-blue">TELEASISTENCIA LAS 24H?</span>
                </span>
            </div>
        </div>
        <div class="d-flex justify-center items-center" style="width: 100vw;">
            <div style="max-width: 1368px;" class="d-flex flex-wrap justify-center items-center" *ngIf="getDeviceType == 'l' || getDeviceType == 'xl' || getDeviceType == 'xxl'">
                <div class="included-item" alt="First slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon mb-2">
                                <img src="../../../../assets/images/onboarding/SOS-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-text">
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Botón SOS</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                    emergencia.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="included-item" alt="Second slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon">
                                <img src="../../../../assets/images/onboarding/caidas-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Detección de caidas</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                emergencia.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="included-item" alt="Third slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon">
                                <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Aviso a emergencias y familiares</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                emergencia.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="included-item" alt="Fourth slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon">
                                <img src="../../../../assets/images/onboarding/geolocalizacion-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Geolocalización</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                El reloj indica a los familiares través de la APP, dónde se encuentra el usuario
                                del reloj en todo momento.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="included-item" alt="Fifth slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon">
                                <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Agenda y Llamadas</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                El reloj dispone de altavoz y micrófono para hablar con el centro de emergencias
                                o con los familiares.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="included-item" alt="Sixth slide">
                    <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                        <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="height: 260px; box-shadow: 0px 1px 8px 0px #00000040;">
                            <div class="card-icon">
                                <img src="../../../../assets/images/onboarding/avisos-icono.svg"
                                    alt="no disponible" />
                            </div>
                            <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                <strong>Comunicados y avisos</strong>
                            </div>
                            <div class="card-content d-flex flex-column align-items-center text-center">
                                Mediante vibración y pitidos para que nuestros mayores no se queden nunca, por
                                ejemplo, sin batería.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="getDeviceType == 'md' || getDeviceType == 'sm'">
            <ngb-carousel>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="First slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded"
                                style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon mb-2">
                                    <img src="../../../../assets/images/onboarding/SOS-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-text">
                                    <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                        <strong>Botón SOS</strong>
                                    </div>
                                    <div class="card-content d-flex flex-column align-items-center text-center">
                                        Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                        emergencia.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Second slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/caidas-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Detección de caidas</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                    emergencia.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Third slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5 md:min-h-[250px]">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Aviso a emergencias y familiares</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Si algo no va bien, con pulsar el botón, gestionaremos de inmediato la
                                    emergencia.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Fourth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/geolocalizacion-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Geolocalización</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    El reloj indica a los familiares través de la APP, dónde se encuentra el usuario
                                    del reloj en todo momento.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Fifth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/emergencia-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Agenda y Llamadas</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    El reloj dispone de altavoz y micrófono para hablar con el centro de emergencias
                                    o con los familiares.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="d-block w-100" alt="Sixth slide">
                        <div class="card-container d-flex flex-column align-items-center px-4 py-5">
                            <div class="card d-flex flex-column align-items-center px-3 py-4 border rounded" style="box-shadow: 0px 1px 8px 0px #00000040;">
                                <div class="card-icon">
                                    <img src="../../../../assets/images/onboarding/avisos-icono.svg"
                                        alt="no disponible" />
                                </div>
                                <div class="card-title d-flex flex-column align-items-center text-center mb-2">
                                    <strong>Comunicados y avisos</strong>
                                </div>
                                <div class="card-content d-flex flex-column align-items-center text-center">
                                    Mediante vibración y pitidos para que nuestros mayores no se queden nunca, por
                                    ejemplo, sin batería.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>

    <div style="background-color: #111183; padding-bottom: 16px;">
        <div class="container">
            <div class="header-title-box d-flex flex-column align-items-center pt-5 gap-32">
                <div *ngIf="getDeviceType == 'xxl' || getDeviceType == 'xl' || getDeviceType == 'l' || getDeviceType == 'md'">
                        <span class="header-title header-title-font">
                            {{ "Te ayudamos a configurar tu" | uppercase }}
                            <span class="header-title-font light-blue">{{ "App" | uppercase }}</span>
                            {{ "." | uppercase }}
                        </span>
                </div>
                <div *ngIf="getDeviceType == 'sm'" class="d-flex flex-column" style="justify-content: center; align-items: center; gap: 16px">
                    <span class="header-title header-title-font">
                        {{ "Te ayudamos a" | uppercase }}
                    </span>
                    <span class="header-title header-title-font">
                        {{ "configurar tu" | uppercase }}
                        <span class="header-title-font light-blue">{{ "App" | uppercase }}</span>
                        {{ "." | uppercase }}
                    </span>
                </div>
                <div style="padding-top: 1.5rem;">
                    <h1 class="header-title header-title-font">
                       {{selectedTitleApp | uppercase}}
                    </h1>
                </div>
            </div>
            <div style="width: 100%; padding-top: 1.5rem;">
                <ngb-carousel
                (slid)="changeTitleApp($event)"
                style="max-width: 728px"
                [interval]="0"
                [showNavigationArrows]="getDeviceType == 'xxl' || getDeviceType == 'xl' || getDeviceType == 'l'" style="padding-bottom: 2.5rem;">
                    <ng-template ngbSlide *ngFor="let video of videosApp">
                        <script src="https://player.vimeo.com/api/player.js"></script>
                      <section style="position: relative;">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe [src]="video.src | safeUrl" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="3 Guia Uso"></iframe>
                        </div>
                      </section>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>

    <div style="background-color:  #1EA8C7; padding-bottom: 16px;">
        <div class="container">
            <div class="header-title-box d-flex flex-column align-items-center pt-5 gap-32">
                <div *ngIf="getDeviceType == 'l' || getDeviceType == 'xl' || getDeviceType == 'xxl'">
                    <span class="header-title header-title-font">
                        {{ "Y también te ayudamos a configurar tu" | uppercase }}
                        <span class="header-title-font light-blue">{{ "Reloj" | uppercase }}</span>
                        {{ "." | uppercase }}
                    </span>
                </div>

                <div *ngIf="getDeviceType == 'md' || getDeviceType == 'sm'" class="d-flex flex-column" style="justify-content: center; align-items: center; gap: 16px">
                    <span class="header-title header-title-font">
                        {{"y también te ayudamos" | uppercase}}
                    </span>
                    <span class="header-title header-title-font">
                        {{"a configurar tu" | uppercase}}
                        <span class="header-title-font light-blue" style="margin-left: 0;;">{{"reloj" | uppercase}}</span>
                        {{"." | uppercase}}
                    </span>
                </div>
                <div style="padding-top: 1.5rem;">
                    <h1 class="header-title header-title-font">
                        {{selectedTitle | uppercase}}
                    </h1>
                </div>
            </div>
            <div style="width: 100%; padding-top: 1.5rem;">
                <ngb-carousel style="max-width: 728px"
                (slid)="changeTitle($event)"
                [interval]="0"
                [showNavigationArrows]="getDeviceType == 'xxl' || getDeviceType == 'xl' || getDeviceType == 'l'"
                style="padding-bottom: 2.5rem;">
                    <ng-template ngbSlide *ngFor="let video of videos">
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <div class="embed-responsive embed-responsive-16by9">    
                            <iframe [src]="video.src | safeUrl" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="3 Guia Uso"></iframe>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>

    <div class="questions container">
        <div class="header-title-box d-flex flex-column items-center pt-5 gap-18">
            <div>
                <h1 style="font-size: 26px;">
                    {{ "¿tienes preguntas? claro, dudar es de sabios" | uppercase }}
                </h1>
                <p>Descarga el manual de usuario o consulta nuestras preguntas más frecuentes.</p>
            </div>
            <div class="d-flex buttons-group justify-center items-center gap-18">
                <div class="button-container">
                    <div class="button-icon">
                        <img src="../../../../assets/images/qr/document.svg" width="88" height="auto" />
                    </div>
                    <div class="button-text">Manual de usuario</div>
                    <a target="_blank" href="/assets/documents/docs-watch/Manual de Usuario Avanzado Octubre 2023.pdf" class="button-download">DESCARGAR</a>
                </div>

                <div class="button-container">
                    <div class="button-icon">
                        <img src="../../../../assets/images/qr/faq.svg" width="88" height="auto" />
                    </div>
                    <div class="button-text">Preguntas frequentes</div>
                    <a target="_blank" href="/assets/documents/docs-watch/FAQs Reloj DoctorGO 021023 .pdf" class="button-download">CONSULTAR</a>
                </div>
            </div>
            <p style="width: 80%; text-align: center;">Para cualquier duda o información adicional puedes contactarnos en el <a href="tel:911442244" class="bold light-blue">911 442 244</a> (de Lunes a Viernes de 10h a 18h)</p>
        </div>
    </div>

    <div class="footer">
        <div class="footer-container">
            <div class="app-info">
                <label class="footer-download-text">Descarga gratis la app del reloj teleasistencia 24h</label>
                <div class="download-btn-group d-flex gap-8">
                    <a href="https://play.google.com/store/apps/details?id=com.yoigo.doctorgo.teleasistencia" class="android-btn">
                        <img src="../../../assets/images/android-download.png" width="160" />
                    </a>
                    <a href="https://apps.apple.com/pl/app/reloj-teleasistencia-24h/id6449715665" class="ios-btn">
                        <img src="../../../assets/images/ios-download.png" width="160" />
                    </a>
                </div>
            </div>
            <hr />
            <div class="other-info">
                <div>
                    <a href="https://doctorgo.es/">
                        <img src="../../assets/images/form-watch/Logo Yoigo SVAlogo-blanco.png" />
                    </a>
                </div>
                <div class="left-footer d-flex">
                    <div class="cookies-informacion-legal d-flex">
                      <a target="_blank" href="/assets/documents/docs-watch/231107_condiciones-generales-mm -para SENIOR.pdf">Información legal</a>
                      <div *ngIf="this.width > 328"  class="vertical-sep"></div>
                      <a target="_blank" href="/assets/documents/docs-watch/Textos_Proteccion_Datos_Reloj_Teleasistencia_24H_20231018.pdf">Protección de datos</a>
                      <div *ngIf="this.width > 328"  class="vertical-sep"></div>
                      <a target="_blank" href="https://doctorgo.es/cookies">Cookies</a>
                    </div>
                    <div *ngIf="getDeviceType !== 'sm'" class="vertical-sep"></div>
                    <label class="footer-text">© Grupo MASMOVIL 2023</label>
                </div>

            </div>
        </div>
    </div>
</div>
