<div class="modal-box modal_open1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header flex-row align-self-end">
        <img
          src="../../../../assets/images/shared/close.svg"
          (click)="close.emit()"
        />
      </div>

      <div class="modal-header flex-column align-items-center">
        <h6 class="fs-24 text-uppercase color-title" *ngIf="!forVitalDent">
          {{
            eusk
              ? "Bi hilabetez doan Yoigoren bezeroa bazara / Hilabetez doan bezeroa ez bazara"
              : "Prueba gratis la app de telemedicina 24h"
          }}
        </h6>
        <h6 class="fs-22 text-uppercase color-title" *ngIf="forVitalDent">
          Prueba 2 meses gratis la app de telemedicina 24h
        </h6>
        <span class="fs-14 color-title" *ngIf="!forVitalDent">
          {{
            eusk
              ? "Bi hilabetez doan Yoigoren bezeroa bazara / Hilabetez doan bezeroa ez bazara"
              : "(2 meses gratis para clientes de yoigo / 1 mes gratis si no eres cliente)"
          }}
        </span>
      </div>

      <div class="modal-body">
        <p class="fs-16">
          {{eusk ? 'Eman iezaguzu zure telefonoa, eta DoctorGO aplikazioa deskargatzeko esteka bidaliko dizugu.' : 'Déjanos tu número de teléfono y te enviaremos el enlace de descarga de la app de DoctorGO.'}}
        </p>

        <form [formGroup]="callMeForm" class="d-flex flex-column flex-lg-row">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{
              eusk ? "Mugikor-zenbakia" : "Número de móvil"
            }}</mat-label>
            <input
              matInput
              name="phone"
              formControlName="phone"
              [placeholder]="eusk ? 'Mugikorra' : 'Teléfono'"
            />
          </mat-form-field>
        </form>
      </div>
      <div class="modal-footer justify-content-end">
        <button
          data-ga="modal_close"
          type="button"
          class="btn bg-stores text-white rounded-lg"
          (click)="send()"
          [disabled]="callMeForm.invalid"
        >
          {{eusk ? "Bidali" : "Enviar"}}
        </button>
        <p class="fs-12 text-test-it">

          {{eusk ?
          'Zure mugikor-zenbakia aplikazioa deskargatzeko esteka duen SMSa bidaltzeko bakarrik erabiliko du DoctorGOk. Informazio gehiago nahi baduzu, ikusi gure'
          : ' En DoctorGO, usaremos tu número de móvil únicamente para enviarte un SMS con el enlace a la descarga. Para más información consulta nuestra'}}


          <a
            href="../../../../assets/documents/Politica_de_privacidad_y_condiciones_de_uso.pdf"
            >{{eusk ? 'pribatutasun-politika' : 'política de privacidad'}}</a
          >
          .
        </p>
      </div>

      <footer class="d-flex flex-column gap-16 mt-4">
        <div class="d-flex gap-16 justify-content-center">
          <p>
            {{eusk ? 'Edo deskargatu zuzenean': 'O descárgatela directamente desde tu'}}
            <a
              href="https://apps.apple.com/es/app/doctorgo/id1527729464"
              target="_blank"
              rel="nofollow"
              class="link text-stores-link"
              >{{eusk? 'App Storetik':'app store'}}</a
            >
            o
            <a
              href="https://play.google.com/store/apps/details?id=com.yoigo.doctorgo"
              target="_blank"
              rel="nofollow"
              class="link text-stores-link"
              >{{eusk? 'Play Storetik':'play store'}}</a
            >.
          </p>
        </div>

        <div class="d-flex gap-16 justify-content-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.yoigo.doctorgo"
            target="_blank"
            rel="nofollow"
            class="link text-stores-link"
          >
            <img
              src="../../../../assets/images/home-v3/play.svg"
              alt="Descarga Android"
            />
          </a>

          <a
            href="https://apps.apple.com/es/app/doctorgo/id1527729464"
            target="_blank"
            rel="nofollow"
            class="link text-stores-link"
          >
            <img
              src="../../../../assets/images/home-v3/applestore.svg"
              alt="Descarga IOs"
            />
          </a>
        </div>
      </footer>
    </div>
  </div>
</div>
