import {
  CustomerBody,
  VerificationBody,
} from 'src/server/interfaces/meetingInterfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeetingApiService {
  private jsonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  constructor(private http: HttpClient) {}

  validateMasStack(phoneNumber: string): Observable<{isYoigo: boolean, stack: string}> {
    return this.http.post<{isYoigo: boolean, stack: string}>('api/onboarding/verification-mas-stack', {phone: phoneNumber}, {
      headers: this.jsonHeaders,
    });
  }

  validate(userData: VerificationBody) {
    return this.http.post('api/onboarding/verification', userData, {
      headers: this.jsonHeaders,
    });
  }

  sendVerificationCode(data: { document: string; phone: string }) {
    return this.http.post('api/onboarding/sendverificationcode', data, {
      headers: this.jsonHeaders,
    });
  }

  checkVerificationCode(data: { code: string; phone: string }) {
    return this.http.post('api/onboarding/checkverificationcode', data, {
      headers: this.jsonHeaders,
    });
  }

  userData(data: { userHash: string }) {
    return this.http.get('api/onboarding/userdata', {
      headers: this.jsonHeaders,
      params: data,
    });
  }

  unsubscribe(code: string) {
    const data = { code };
    return this.http.post('api/onboarding/unsubscribe', data, {
      headers: this.jsonHeaders,
    });
  }

  verifyYoigo(code: string): Observable<{isYoigo: boolean, document: string}> {
    return this.http.post<{isYoigo: boolean, document: string}>('api/onboarding/verify-yoigo', {code}, {
      headers: this.jsonHeaders,
    });
  }

  customers(data: CustomerBody) {
    return this.http.post('api/onboarding/customers', data, {
      headers: {
        ...this.jsonHeaders,
        'sub-auth': 'Bearer ' + localStorage.getItem('next_r_token')
      },
    });
  }

  processPaymentMethod(paymentMethodId: string, document: string, code: string) {
    return this.http.post(
      'api/onboarding/process-payment',
      { paymentMethodId, document, code },
      {
        headers: {
          ...this.jsonHeaders,
          'sub-auth': 'Bearer ' + localStorage.getItem('next_r_token')
        }
      }
    );
  }

  verificateCode(code: string) {
    return this.http.get('api/onboarding/verificate-code', {
      headers: this.jsonHeaders,
      params: { code },
    });
  }

  verifySubscription(subId: string): Observable<{status: string}> {
    return this.http.post<{status: string}>('api/onboarding/verify-subscription', { subId }, {
      headers: {
        ...this.jsonHeaders,
        'sub-auth': 'Bearer ' + localStorage.getItem('next_r_token')
      },
    });
  }

}
