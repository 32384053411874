<section class="gap-16 d-flex justify-content-center">
  <app-onboarding-blue-box title="¡Bienvenido a DoctorGO!">
    Tu Medico, Nutricionista y Entrenador Personal, siempre contigo
  </app-onboarding-blue-box>
</section>

<section class="gap-16 w-100 d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-1">
        <img src="/assets/images/onboarding/check-blue.svg" />
      </div>
      <div class="col-10">
        <p>Empieza hoy <b>GRATIS</b></p>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div class="line"></div>
      <div class="line-text">
        Todos los servicios durante 2 meses si eres de Yoigo o de 1 mes si no lo
        eres.
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <img src="/assets/images/onboarding/check-blue.svg" />
      </div>
      <div class="col-10">
        <p><b>Si decides quedarte</b></p>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div class="line"></div>
      <div class="line-text">
        La suscripción incluye todos los servicios para 2 adultos + niños, por
        tan sólo 8€/mes si eres de Yoigo u 10€/mes si no lo eres.
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <img src="/assets/images/onboarding/check-blue.svg" />
      </div>
      <div class="col-10">
        <p><b>Y sin compromisos</b></p>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div class="line"></div>
      <div class="line-text last">
        Cancela tu suscripción cuando quieras, sin ningún tipo de penalización.
      </div>
    </div>
  </div>
</section>

<app-sign-up-footer
  buttonText="Continuar"
  (buttonClick)="
    continueDebouncer.next({
      isClient: this.onboardingStateService.isYoigo.value,
      buttonText: 'Comenzar mi prueba gratuita'
    })
  "
  [buttonValid]="true"
></app-sign-up-footer>
