import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommunicationWebviewNative } from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';

@Component({
  selector: 'app-alert-screen',
  templateUrl: './alert-screen.component.html',
  styleUrls: ['./alert-screen.component.scss'],
})
export class AlertScreenComponent implements OnInit {
  @Input() imgsrc: string = '/assets/images/onboarding/check-blue.svg';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonText: string;

  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    private onboardingStateService: OnboardingStateService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueClick();
    });
  }

  ngOnInit(): void {
    /* this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'CUENTA CREADA',
          screen_class: 'CUENTA CREADA',
        },
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding Finished', {
      data: {
        finished_reason: 'SIGNUP',
      },
    }); */
  }

  continueClick() {
    this.continue.emit(true);
  }
}
