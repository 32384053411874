<section
  class="gap-32 d-flex flex-column justify-content-center align-items-center"
>
  <section>
    <img src="/assets/images/required-password.svg" alt="Password required" />
  </section>

  <section class="gap-32 text-center d-flex flex-column">
    <h1 class="fs-20">Confirma que eres tú</h1>

    <section class="gap-8 text-center d-flex flex-column">
      <span class="fs-14">
        Introduce el código de confirmación que te hemos enviado por SMS.
        <!-- para
        que verifiquemos tus datos. -->
      </span>

      <strong class="fs-14">
        Importante: El SMS puede tardar algunos segundos. En breve recibirás el
        SMS.
      </strong>
    </section>

    <form [formGroup]="codeForm">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>Código de confirmación</mat-label>
        <input noSpaces [focusAfter]="750" matInput formControlName="code" />
      </mat-form-field>
      <app-onboarding-form-error *ngIf="error">{{
        error
      }}</app-onboarding-form-error>
    </form>
  </section>
</section>

<app-sign-up-footer
  class="h-100"
  [buttonText]="loading ? '<img class=spinner-onboarding width=26 src=/assets/images/Spinner.svg alt=Cargando />' : 'CONTINUAR'"
  [buttonValid]="codeForm.valid && !loading"
  (buttonClick)="continueDebouncer.next()"
  (linkClick)="sendVerificationCode(true)"
  linkText="No he recibido el SMS"
></app-sign-up-footer>
