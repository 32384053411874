<div class="fixed-top">
  <div
    *ngIf="!isHiring"
    id="callMeSection"
    class="callMe-section d-flex align-items-center"
  >
    <div
      class="gap-16 container d-flex callMe d-flex justify-content-end align-items-center help__section"
    >
      <div class="d-flex gap-24">
        <span>
          <a href="/ayuda">Ayuda</a>
        </span>
        <span>|</span>
        <span>
          <a href="/contacto">Contacto</a>
        </span>
      </div>
    </div>
  </div>

  <nav
    class="navbar navbar-expand-lg navbar-light d-flex justify-content-between flex-nowrap align-content-center container"
  >
    <div class="">
      <button
        #navbarToggler
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="openMenu()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div
      *ngIf="!isHiring"
      class="collapse navbar-collapse"
      id="navbarTogglerDemo01"
      style="max-width: 1240px"
    >
      <div class="collapse-container">
        <ul class="navbar-nav mt-2 mt-lg-0">
          <div
            class="logo-and-close mb-5 d-flex flex-row justify-content-between align-items-start"
          >
            <a href="/">
              <img
                class="logo-doctor-go"
                src="../../../../assets/images/doctor-go-logo.svg"
                alt="no disponible"
              />
            </a>
            <button
              type="button"
              class="icon-icn-close"
              (click)="closeMenu()"
            ></button>
          </div>
          <li class="nav-item">
            <span class="nav-link d-flex gap-8" routerLinkActive="active-link"
              >Telemedicina
              <img
                class="nav-link"
                src="../../../../assets/images/menu-abajo.svg"
                alt="na"
              />
          </span>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li
                class="nav-item main dropdown"
                routerLinkActive="active-link-new"
              >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/alergologo"
                  routerLinkActive="active-link-new"
                  >Alergología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/cardiologo"
                  routerLinkActive="active-link-new"
                  >Cardiología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/dermatologo"
                  routerLinkActive="active-link-new"
                  >Dermatología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/ginecologo"
                  routerLinkActive="active-link-new"
                  >Ginecología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/pediatra"
                  routerLinkActive="active-link-new"
                  >Pediatría</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/psicologo"
                  routerLinkActive="active-link-new"
                  >Psicología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/sexologo"
                  routerLinkActive="active-link-new"
                  >Sexología</a
                >
                <a
                  class="p-2 w-100 dropdown-item"
                  href="/medico-general-online"
                  routerLinkActive="active-link-new"
                  >Médico General</a
                >
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <span class="nav-link d-flex gap-8" routerLinkActive="active-link"
              >Nutrición y Fitness
              <img
                class="nav-link"
                src="../../../../assets/images/menu-abajo.svg"
                alt="na"
              />
          </span>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li
                class="nav-item main dropdown"
                routerLinkActive="active-link-new"
              >
                <a
                  class="p-2 dropdown-item"
                  href="/nutricionista"
                  routerLinkActive="active-link-new"
                  >Nutricionista</a
                >

                <a
                class="p-2 dropdown-item"
                href="/entrenador-personal-online"
                routerLinkActive="active-link-new"
                >Entrenador personal</a
              >

              </li>
              <li
                class="nav-item"
                routerLinkActive="active-link-new"
                *ngIf="false"
              >
                <a class="nav-link" href="#">Test analítica completa</a>
              </li>
              <li
                *ngIf="false"
                class="nav-item"
                routerLinkActive="active-link-new"
              >
                <a class="nav-link" href="//programas-salud"
                  >Programas de salud</a
                >
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a
              class="nav-link d-flex gap-8"
              href="/telefarmacia-y-receta-electronica"
              routerLinkActive="active-link"
              >Telefarmacia y Receta Electrónica
            </a>
          </li>
        </ul>
        <div class="logo-navbar">
          <img
            src="../../../../assets/images/logonavbar.svg"
            alt="no disponible"
          />
        </div>
      </div>
    </div>

    <a
      href="/"
      class="link-logo"
      [ngClass]="isMobileViewComponent ? 'd-flex justify-content-center ' : ''"
    >
      <img
        class="logo-doctor-go"
        src="../../../../assets/images/doctor-go-logo.svg"
        [ngClass]="isMobileViewComponent ? 'mobile-view-css' : ''"
        alt="no disponible"
      />
    </a>

    <section
      class="llama__gratis flex-column"
      id="callMe1"
      [ngClass]="isHiring || isOnlineHealthLanding ? 'd-none' : 'd-flex'"
    ></section>

    <section *ngIf="isOnlineHealthLanding" class="test-app">
      <button
        data-ga="modal_open"
        class="test-app__button"
        (click)="openModalTryApp()"
      >
        <span class="test-app__button__text" data-ga="modal_open">
          {{ "PRUEBA GRATIS LA APP" | uppercase }}
        </span>
      </button>
    </section>

    <a
      *ngIf="!isHiring"
      href="https://www.yoigo.com/"
      target="_blank"
      rel="nofollow"
    >
      <img
        src="../../../../assets/images/yoigo_azul.svg"
        alt="no disponible"
        class="logo-yoigo d-none d-lg-block"
      />
    </a>
  </nav>

  <div *ngIf="displayFuet" class="fuet">
    <p>
      Usa el código <b>REQUETESANO</b> de Yoigo y aumenta a
      <b>6 los meses gratis</b>
    </p>
    <button class="button" (click)="copyToClipboard()">
      <span *ngIf="codeCopiedToClipboard" class="copyToClipboardSuccess">
        Código copiado!</span
      >
      COPIAR CÓDIGO
    </button>
  </div>
</div>

<div class="modal-call d-flex flex-column rounded-bottom" *ngIf="modalOpen">
  <div class="callWe position-relative">
    <button type="button" (click)="openModal()">
      <span class="icon-icn-close"></span>
    </button>
    <p>Llámanos GRATIS al</p>
    <h3><a href="tel:622" class="text-white">622</a></h3>
  </div>
  <div class="container-fluid">
    <div class="callMe-form">
      <app-callme-form></app-callme-form>
    </div>
    <p class="yoigo-text fs-12">
      En Yoigo vamos a tratar tus datos para informarte de nuestros servicios.
      Puedes ejercitar tus derechos privacidad-yoigo@yoigo.com Más info en
      <a
        href="../../../assets/documents/politica-privacidad-DoctorGo.pdf"
        rel="nofollow"
        target="_blank"
        >política de privacidad</a
      >
    </p>
    <div class="schedule fs-14 text-center">
      <p>De Lunes a Domingo: 9:00 - 23:00</p>
    </div>
  </div>
</div>

<app-test-it-modal
  *ngIf="showTestItModal"
  [numberOfMonths]="numberOfMonths"
  (close)="showTestItModal = false"
>
  <app-test-it-modal></app-test-it-modal>
</app-test-it-modal>
