import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss']
})
export class MedicalHistoryComponent implements OnInit {

  constructor(private utils: FunctionUtilsService, private router: Router) { }

  ngOnInit(): void {

    if (!this.utils.getIsMobile())
      this.router.navigateByUrl('/')
      
  }

}
