import { IsCustomerService } from './../../../services/isCustomer/is-customer.service';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { Router, NavigationEnd } from '@angular/router';
import { FunctionUtilsService } from '../../../services/function-utils.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() unsubscribeEventFooter = new EventEmitter<any>();
  @ViewChild('callMeFooter') private callMeFooter!: ElementRef;
  private readonly unsubscribe$: Subject<void> = new Subject();
  clickEvent: any;
  isIndexPage: Subscription;
  isIndexPageResult: boolean;

  showTestItModal = false;
  isCustomer: boolean = true;
  numberOfMonths: string;
  isHiring = false;

  isMobileViewComponent = false;
  isLandingPage = false;
  isHome = false;
  canRenderFooter = false

  blackListUrls = [
    '/',
    '/',
    '/seguros-medicos',
    '/sorteos',
    '/cookies',
    '/cuadro-medico-dkv'
  ]

  actualYear = new Date().getFullYear()

  promotions = [{
    offerType: 'regular',
    offeredProductName: 'DoctorGO',
    offeredService: `Prueba ${this.isCustomer ? '2 meses': '1 mes'} gratis la app de telemedicina DoctorGO, sin compromiso y cancela cuando quieras.`,
    ppalImageAltText: '',
    position: 'regular',
  }]

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private utils: FunctionUtilsService,
    public isCustomerService: IsCustomerService,
    @Inject(PLATFORM_ID) public platformId: string
  ) {}

  ngOnInit(): void {
    this.isMobileViewComponent = this.router.url === '/seguros-medicos/mobile';

    // this.analyticsService.pageLoaded({promotions: this.promotions})

    this.isLandingPage = this.router.url === '/seguros-medicos/clientes';
    this.isHome = this.router.url === '/'
    this.canRenderFooter = !this.blackListUrls.some(str => this.router.url.includes(str))

    this.unsubscribeEventFooter.emit('');

    this.isCustomerService.isCustomer$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer) => {
        this.isCustomer = customer;
      });
    // this.loadBysideFooter();
    this.isIndexPage = this.utils.subIsIndex.subscribe(
      (response: boolean) => (this.isIndexPageResult = response)
    );
  }

  ngAfterViewInit() {
    this.router.events.subscribe((val: any) => {
      this.isLandingPage = this.router.url === '/seguros-medicos/clientes';
      this.isHome = this.router.url === '/';

      this.canRenderFooter = !this.blackListUrls.some(str => this.router.url.includes(str))

      if (val instanceof NavigationEnd) {
        if (val.url.includes('/contratacion')) {
          this.isHiring = true;
        } else {
          this.isHiring = false;
        }
      }
    });
  }

  onClickEvent(e: any) {
    // console.log('Evento de click');
    this.numberOfMonths = this.isCustomer ? '2 meses' : '1 MES';
    this.showTestItModal = true;
    // this.utils.hireOnline(1059);
    if (isPlatformBrowser(this.platformId)) {
      // const clickEvent = {
      //   offerType: 'regularoffer',
      //   offeredProductName: 'DoctorGO',
      //   principalMessage: 'En esta consulta, no tendrás que esperar leyendo revistas',
      //   offeredService: 'DoctorGO',
      //   ppalImageAltText: `${ window.location.origin }/assets/images/home/banner-graphics.png`,
      //   position: 'Top',
      // }
      this.clickEvent = [
        {
          offerType: 'regular',
          offeredProductName: 'DoctorGO',
          offeredService: `Prueba ${this.numberOfMonths} gratis la app de telemedicina DoctorGO, sin compromiso y cancela cuando quieras.`,
          ppalImageAltText: '',
          position: 'regular',
        },
      ];
      if (this.analyticsService.getIsFirstTimeIWant()) {
        this.analyticsService.sendPromotionClick(this.clickEvent[0]);
      }
    }
  }

  loadBysideFooter() {
    if (isPlatformBrowser(this.platformId)) {
      let step1 = new Promise(function (resolve, reject) {
        let interval = setInterval(function () {
          if (window['bysideWebcare_placeholder_loadnow']) {
            clearInterval(interval);
            resolve(true);
          }
        }, 1000);
      });

      // step1.then(() => {
      //   window['bysideWebcare_placeholder_loadnow'](
      //     '2017_yoigo_c2c_footer_julho',
      //     undefined,
      //     'callMeFooter1'
      //   );
      // });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
