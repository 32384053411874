import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { MeetingApiService } from '../../../services/meeting-api/meeting-api.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { Subject } from 'rxjs';
import { UserType } from '../../../types/onboardingTypes';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-invitation',
  templateUrl: './onboarding-invitation.component.html',
  styleUrls: ['./onboarding-invitation.component.scss'],
})
export class OnboardingInvitationComponent implements OnInit {
  @ViewChildren('inputs') vc: QueryList<ElementRef>;

  @Output() continue = new EventEmitter<boolean>();
  continueDebouncer: Subject<any> = new Subject<any>();

  completed = false;

  codeValue = '';
  error: string;
  loading = false;

  constructor(
    private meetingApiService: MeetingApiService,
    private onboardingStateService: OnboardingStateService,
    private segmentService: SegmentService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.onContinueButtonClick();
    });
  }

  ngOnInit() {
    this.segmentService.sendSegmentEvent('Invitation code Started', null);
    this.onboardingStateService.hideBackButton = false;
  }

  onContinueButtonClick() {
    this.error = '';
    this.loading = true;

    // replace codeValue blank spaces

    this.onboardingStateService.verificationForm.controls.invitation.setValue(
      this.codeValue
    );

    this.segmentService.sendSegmentEvent('Invitation code Submitted', {
      data: {
        code: this.onboardingStateService.verificationForm.controls.invitation
          .value,
      },
    });

    this.meetingApiService
      .verificateCode(
        this.onboardingStateService.verificationForm.controls.invitation.value
      )
      .subscribe(
        (response: any) => {
          this.continue.emit(true);

          if (response.metadata) {
            this.onboardingStateService.metadataMonthPrice = {
              ...response.metadata,
            };
          }
          if (response.codeType.toUpperCase() === 'INVITATION') {
            this.segmentService.sendSegmentIdentifyEvent(this.onboardingStateService.dniForm.value.document || null, {
              userType: 'secondary',
              invitedByCode:
                this.onboardingStateService.verificationForm.controls.invitation
                  .value,
            });
            this.onboardingStateService.userType = UserType.invitation;
          } else {
            this.onboardingStateService.promotionForm.controls.code.setValue(
              this.onboardingStateService.verificationForm.controls.invitation
                .value
            );
            this.onboardingStateService.verificationForm.controls.invitation.setValue(
              ''
            );

            this.onboardingStateService.userType = response.userType;

          }
        },
        (error) => {

          this.onboardingStateService.verificationForm.controls.invitation.setValue("");

          this.error =
            'Hubo un error al validar el código de invitación. Vuelve a intentarlo';
          this.segmentService.sendSegmentEvent('Invitation code Error', {
            data: error,
          });

          this.loading = false;
          // this.continue.emit(false);
        }
      );
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
