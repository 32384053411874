import { BehaviorSubject, Observable } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { PriceList } from './interfaces/is-custormer.interfaces';
import { PriceSelector } from './enum/is-customer.enum';

@Injectable({
  providedIn: 'root'
})

export class IsCustomerService {

  private isCustomerBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isCustomer$: Observable<boolean> = this.isCustomerBehaviorSubject.asObservable();

  priceList: PriceList = {
    [PriceSelector.DOCTOR_INSURANCE]: { integer: '21', decimal: '90' },
    [PriceSelector.DOCTOR_APP]: { integer: '10', decimal: '00' },
    [PriceSelector.DOCTOR_INSURANCE_NO_CUSTOMER]: { integer: '26', decimal: '90' },
    [PriceSelector.DOCTOR_APP_NO_CUSTOMER]: { integer: '10', decimal: '00' },
    [PriceSelector.DKV_COMPLET_OLD]: { integer: '21', decimal: '00' },
    [PriceSelector.DKV_COMPLET_NEW]: { integer: '14', decimal: '00' },
    [PriceSelector.DKV_CLASIC_OLD]: { integer: '42', decimal: '00' },
    [PriceSelector.DKV_CLASIC_NEW]: { integer: '27', decimal: '00' },
    [PriceSelector.DKV_ELITE_OLD]: { integer: '46', decimal: '00' },
    [PriceSelector.DKV_ELITE_NEW]: { integer: '30', decimal: '00' },
  };

  isCustomer: boolean = true;

  constructor(
    private routeParams: ActivatedRoute,
  ) {
    this.init();
  }

  init(): void {
    this.routeParams.queryParams.subscribe(
      params => {
        this.isCustomer = !(params && params.cliente === 'false');
        this.isCustomerBehaviorSubject.next(this.isCustomer);
      }
    );
  }

  getPrice(selector: PriceSelector) {
    return this.priceList[selector]
  }

}
