import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[nextFocusId]',
})
export class NextFocusIdDirective implements OnInit {
  @Input() nextFocusId: string;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  @HostListener('keyup', ['$event'])
  customKeyUp($event) {
    if ($event.keyCode === 13) {
      document.getElementById(this.nextFocusId).focus();
      this.elementRef.nativeElement.blur();
    }
  }
}
