import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(private utils: FunctionUtilsService, private router: Router) { }

  ngOnInit(): void {

    if (!this.utils.getIsMobile())
      this.router.navigateByUrl('/')
      
  }

}
