import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  OnboardingTokens,
  RootUserData,
} from 'src/server/interfaces/meetingInterfaces';

import { MeetingApiService } from '../../../services/meeting-api/meeting-api.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-yoigo-sms',
  templateUrl: './yoigo-sms.component.html',
  styleUrls: ['./yoigo-sms.component.scss'],
})
export class YoigoSmsComponent implements OnInit {
  @Output() continue: EventEmitter<'continue' | 'userFound'> =
    new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  codeForm = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });

  error = '';
  retries = 0;
  loading = false;

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    private meetingApiService: MeetingApiService,
    private onboardingStateService: OnboardingStateService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.validateCode();
    });
  }

  ngOnInit(): void {
    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'DATOS ACCESO VERIFICACION',
          screen_class: 'DATOS ACCESO VERIFICACION',
        },
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding OTP Started', null);

  }

  sendVerificationCode(retry = false) {



    if (retry) {
      this.retries++;

      this.segmentService.sendSegmentEvent('Onboarding OTP Retries', {
        data: { retries: this.retries },
      });
    }

    this.error = '';

    const data = {
      document: this.onboardingStateService.form.value.document.toUpperCase(),
      phone: this.onboardingStateService.form.value.phone,
    };

    this.segmentService.sendSegmentEvent('Onboarding OTP Send Code Submitted', {
      data,
    });

    this.meetingApiService.sendVerificationCode(data).subscribe(
      (response) => {
       // console.log(response);
        // todo : send success to segment
        this.segmentService.sendSegmentEvent('Onboarding OTP Success', {data: response})
      },
      (err) => {
        this.error =
          'Hubo un error al enviar el código de verificación. Vuelve a intentarlo más tarde.';
        // todo: Send error to segment

        this.segmentService.sendSegmentEvent('Onboarding OTP Send Code Error', {
          data: {error: err, data},
        });

      }
    );
  }

  validateCode() {
    // TODO: Emitir evento para cerrar todo el webview mandando el JWT para que mantengan la sesión

    this.error = '';
    const phone = this.onboardingStateService.form.value.phone;
    this.loading = true;

    this.segmentService.sendSegmentEvent('Onboarding OTP Submitted', {
      data: { code: this.codeForm.value.code, phone },
    });

    this.meetingApiService
      .checkVerificationCode({ code: this.codeForm.value.code, phone })
      .subscribe(
        (response: OnboardingTokens) => {

              if (!response.t) {
                this.segmentService.sendSegmentEvent(
                  'Cuenta Verificada - LOGIN',
                  null
                );

                this.segmentService.sendSegmentEvent('Onboarding Finished', {
                  data: {
                    finished_reason: 'LOGIN',
                  },
                });

                this.segmentService.sendSegmentIdentifyEvent(response.user.user_hash, {});

                this.webviewEvents.emitEvent({
                  action: Actions.CLOSE,
                  payload: {
                    session_token: response.user.session_token,
                    user_hash: response.user.user_hash,
                    user_token: response.user.user_token,
                    // action: 'doctorgo://www.doctorgo.es/app/chat?professionals=1&pretyped=test%20test%20test%20test%20test%20test%20test%20test%20'
                  },
                });

                return;
              } else {

                this.onboardingStateService.onboardingTokens = {
                  ...response,
                  user: response.user,
                };

                this.onboardingStateService.userResponse = { ...response.pld };

                localStorage.setItem('next_r_token', response.t);

                this.onboardingStateService.personalDataForm.controls[
                  'name'
                ].setValue(response.pld?.userData.firstName);
                this.onboardingStateService.personalDataForm.controls[
                  'surname1'
                ].setValue(response.pld?.userData.lastName);
                this.onboardingStateService.personalDataForm.controls[
                  'surname2'
                ].setValue(response.pld?.userData.lastName);
                this.onboardingStateService.personalDataForm.controls[
                  'birthDate'
                ].setValue(response.pld?.userData.birthDate);
                this.onboardingStateService.personalDataForm.controls[
                  'sex'
                ].setValue(response.pld?.userData.gender);

                this.onboardingStateService.dniForm.controls['nieDni'].setValue(
                  response.pld?.userData.dni ? 'dni' : 'nie'
                );
                this.onboardingStateService.dniForm.controls[
                  'document'
                ].setValue(response.pld?.userData.dni);

                this.onboardingStateService.contactForm.controls[
                  'phone'
                ].setValue(response.pld?.userData.mobilePhone);
                this.onboardingStateService.contactForm.controls[
                  'email'
                ].setValue(response.pld?.userData.email);
                this.continue.emit('continue');
              }

            }, error => {
              this.error =
                'Hubo un error en la validación del código de verificación. Vuelva a solicitar el código.';
              this.segmentService.sendSegmentEvent('Onboarding OTP Error', {
                data: {
                  error: error.error.error,
                  code: this.codeForm.value.code,
                  phone,
                },
              });

              this.loading = false;

            })


          // this.continue.emit();
  }

  isReady(userResponse: RootUserData) {
    const hasNameAndLastNameAndBirthDate =
      userResponse.userData.firstName !== null &&
      userResponse.userData.lastName !== null &&
      userResponse.userData.birthDate !== null;

    if (!hasNameAndLastNameAndBirthDate) {
      return false;
    }

    if (userResponse.userData.status === 0)
      return false;

    if (!this.onboardingStateService.isYoigo.value) {
      const hasStripe =
        userResponse.userData.additional_data &&
        userResponse.userData.additional_data.stripe

      if (!hasStripe) {
        return false;
      }
    }

    return true;
  }
}
