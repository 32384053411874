import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  SegmentEvent,
  SegmentService,
} from 'src/app/services/segment/segment.service';
import { animate, style, transition, trigger } from '@angular/animations';

import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { OnboardingStateService } from './services/onboarding-state/onboarding-state.service';
import { isPlatformBrowser } from '@angular/common';

type Steps =
  | 'stepPresentation'
  | 'stepFirst'
  | 'stepAuth'
  | 'stepInvitation'
  | 'stepInvitationConfirmed'
  | 'stepMFA'
  | 'stepRegister'
  | 'stepConfirm'
  | 'stepWellcome'
  | 'stepPayment';
@Component({
  selector: 'app-sign-up-meeting',
  templateUrl: './sign-up-meeting.component.html',
  styleUrls: ['./sign-up-meeting.component.scss'],
  animations: [
    trigger('onOff', [
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          350,
          style({
            opacity: 0,
            transform: 'translateX(-100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class SignUpMeetingComponent implements OnInit {
  steps: Record<Steps, { event: SegmentEvent; active: boolean }> = {
    stepPresentation: { event: 'Onboarding Started', active: false },
    stepFirst: { event: 'Onboarding Started', active: false },
    stepAuth: { event: 'Onboarding Started', active: false },
    stepInvitation: { event: 'Onboarding Started', active: false },
    stepInvitationConfirmed: { event: 'Onboarding Started', active: false },
    stepMFA: { event: 'Onboarding Started', active: false },
    stepRegister: { event: 'Onboarding Started', active: false },
    stepPayment: { event: 'Onboarding Started', active: false },
    stepConfirm: { event: 'Onboarding Started', active: false },
    stepWellcome: { event: 'Onboarding Started', active: false },
  };
  logo = 'doctorgo'; // Para que al recargar no muestre 1 seg la imagen de yoigo

  _stepsHistory: {
    steps: Record<Steps, { event: SegmentEvent; active: boolean }>;
    step: Steps;
    logo: 'yoigo' | 'doctorgo';
  }[] = [];

  isBack = false;

  constructor(
    private segmentService: SegmentService,
    private metaTagService: Meta,
    public onboardingStateService: OnboardingStateService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    // this.onboardingStateService.verificationForm.controls.invitation.setValue(true); // Prueba de que se oculta si tenemos código de invitación
  }

  ngOnInit(): void {


    if (isPlatformBrowser(this.platformId)) {
      this.activatedRoute.params.subscribe((params) => {

        if (params.token) {
          localStorage.setItem('onboarding-token', params.token);

        } else {
          localStorage.removeItem('onboarding-token');
        }

       this.onEnter(this.steps, 'stepAuth');

      })

      this.metaTagService.updateTag({
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0',
      });
    }


    // this.onEnter(this.steps, 'stepAuth');
  }

  onEnter(
    steps: Record<Steps, { event: SegmentEvent; active: boolean }>,
    step: Steps,
    logo: 'yoigo' | 'doctorgo' = 'doctorgo',
    isBack: boolean = false
  ) {
    this.isBack = isBack;

    this.onLeave(steps);

    setTimeout(() => {
      steps[step].active = true;
      this.logo = logo;

      this._stepsHistory.push({ steps, step, logo });
    }, 400);
  }

  onPresentationStep() {
    this.onEnter(this.steps, 'stepFirst');
  }

  onFirstStep(place: 'continue' | 'invitation') {
    if (place === 'continue') {
      this.onEnter(this.steps, 'stepAuth');
    }
    if (place === 'invitation') {
      this.onEnter(this.steps, 'stepInvitation');
    }
  }

  onInvitation(event: boolean) {
    this.onEnter(this.steps, 'stepInvitationConfirmed', 'doctorgo');
    // this.goBack();
  }
  onInvitationConfirmed(goBackEvent: boolean) {
    if (goBackEvent) {
      this.goDoubleBack();
    } else {
      this.onEnter(this.steps, 'stepAuth', 'doctorgo');
    }
  }

  onLogin(event: { form: FormGroup; isClient: boolean }) {
    this.onEnter(this.steps, 'stepMFA', 'doctorgo');
  }

  onNoClient() {
    this.onEnter(this.steps, 'stepRegister', 'doctorgo');
  }

  onLeave(steps: { [key: string]: { event: SegmentEvent; active: boolean } }) {
    for (const key of Object.keys(steps)) {
      steps[key].active = false;
    }
  }

  onSMSChecked(where: 'continue' | 'userFound') {
    if (where === 'userFound') {
      // TODO: Close webview
    } else if (where == 'continue') {
      this.onEnter(this.steps, 'stepRegister', 'doctorgo');
    }
  }

  onRegistered(needPay: boolean | 'code') {
    if (needPay === 'code') {
      this.onEnter(this.steps, 'stepInvitation', 'doctorgo');
    } else if (needPay)
      this.onEnter(this.steps, 'stepPayment', 'doctorgo');
    else {

      if (this.onboardingStateService.promotionForm.controls.code.value && this.onboardingStateService.userType === 3) {
        this.onEnter(this.steps, 'stepWellcome', 'doctorgo');
      } else {
        this.onEnter(this.steps, 'stepConfirm', 'doctorgo');
      }

    }

  }

  onDoPayment() {
    this.onEnter(this.steps, 'stepConfirm', 'doctorgo');
  }

  onConfirmed() {
    this.onEnter(this.steps, 'stepWellcome', 'doctorgo');
  }

  returnLogo() {
    if (this.logo && this.logo === 'yoigo')
      return '/assets/images/yoigo_azul.svg';
    if (this.logo && this.logo === 'doctorgo')
      return '/assets/images/doctor-go-logo.svg';
    return '';
  }

  goBack() {
    this.goBackLogic(1);
  }

  goDoubleBack() {
    this.goBackLogic(2);
  }

  goBackLogic(steps: number) {
    const keys = Object.keys(this.steps);
    const currentStep = keys.find((k) => this.steps[k].active === true);

    const index = this._stepsHistory.findIndex((s) => s.step === currentStep);
    const lastStep = this._stepsHistory[index - steps];

    this.segmentService.sendSegmentEvent('Onboarding Back', {
      data: {
        from: currentStep,
        to: lastStep.step,
      },
    });

    this._stepsHistory[index] = null;
    this._stepsHistory = this._stepsHistory.filter((el) => el !== null);

    this.onEnter(lastStep.steps, lastStep.step, lastStep.logo, true);
  }
}
