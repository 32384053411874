import { Ecommerce } from "./ecommerce";
import { General } from "./general";
import { GeneralEventModel } from "./generaleventmodel";

export class EventDetailLead {
  content_type: string;
  position: string

  constructor(partial: Partial<EventDetailLead> = null){
    Object.assign(this, partial);
  }
}
