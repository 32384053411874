import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';
import { UserType } from '../../../types/onboardingTypes';

@Component({
  selector: 'app-onboarding-confirm',
  templateUrl: './onboarding-confirm.component.html',
  styleUrls: ['./onboarding-confirm.component.scss'],
})
export class OnboardingConfirmComponent implements OnInit {
  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  error = '';

  std = {
    title: 'Ya estamos listos',
    subtitle:
      'Revisa las condiciones de DoctorGO y pulsa en confirmar para crear la cuenta.',
  };

  title: Record<UserType, { title: string; subtitle: string }> = {
    [UserType.standard]: this.std,
    [UserType.promo]: this.std,
    [UserType.vitaldent]: this.std,
    [UserType.invitation]: {
      title: 'Qué bien que hayas sido invitado!',
      subtitle:
        'Disfruta de tu médico, GRATIS para siempre mientras la cuenta principal siga activa.',
    },
    [UserType.employee]: {
      title: 'Qué bien que hayas sido invitado!',
      subtitle:
        'Disfruta de tu médico, GRATIS para siempre mientras seas empleado.',
    },
  };

  constructor(
    private webviewEvents: CommunicationWebviewNative,
    private segmentService: SegmentService,
    public onboardingStateService: OnboardingStateService
  ) {
    this.continueDebouncer.pipe(debounceTime(250)).subscribe(() => {
      this.continueClick();
    });
  }

  ngOnInit(): void {
    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'PLAN DE SUSCRIPCION',
          screen_class: 'PLAN DE SUSCRIPCION',
        },
      },
    });

    // TODO: send form and validate
    this.segmentService.sendSegmentEvent(
      'Onboarding Choose Plan Started',
      null
    );
  }

  continueClick() {
    this.continue.emit(true);

    this.webviewEvents.emitEvent({
      action: Actions.CLICK,
      payload: 'CONFIRMAR',
    });

    this.segmentService.sendSegmentEvent(
      'Onboarding Choose Plan Submitted',
      null
    );

    // this.segmentService.sendSegmentEvent('Onboarding Choose Plan Error', null);
  }
}
