import { Injectable } from '@angular/core';

export enum Actions {
  CLOSE = 'CLOSE',
  SCREEN = 'screen_view',
  CLICK = 'click',
  LOGOUT = 'LOGOUT',
  FB_SEND_EVENT = 'FB_SEND_EVENT',

}

declare var JSBridge;

@Injectable({
  providedIn: 'root',
})
export class CommunicationWebviewNative {
  private isAndroid = false;
  private readonly CHANNEL = 'meetingDoctors';

  constructor() {
    this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }

  emitEvent(data: { action: Actions; payload: any } ) {
    try {
      // Si es Ios
      if (!this.isAndroid) {
        window['webkit'].messageHandlers[this.CHANNEL].postMessage(
          JSON.stringify(data)
        );
        return;
      }

      // Es android
      const meetingEvent = new CustomEvent(this.CHANNEL, {detail: data});
      document.dispatchEvent(meetingEvent);
    } catch (err) {
      console.error(
        `Error to communicate with natives platform: (${
          this.isAndroid ? 'Android' : 'IOs'
        })`,
        err
      );
    }
  }
}
