import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

import { SEOService } from 'src/app/services/SEO/seo.service';

@Component({
  selector: 'app-qr-watch',
  templateUrl: './qr-watch.component.html',
  styleUrls: ['./qr-watch.component.scss']
})
export class QrWatchComponent implements OnInit {

  public width: number = 0;
  @ViewChild('carouselIncludedComp') carouselIncluded: ElementRef;

  videos = [

    {
      title: 'Configuración Reloj',
      src: 'https://player.vimeo.com/video/875486735?h=e223610d30&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
    },
  ]

  videosApp = [
    {
      title: 'Instalación',
      src: 'https://player.vimeo.com/video/875487157?h=e253f4f8b9&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"'
    },
    {
      title: 'Autorizados',
      src: 'https://player.vimeo.com/video/875549600?h=2aa4f70002&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"'
    },
    {
      title: 'Contraseña',
      src: 'https://player.vimeo.com/video/875487270?h=00a23643ac&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
    },
    {
      title: 'Guía Uso',
      src: 'https://player.vimeo.com/video/875487364?h=d942f9b3ca&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
    },
    {
      title: 'Gestión de Autorizados',
      src: 'https://player.vimeo.com/video/875487453?h=1b4d17644e&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
    }
  ]

  selectedTitle = this.videos[0].title;
  selectedTitleApp = this.videosApp[0].title

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private seoService: SEOService
    ) {

  }

  /** */

  ngOnInit(): void {
    this.seoService.setSEOByUrl()
    if (isPlatformBrowser(this.platformId))
      this.width = window.innerWidth;
  }

  changeTitle($event) {
    const arr = $event.current.split("-")
    const slide = Number(arr[arr.length - 1]);
    this.selectedTitle = this.videos[slide].title
  }

  changeTitleApp($event) {
    const arr = $event.current.split("-")
    const slide = Number(arr[arr.length - 1]);
    this.selectedTitleApp = this.videosApp[slide].title

  }

  public get isBigScreen(): boolean {
    return this.width >= 1600;
  }

  public get getDeviceType(): string{
    if(this.width >= 1600){
      return 'xl';
    }

    if(this.width >= 1032){
      return 'l';
    }

    if(this.width >= 647){
      return 'md';
    }

    if(this.width >= 455){
      return 'sm';
    }
    
    return 'sm';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
  }

}
