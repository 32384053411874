import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  Actions,
  CommunicationWebviewNative,
} from 'src/app/components/lead-vitaldent/services/communication-webview-native.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { OnboardingStateService } from '../../../services/onboarding-state/onboarding-state.service';

@Component({
  selector: 'app-presentation-step',
  templateUrl: './presentation-step.component.html',
  styleUrls: ['./presentation-step.component.scss'],
})
export class PresentationStepComponent implements OnInit {
  @Output() continue = new EventEmitter();
  continueDebouncer: Subject<any> = new Subject<any>();

  constructor(
    public onboardingStateService: OnboardingStateService,
    private segmentService: SegmentService,
    private webviewEvents: CommunicationWebviewNative
  ) {
    this.continueDebouncer
      .pipe(debounceTime(250))
      .subscribe(({ isClient, buttonText }) => {
        this.continueFn();
      });
  }

  ngOnInit() {
    this.webviewEvents.emitEvent({
      action: Actions.FB_SEND_EVENT,
      payload: {
        event: Actions.SCREEN,
        params: {
          screen_name: 'INICIO',
          screen_class: 'INICIO',
        },
      },
    });

    this.segmentService.sendSegmentEvent('Onboarding Presentation', null);
  }

  continueFn() {
    this.segmentService.sendSegmentEvent(
      'Onboarding Presentation Continue',
      null
    );
    this.continue.emit(true);
  }
}
