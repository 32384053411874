<form [formGroup]="callMeForm" (submit)="onSubmit()" class="d-flex flex-column" [ngClass]="{ 'flex-lg-row': lgRow, 'blackWhite container': blackWhite }" *ngIf="!responseOK && !responseKO else divOK">
    <mat-form-field appearance="outline" class="d-block" [ngClass]="{ 'get-offer': from === 'get-offer', 'w-100': lgRow }" *ngIf="!blackWhite">
      <mat-label *ngIf="!isEmail">Número de teléfono</mat-label>
      <mat-label *ngIf="isEmail">Ingrese correo electrónico</mat-label>
      <input matInput name="phone" [formControlName]="!isEmail ? 'phone' : 'email'" [placeholder]="!isEmail ? 'Teléfono' : 'email'">
    </mat-form-field>

    <h4 class="fs-18 mr-4" *ngIf="blackWhite">TE LLAMAMOS</h4>
    <input class="input-blackWhite" type="text" formControlName="phone" placeholder="Introduce tu número" *ngIf="blackWhite">

    <mat-checkbox class="example-margin"
      formControlName="accept" *ngIf="blackWhite">He leído los <a href="">Términos de protección de datos</a></mat-checkbox>

    <button type="submit" class="btn btn-block rounded text-white" [disabled]="callMeForm.invalid" [ngClass]="{ 'disabled': callMeForm.invalid, 'get-offer': from === 'get-offer', 'btn-desktop': lgRow, 'btn-email': isEmail }">{{ (from === 'get-offer') ? 'Enviar' : '¡LLAMADME GRATIS!' }}</button>
</form>

<ng-template #divOK>
  <div class="d-flex flex-row justify-content-between align-items-center bg-white p-3" *ngIf="responseOK">
    <img src="../../../../assets/images/interface-check.svg" alt="no disponible" class="mr-3">
    <p class="text-global">
      En breve recibirás un SMS con los enlaces de descarga de la app.
    </p>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-center bg-white p-3" *ngIf="responseKO">
    <span class="icon-icn-close text-red fs-30 mr-3"></span>
    <p class="text-global">
      Parece que el servicio no está disponible en estos momentos. Inténtelo más tarde.
    </p>
  </div>
</ng-template>
