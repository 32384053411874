import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { EventDetailLead } from 'src/app/services/google-analytics/classes/eventdetaillead';
import { EventDetailLeadError } from 'src/app/services/google-analytics/classes/eventdetailleaderror';
import { FormService } from 'src/app/services/form/form.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { ServiceItem } from 'src/app/services/google-analytics/classes/serviceitem';
import { error } from 'jquery';

@Component({
  selector: 'app-test-it-modal',
  templateUrl: './test-it-modal.component.html',
  styleUrls: ['./test-it-modal.component.scss']
})
export class TestItModalComponent implements OnInit {

  @Output() close = new EventEmitter<string>();
  @Input() numberOfMonths: string = '2 meses';
  @Input() forVitalDent: boolean = false;
  @Input() eusk = false;
  dataToSend: any;
  callMeForm = new FormGroup({
    phone: new FormControl('', [Validators.required, Validators.pattern(/^(\+34|0034|34)?[67]\d{8}$/)])
  })


  constructor(
    private analyticsService: AnalyticsService,
    private formService: FormService,
    private googleAnalytics: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
  }

  send() {
    this.close.emit()
    this.sendPhone();
    this.analyticsService.sendCustomEvent({
      eventAction: 'Accion/Solicitud Informacion',
      eventLabel: 'Telefono',
      element:'Enviar'
    });
  }

  sendPhone() {
    this.dataToSend = {
      msisdn: Number(this.callMeForm.get('phone').value)
    };

    this.formService.sendData(this.dataToSend).subscribe(
      response => {
        // console.log('Response phone', response);
        // this.responseOK = true;
        // this.timeoutOK = setTimeout(() => {
        //   this.responseOK = false;
        //   this.callMeForm.reset();
        // }, 5000);

        this.googleAnalytics.sendEventToGoogleAnalytics(new ServiceItem({
          'event': 'generate_lead',
          'eventModel': new EventDetailLead(
            {
              content_type: 'app_download',
              position: 'popup'
            }
          )
        }))

      },
      err => {
        console.error('Error response phone:', err);
        // this.responseKO = true;
        // this.timeoutOK = setTimeout(() => {
        //   this.responseKO = false;
        // }, 5000);

        this.googleAnalytics.sendEventToGoogleAnalytics(new ServiceItem({
          'event': 'web_error',
          'eventModel': new EventDetailLeadError(
            {
              content_type: 'app_download',
              type: 'error'
            }
          )
        }))
      }
    );
  }

}
