<footer class="h-100 w-100">
  <div *ngIf="checkboxes && checkboxes.length > 0" class="radio">
    <div *ngFor="let c of checkboxes">
      <mat-checkbox [checked]="c.value" (change)="onChange($event, c)">
        <span [innerHTML]="c.text"></span>
      </mat-checkbox>
    </div>
  </div>


  <div *ngIf="buttonText" class="button-container text-blue">
    <button
      [ngClass]="
        !buttonValid || (checkboxes && this.requiredCheckboxes() === false)
          ? 'disabled'
          : ''
      "
      class="doctor-button"
      [disabled]="
        !buttonValid || (checkboxes && this.requiredCheckboxes() === false)
      "
      (click)="buttonClick.emit()"
    >
      <span [innerHTML]="buttonText"></span>
    </button>
  </div>

  <div *ngIf="linkText" [class.text-grey]="hasCode" [class.link]="!hasCode" class="text-blue" (click)="!hasCode ? linkClick.emit(true): null">
    {{ linkText }}
  </div>
</footer>
