import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyInsuranceRoutingModule } from './family-insurance-routing.module';
import { FamilyInsuranceComponent } from './family-insurance.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [FamilyInsuranceComponent],
  imports: [CommonModule, FamilyInsuranceRoutingModule, SharedModule],
})
export class FamilyInsuranceModule {}
