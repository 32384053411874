import { Injectable } from '@angular/core';
import { General } from './classes/general';
import { GeneralEventModel } from './classes/generaleventmodel';
import * as _ from 'lodash';

declare var window;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  constructor() { }

  sendEventToGoogleAnalytics(object: General | GeneralEventModel) {
    // window.dataLayer.push({ecommerce: null, eventModel: null});
     const res = JSON.parse(JSON.stringify(object))
     window.dataLayer.push(res);
     // test
  }

}
