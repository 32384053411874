import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

export type SegmentEvent =
  | 'VitaldentLead Started'
  | 'VitaldentLead Submitted'
  | 'VitaldentLead Error'
  | 'VitaldentLead Reset'
  | 'VitaldentLead Add Wallet'
  | 'Onboarding Presentation'
  | 'Onboarding Presentation Continue'
  | 'Onboarding Started'
  | 'Onboarding Started as'
  | 'Onboarding Started Continue'
  | 'Onboarding Verification Request Started'
  | 'Onboarding Verification Request Submitted'
  | 'Onboarding Verification Request Error'
  | 'Onboarding Verification Check Started'
  | 'Onboarding Verification Check Submitted'
  | 'Onboarding Verification Check Error'
  | 'Cuenta Verificada - LOGIN'
  | 'Cuenta Verificada - SIGNUP'
  | 'Onboarding Form Started'
  | 'Onboarding Form Submitted'
  | 'Onboarding Form Error'
  | 'Onboarding Form Create SVA Success'
  | 'Onboarding Form Create SVA Error'
  | 'Onboarding Choose Plan Started'
  | 'Onboarding Choose Plan Submitted'
  | 'Onboarding Choose Plan Error'
  | 'Onboarding Finished'
  | 'Invitation code Started'
  | 'Invitation code Submitted'
  | 'Invitation code Error'
  | 'Onboarding Payment Started'
  | 'Onboarding Payment Submitted'
  | 'Onboarding Payment Error'
  | 'Onboarding Back'
  | 'Onboarding OTP Started'
  | 'Onboarding OTP Submitted'
  | 'Onboarding OTP Error'
  | 'Onboarding OTP Retries'
  | 'Onboarding OTP Send Code Submitted'
  | 'Onboarding OTP Send Code Error'
  | 'Onboarding OTP Success'
  | 'Onboarding Activation Started'
  | 'Onboarding Activation Submitted'
  | 'Unsubscribe Started'
  | 'Unsubscribe Submitted'
  | 'Unsubscribe Comment Submitted'
  | 'Unsubscribe Submitted Error'
  | 'Unsubscribe Description Started'
  | 'Unsubscribe Description Logout'
  | 'Onboarding Invalid Token Error'
  | 'Onboarding Form Create Partner Promotions Success'
  | 'Onboarding Form Create Partner Promotions Error'
  | 'Onboarding Form Disable SVA'

export interface SegmentConfig {
  path: string;
  source: 'Banner';
  channel: 'web' | 'call-center' | 'shop';
}

export interface SegmentSubmittedConfig {
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  sendSegmentEvent(
    event: SegmentEvent,
    data: SegmentConfig | SegmentSubmittedConfig
  ) {

    if (isPlatformBrowser(this.platformId)) {
      if (data && data['data']) {
        window?.['analytics'].track(event + `${environment.environment === 'prod' ? '' : ' (STA)'}`, data['data']);
      } else {
        window?.['analytics'].track(event + `${environment.environment === 'prod' ? '' : ' (STA)'}`, data);
      }
    }

  }

  sendSegmentIdentifyEvent(userId: string, traits: any) {

    if (isPlatformBrowser(this.platformId)) {
      try {
        window?.['analytics'].identify(userId, traits);
      } catch (err) {
        console.log(err)
      }
    }


  }
}
