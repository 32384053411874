<!-- <section class="gap-16 d-flex justify-content-center">
  <button class="p-2 button" (click)="this.onboardingStateService.isYoigo.next(true)"
    [ngClass]="this.onboardingStateService.isYoigo.value ? 'selected' : ''">
    <span class="text-button">Soy cliente de Yoigo</span>
  </button>

  <button class="p-2 button" (click)="this.onboardingStateService.isYoigo.next(false)"
    [ngClass]="!this.onboardingStateService.isYoigo.value ? 'selected' : ''">
    <span class="text-button">No soy cliente de Yoigo</span>
  </button>
</section> -->

<section class="gap-16 w-100 d-flex flex-column">
  <span
    *ngIf="this.onboardingStateService.isYoigo.value"
    class="text-center fs-14"
  >
    Introduce tus <strong>datos</strong> para acceder:
  </span>
  <span
    *ngIf="!this.onboardingStateService.isYoigo.value"
    class="text-center fs-14"
  >
    Introduce tus datos para acceder:
  </span>

  <form
    [formGroup]="this.onboardingStateService.form"
    class="d-flex flex-column"
  >
    <mat-form-field appearance="outline" tabindex="0">
      <mat-label *ngIf="this.onboardingStateService.isYoigo.value"
        >Móvil de Yoigo</mat-label
      >
      <mat-label *ngIf="!this.onboardingStateService.isYoigo.value"
        >Tu número de Móvil</mat-label
      >
      <input
        noSpaces
        [focusAfter]="750"
        matInput
        formControlName="phone"
        nextFocusId="nieDni"
      />
    </mat-form-field>
    <article class="gap-24 d-flex justify-content-between">
      <mat-form-field
        nextFocusId="document"
        [style.width]="'80px'"
        appearance="outline"
        tabindex="1"
      >
        <mat-label>DNI</mat-label>
        <mat-select id="nieDni" formControlName="nieDni">
          <mat-option value="dni">DNI</mat-option>
          <mat-option value="nie">NIE</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        [style.width.px]="220"
        [ngClass]="'flex-1'"
        appearance="outline"
        tabindex="2"
      >
        <mat-label *ngIf="this.onboardingStateService.isYoigo.value"
          >DNI del titular</mat-label
        >
        <mat-label *ngIf="!this.onboardingStateService.isYoigo.value"
          >DNI del titular</mat-label
        >
        <input
          noSpaces
          autocapitalize="characters"
          id="document"
          matInput
          formControlName="document"
        />
      </mat-form-field>
    </article>
    <app-onboarding-form-error *ngIf="error">{{
      error
    }}</app-onboarding-form-error>
  </form>
  <app-onboarding-blue-box class="custom-text" style="font-size: 14px">
    <strong>Si no tienes cuenta</strong> no hay problema, introduce tus datos
    igualmente y la creamos en un momento.
  </app-onboarding-blue-box>
</section>

<app-sign-up-footer
  class="h-100"
  [hasCode]="
    this.onboardingStateService.verificationForm.controls.invitation.value ||
    this.onboardingStateService.promotionForm.controls.code.value
  "
  [linkText]="
    loading ? '' : this.onboardingStateService.verificationForm.controls.invitation.value ||
    this.onboardingStateService.promotionForm.controls.code.value
      ? 'Código introducido: ' +
        (this.onboardingStateService.verificationForm.controls.invitation
          .value ||
          this.onboardingStateService.promotionForm.controls.code.value)
      : '¿Tienes un código?'
  "
  (linkClick)="this.continueInvitationDebouncer.next(true)"
  [buttonValid]="this.onboardingStateService.form.valid && !loading"
  (buttonClick)="continueDebouncer.next()"
  [buttonText]="loading ? '<img class=spinner-onboarding width=26 src=/assets/images/Spinner.svg alt=Cargando />' : 'ACCEDER A DOCTORGO'"
  [checkboxes]="checkboxes"
></app-sign-up-footer>
