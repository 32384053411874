<div class="header">
  <div *ngIf="!isHiring" id="callMeSection" class="header__top">
    <div class="header__top">
      <div class="header__top__contact">
        <a class="header__top__contact__text" href="/contacto">Contacto</a>
      </div>
    </div>
  </div>

  <nav class="header__mid" [ngClass]="isHiring ? 'justify-content-center' : ''">
    <div *ngIf="!isHiring">
      <button class="" (click)="toggleNavbar()" type="button">
        <span class=""><img src="../../../../assets/images/navbar-mobile/menu.svg" alt="imagen no disponible"
            class="header__mid__menu-icon" /></span>
      </button>
    </div>

    <a href="/">
      <img class="header__mid__menu-img" src="../../../../assets/images/doctor-go-logo.svg"
        [ngClass]="isMobileViewComponent ? 'mobile-view-css' : ''" alt="no disponible" />
    </a>

    <section class="llama__gratis flex-column" [ngClass]="isHiring || isOnlineHealthLanding ? 'd-none' : 'd-flex'"
      id="callMe1Mobile"></section>

    <section *ngIf="isOnlineHealthLanding" class="test-app">
      <button data-ga="modal_open" class="test-app__button" (click)="openModalTryApp()">
        <span data-ga="modal_open" class="test-app__button__text">
          {{ "PRUEBA GRATIS LA APP" | uppercase }}
        </span>
      </button>
    </section>
  </nav>
</div>

<div class="menu" *ngIf="navbarIsOpened">
  <div class="menu__section">
    <div class="menu__section__header" (click)="openEspecialidades()">
      <a class="menu__section__header__text">Telemedicina
        <img class="" src="../../../../assets/images/menu-abajo.svg" alt="na"
          [ngClass]="[especialitiesIsOpened ? 'rotateIn' : 'rotateOut']" />
      </a>
    </div>

    <div class="menu__section__list" *ngIf="especialitiesIsOpened">
      <div class="menu__section__list">
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/alergologo">Alergología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/cardiologo">Cardiología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/dermatologo">Dermatología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/ginecologo">Ginecología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/pediatra">Pediatría</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/psicologo">Psicología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/sexologo">Sexología</a>
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/medico-general-online">Médico General</a>
      </div>
    </div>
  </div>

  <div class="menu__section">
    <div class="menu__section__header" (click)="openPersonalPlans()">
      <a class="menu__section__header__text">Nutrición y Fitness
        <img class="" src="../../../../assets/images/menu-abajo.svg" alt="na"
          [ngClass]="[personalPlansIsOpened ? 'rotateIn' : 'rotateOut']" />
      </a>
    </div>

    <div class="menu__section__list" *ngIf="personalPlansIsOpened">
      <div class="menu__section__list">
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/nutricionista">Nutricionista</a>
      </div>
      <div class="menu__section__list">
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/entrenador-personal-online">Entrenador personal</a>
      </div>
    </div>
  </div>

  <div class="menu__section">
    <div class="menu__section__header" (click)="openTelepharmacy()">
      <a class="menu__section__header__text">Telefarmacia y Receta Electrónica
        <img class="" src="../../../../assets/images/menu-abajo.svg" alt="na"
          [ngClass]="[telepharmacyIsOpened ? 'rotateIn' : 'rotateOut']" />
      </a>
    </div>
    <div class="menu__section__list" *ngIf="telepharmacyIsOpened">
      <div class="menu__section__list">
        <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
          (click)="closeNavbar()" href="/telefarmacia-y-receta-electronica">Telefarmacia y receta electrónica</a>
      </div>
    </div>
  </div>

  <div class="menu__section">
    <div class="menu__section__header" (click)="openHelp()">
      <a class="menu__section__header__text">Ayuda
        <img class="" src="../../../../assets/images/menu-abajo.svg" alt="na"
          [ngClass]="[helpIsOpened ? 'rotateIn' : 'rotateOut']" />
      </a>
    </div>

    <div class="menu__section__list" *ngIf="helpIsOpened">
      <a class="menu__section__list__frame" routerLinkActive="menu__section__list__frame__active"
        (click)="closeNavbar()" href="/ayuda">
        Ayuda
      </a>
    </div>
  </div>
</div>

<app-test-it-modal *ngIf="showTestItModal" [numberOfMonths]="numberOfMonths" (close)="showTestItModal = false">
  <app-test-it-modal></app-test-it-modal>
</app-test-it-modal>
