export const environment = {
  production: true,
  environment: 'prod',
  backendUrlApi:
    'https://masmovil-doctorgo-api.prod.k8s.masmovil.com/masmovil-doctorgo-api',
  metaImageBaseUrl: 'https://doctorgo.es',

  contractEmailTimeout: 1000, // 15 * 60 * 1000

  pkStripe:  'pk_live_51N0mICK0k7ip6FYpnPkE8hL15kpDLXhE1QDzGKm1AlErQ2Ye8cs3KZkF2DFHe4lJyuXBfdNKfqOLVFGa1a97u8IJ00ZWImvgCV'

};
