import { Component, OnInit, ElementRef, ViewChild, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { TeleasistenciaService } from '../../services/teleasistencia/teleasistencia.service';
import { SEOService } from '../../services/SEO/seo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { EventDetail } from 'src/app/services/google-analytics/classes/eventdetail';
import { GeneralEventModel } from 'src/app/services/google-analytics/classes/generaleventmodel';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-form-watch',
  templateUrl: './form-watch.component.html',
  styleUrls: ['./form-watch.component.scss']
})
export class FormWatchComponent implements OnInit {

  public width: number = 0;
  public phoneNumber: string = '';
  public phoneForm: FormGroup;

  public correoEnviado: boolean = false;

  @ViewChild('carouselIncludedComp') carouselIncluded: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) public platformId: object,
    public http: HttpClient,
    public teleasistencia: TeleasistenciaService,
    private seoService: SEOService,
    public modalService: NgbModal,
    public analyticsService: GoogleAnalyticsService
    ) {
    }

  ngOnInit(): void {
    this.seoService.setSEOByUrl()
    if (isPlatformBrowser(this.platformId))
      this.width = window.innerWidth;

    this.phoneForm = new FormGroup({
      phone: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });
  }

  public get isBigScreen(): boolean {
    return this.width >= 1600;
  }

  public get getDeviceType(): string{

    if(this.width > 1023 && this.width <= 1600){
      return 'xl';
    }else if(this.width > 768 && this.width < 1023){
      return 'l';
    }else if(this.width > 455 && this.width <= 768){
      return 'md';
    }else if(this.width <= 455){
      return 'sm';
    }
    
    return 'xxl';
  }

  public openModal(content){

    const eventDetail = new EventDetail({content_type: 'calls_pop_up_open',outbound: false})
    const analyticsEvent = new GeneralEventModel()
    analyticsEvent.event = 'click';
    analyticsEvent.eventModel = {...eventDetail}
    this.analyticsService.sendEventToGoogleAnalytics(analyticsEvent);



    let generalEvent:GeneralEventModel = new GeneralEventModel();
    generalEvent.event = "impression";
    generalEvent.eventModel = {
      content_type: 'pop_up : C2C'
    };

    this.analyticsService.sendEventToGoogleAnalytics(generalEvent);
    
    this.modalService.open(content, { centered: true })
  }

  public closeModal(){
    this.modalService.dismissAll();
  }

  public async sendPhone(){
    
    this.correoEnviado = false;
    const { phone, name } = this.phoneForm.getRawValue()
    if((phone && phone > 0) && (name && name.trim().length > 0)){
      const result:any = await this.teleasistencia.sendPhoneData(phone, name);

      if(result.enviado){
        const generalEvent = new GeneralEventModel();
        generalEvent.event = 'generate_lead';
        generalEvent.eventModel = {
          content_type: 'call_me_back'
        }

        this.analyticsService.sendEventToGoogleAnalytics(generalEvent);
      }

      this.correoEnviado = result.enviado;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
  }

}
