import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import {
  PageLoaded,
  GenericPromotion,
  GenericProduct,
} from '../../interfaces/analytics.interface';

import { ViewItem } from '../google-analytics/classes/viewitem';

export interface CookiesConfig {
  preferences?: boolean;
  advertising?: boolean;
  analytics?: boolean;
  date?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  clickEvent!: GenericPromotion;
  cookiesConfig!: CookiesConfig;

  isFirstTimeVideo = true;
  isFirstTimeIWant = true;
  isFirstTimeIWantYoigo = true;

  promoCTA: any;
  loaded: boolean = false;

  constructor(
    private cookieService: CookieService,
    private googleAnalytics: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  removeToCart(append: boolean, price?: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (await this.isCookieActive()) {

        // console.log('__addToCart__');

        window['analyticService'].addToCart({
          ctaText: 'CONTRATAR',
          append,
          items: [
            {
              idTariff: 'SeguroOnline',
              brand: 'DoctorGO/DKV',
              category: 'Seguros/Salud',
              price: {
                monthPriceWithDiscountsWithIVA: price,
              },
              variant: 'Linea principal',
              name: 'Seguro Pack Familiar',
            },
          ],
        });
        resolve(true);
      }
    });
  }

  async addToCart(append: boolean, price?: string) {
    if (await this.isCookieActive()) {

      // console.log('__addToCart__');

      window['analyticService'].addToCart({
        ctaText: 'CONTRATAR',
        append,
        items: [
          {
            idTariff: 'SeguroOnline',
            brand: 'DoctorGO/DKV',
            category: 'Seguros/Salud',
            price: {
              monthPriceWithDiscountsWithIVA: price,
            },
            variant: 'Linea principal',
            name: 'Seguro Pack Familiar',
          },
        ],
      });
    }

    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'add_to_cart',
      'eventModel': ({
        items: [
          {
            'promotion_id': 'SeguroOnline',
            'promotion_name': 'Seguros Medicos',
            'item_brand': 'DoctorGO/DKV',
            'item_category': 'Seguros/Salud',
            'item_variant': 'Linea principal',
            'item_name': 'Seguro Pack Familiar',
            'price': price,
            'quantity': '1',
          }
        ],
        currency: 'EUR',
        value: price
      })
    }),
    )
  }

  pageLoaded(page: PageLoaded) {
   // console.log('__pageLoaded__', page);

    if (isPlatformBrowser(this.platformId)) {

      window['analyticService'].pageLoaded(page);

      this.pageLoadedContent(page)

    }

  }

   pageLoadedContent(page: PageLoaded) {
            // diapositiva 6
            if (page.items) {
              this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
                'event': 'view_item_list',
                'eventModel': ({
                  items: page.items.map(item => {
                    return ({
                      'item_id': item.brand,
                      'item_name': item.name,
                      'creative_slot': item.position,
                      'item_brand': item.brand,
                      'item_category': item.category,
                      'price': item.price.monthPriceWithDiscountsWithIVA,
                      'promotion_name': item.promoType,
                    })
                  }),
                })
              }),
              )
            }

            // diapositiva 4
            if (page.promotions) {
              page.promotions.forEach((promo, index) => {
                this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
                  'event': 'view_promotion',
                  'eventModel': ({
                    items: [
                      ({
                        'item_id': promo.offerType,
                        'item_name': promo.offeredService,
                        'promotion_id': promo.offerType,
                        'promotion_name': promo.offeredService,
                        'creative_slot': promo.position,
                        'index': index
                      })
                    ],

                    'creative_name': promo.offeredService,
                    'creative_slot': promo.position,
                    'promotion_id': promo.offerType,
                    'promotion_name': promo.offeredService

                  }),

                }),
                )
              })

            }
  }

  async sendCheckout(step: number, price: string) {
    if (await this.isCookieActive()) {

      // console.log('__sendCheckout__', step, price);

      window['analyticService'].sendCheckoutEvent({
        step,
        items: [
          {
            idTariff: 'SeguroOnline',
            brand: 'DoctorGO',
            category: 'SEGUROS',
            price: {
              monthPriceWithDiscountsWithIVA: price,
            },
            variant: 'Linea principal',
            name:
              step === 1
                ? 'SEGURO ONLINE'
                : price === '21.90'
                  ? `SEGURO ONLINE Cliente Yoigo`
                  : `SEGURO ONLINE No Cliente Yoigo`,
          },
        ],
      });
    }

    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'begin_checkout',
      'eventModel': ({
        items: [
          ({
            'promotion_id': 'SeguroOnline',
            'item_brand': 'DoctorGO',
            'item_category': 'SEGUROS',
            'item_variant': 'Linea principal',
            'item_name': step === 1
              ? 'SEGURO ONLINE'
              : price === '21.90'
                ? `SEGURO ONLINE Cliente Yoigo`
                : `SEGURO ONLINE No Cliente Yoigo`,
            'price': price,
            'quantity': '1',
          }),
        ],
      })
    }),
    )
  }

  async sendTransactionComplete(price: string) {
    if (await this.isCookieActive()) {
      // console.log('__sendTransactionComplete__');

      window['analyticService'].sendTransactionComplete({
        orderId: String(+new Date()),
        basketId: String(+new Date()),
        payment: 'tarjeta',
        totalAmount: price,
        newRegister: true,
        transactionPlatform: 'web',
        purchaseType: 'xsell',
        SFID: 'WE',
        items: [
          {
            idTariff: 'SeguroOnline',
            brand: 'DoctorGO',
            category: 'SEGUROS',
            price: {
              monthPriceWithDiscountsWithIVA: price,
            },
            variant: 'Linea principal',
            name:
              price === '21.90'
                ? `SEGURO ONLINE Cliente Yoigo`
                : `SEGURO ONLINE No Cliente Yoigo`,
          },
        ],
      });
    }

    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'add_payment_info',
      'eventModel': ({
        items: [
          ({
            'promotion_id': 'SeguroOnline',
            'item_brand': 'DoctorGO',
            'item_category': 'SEGUROS',
            'item_variant': 'Linea principal',
            'item_name': price === '21.90'
              ? `SEGURO ONLINE Cliente Yoigo`
              : `SEGURO ONLINE No Cliente Yoigo`,
            'price': price,


          }),
        ],

        currency: 'EUR',
        value: price,
        payment_type: 'tarjeta',

      })
    }),
    )
  }

  async sendTransactionError(errorCode: string, price: string) {
    if (await this.isCookieActive()) {

      // console.log('__sendTransactionError__');

      window['analyticService'].sendTransactionError({
        errorCode,
        item: {
          idTariff: 'SeguroOnline',
          brand: 'DoctorGO',
          category: 'SEGUROS',
          price: {
            monthPriceWithDiscountsWithIVA: price,
          },
          variant: 'Linea principal',
          name: 'SEGURO ONLINE',
        },
      });
    }

    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'web_error',
      'eventModel': ({
        items: [
          ({
            'promotion_id': 'SeguroOnline',
            'item_brand': 'DoctorGO',
            'item_category': 'SEGUROS',
            'item_variant': 'Linea principal',
            'item_name': 'SEGURO ONLINE',
            'price': price,
            'quantity': '1',
          }),
        ],
      })
    }),
    )
  }

  // TODO: Click en listado de promociones
  async sendPromotionsImpresion(data: any) {
    if (await this.isCookieActive()) {
      // console.log('_____dataPromotionImpresion_____');
      // console.log(data);
      if (isPlatformBrowser(this.platformId)) {
        window['analyticService'].sendPromotionsImpresion({
          externalPromos: data,
        });
      }
    }
  }

  // TODO: Click en promociones
  async sendPromotionClick(
    externalPromotion: GenericPromotion, // promoción en el que hacen click
    ctaText?: string // texto del botón la hacer click
  ) {
    if (await this.isCookieActive()) {

      // console.log('__Send Promo__', externalPromotion);
      if (isPlatformBrowser(this.platformId)) {
        window['analyticService'].sendPromotionClick(
          { externalPromotion: externalPromotion },
          ctaText
        );
      }
    }


    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'select_promotion',
      'eventModel': ({

        items: [
          ({
            item_id: externalPromotion.offerType,
            item_name: externalPromotion.offeredProductName,
            item_brand: externalPromotion.offeredProductName,
            item_category: externalPromotion.offeredService
          })
        ],

        promotion_id: externalPromotion.offerType,
        promotion_name: externalPromotion.offeredService

      })
    }),
    )
  }

  async sendProductClick(
    externalPromotion: GenericProduct, // promoción en el que hacen click
    ctaText?: string // texto del botón la hacer click
  ) {
    if (await this.isCookieActive()) {

      // console.log('__Send Promo__', externalPromotion);
      if (isPlatformBrowser(this.platformId)) {
        window['analyticService'].sendProductClick(externalPromotion, ctaText);
      }
    }

    this.googleAnalytics.sendEventToGoogleAnalytics(new ViewItem({
      'event': 'select_item',
      'eventModel': ({
        items: [
          ({
            item_name: externalPromotion.item.category,
            currency: 'EUR',
            item_id: externalPromotion.item.brand,
            'item_brand': externalPromotion.item.brand,
            'item_category': externalPromotion.item.category,
            'price': externalPromotion.item.price.monthPriceWithDiscountsWithIVA.toString(),
            'quantity': '1',
          }),

        ],
      })
    }),
    )
  }

  buildPromotionClickToSend(click: any) {
    this.clickEvent = {
      offerType: 'regularoffer',
      principalMessage: 'Tu medico aun par de clicks, alla donde estes',
      offeredService: 'Doctor Go',
      ppalImageAltText: 'URL de la imagen',
      position: 'Top',
    };

    this.sendPromotionClick(this.clickEvent, click.innerText);
  }

  async sendCustomEvent(data: any) {
    if (await this.isCookieActive()) {
      // console.log('_____dataCustomEvent_____');
      // console.log(data);
      if (isPlatformBrowser(this.platformId)) {
        window['analyticService'].sendCustomEvent({ dataToSend: data });
      }
    }
  }

  async initCookiesPreferences(cookiesConfig: CookiesConfig) {
    // console.log('_____objInitCookies_____');
    // console.log(cookiesConfig);
    this.cookiesConfig = cookiesConfig;
    if (isPlatformBrowser(this.platformId)) {
      window['analyticService'].initCookiesPreferences({
        cookiesConfig: this.cookiesConfig,
      });
    }
  }

  async sendCookiesPreferences(ctaText?: string, axLabel?: string) {
    // console.log(
    //   'Send cookies preferences',
    //   this.cookiesConfig,
    //   ctaText,
    //   axLabel
    // );
    if (isPlatformBrowser(this.platformId)) {
      window['analyticService'].sendCookiesPreferences({
        cookiesConfig: this.cookiesConfig,
        ctaText: ctaText ? ctaText : null,
        axLabel: axLabel ? axLabel : null,
      });
    }
  }

  setCookiesPreferences(cookiesConfig: CookiesConfig) {
    this.cookiesConfig = cookiesConfig;
  }

  async isCookieActive() {

    if (localStorage.getItem("ax_cookies_preferences")) {
      const item = JSON.parse(localStorage.getItem("ax_cookies_preferences"))

      if (!item.analytics)
        return false

      return true

    }

    return false;
  }

  setPromoCTA(promo: any) {
    this.promoCTA = promo;
  }

  getPromoCTA() {
    return this.promoCTA;
  }

  videoVisited(isVisited: boolean) {
    this.isFirstTimeVideo = isVisited;
  }

  iWantVisited(isVisited: boolean) {
    this.isFirstTimeIWant = isVisited;
  }

  iWantYoigoVisited(isVisited: boolean) {
    this.isFirstTimeIWantYoigo = isVisited;
  }

  getIsFirstTimeVideo() {
    return this.isFirstTimeVideo;
  }

  getIsFirstTimeIWant() {
    return this.isFirstTimeIWant;
  }

  getIsFirstTimeIWantYoigo() {
    return this.isFirstTimeIWantYoigo;
  }
}
