import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';

@Component({
  selector: 'app-booking-app',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit {
  constructor(private utils: FunctionUtilsService, private router: Router) { }

  ngOnInit(): void {

    if (!this.utils.getIsMobile())
      this.router.navigateByUrl('/')
      
  }
}
