import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  urls = [
    {
      url: '/?cliente=false',
      isIndexPage: true,
      title: 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
      description:
        'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'DoctorGO',
        alternateName: 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
        description:
          'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
        url: 'https://doctorgo.es',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/yoigo/',
          'https://www.instagram.com/yoigo/',
          'https://www.facebook.com/Yoigo/',
        ],
      },
      openGraph: {},
    },
    {
      url: '/contratacion',
      isIndexPage: true,
      title: 'DoctorGO: Contratación Seguro Pack Familiar',
      description:
        'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/contratacion',
      openGraph: {},
    },
    {
      url: '/',
      isIndexPage: true,
      title: 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
      description:
        'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'DoctorGO',
        alternateName: 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
        description:
          'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
        url: 'https://doctorgo.es',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/analitica-sangre-y-orina',
      isIndexPage: false,
      title: 'Analítica de sangre | Analítica de orina | DoctorGO',
      description:
        '¿Necesitas una analítica completa? Gratis con DoctorGO una analítica de sangre y orina para conocer el estado de tu salud y detectar posibles enfermedades.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/analitica-sangre-y-orina',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/analitica-sangre-y-orina",
        "name": "Test Analítica Sangre y Orina",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Analítica de sangre | Analítica de orina | DoctorGO",
        "description": "¿Necesitas una analítica completa? Gratis con DoctorGO una analítica de sangre y orina para conocer el estado de tu salud y detectar posibles enfermedades.",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "800 622 278",
            "areaServed": "ES",
            "contactOption": "TollFree",
            "contactType": "customer service"
          }
        ],
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },
    {
      url: '/programas-salud',
      isIndexPage: false,
      title: 'Descubre por qué contratar DoctorGO de Yoigo',
      description:
        'Llega DoctorGO, el nuevo servicio de salud a lo Yoigo. Servicio inmediato con grandes especialistas. Descubre nuestra oferta y disfruta de ¡1 mes GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/programas-salud',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/programas-salud',
        name: 'Programas de Salud',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Descubre por qué contratar DoctorGO de Yoigo',
        description:
          'Llega DoctorGO, el nuevo servicio de salud a lo Yoigo. Servicio inmediato con grandes especialistas. Descubre nuestra oferta y disfruta de ¡1 mes GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/telefarmacia-y-receta-electronica',
      isIndexPage: false,
      title: 'Telefarmacia. Consigue tu receta electronica con DoctorGO',
      description:
        'Con DoctorGO tendrás siempre tu receta electrónica en el móvil y servicio de telefarmacia GRATIS para llevarte tus medicamentos sin salir de casa.',
      index: true,
      follow: true,
      canonical:
        'https://doctorgo.es/telefarmacia-y-receta-electronica',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/telefarmacia-y-receta-electronica',
        name: 'Telefarmacia y Receta Electrónica',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Telefarmacia. Consigue tu receta electronica con DoctorGO',
        description:
          'Con DoctorGO tendrás siempre tu receta electrónica en el móvil y servicio de telefarmacia GRATIS para llevarte tus medicamentos sin salir de casa.',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/alergologo',
      isIndexPage: false,
      title: 'Alergólogo Online. Alergología sin salir de casa | DoctorGO',
      description:
        '¿Necesitas una cita con un alergólogo online? Aquí puedes hacerlo de una forma rápida y sencilla. ✓Alergología 100% online y 24/7. El primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/alergologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/alergologo',
        name: 'Alergólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Alergólogo Online. Alergología sin salir de casa | DoctorGO',
        description:
          '¿Necesitas una cita con un alergólogo online? Aquí puedes hacerlo de una forma rápida y sencilla. ✓Alergología 100% online y 24/7. El primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/cardiologo',
      isIndexPage: false,
      title: 'Tu Cardiólogo Online Privado de DoctorGO',
      description:
        'Si necesitas una cita con un cardiólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Cardiología 100% online y 24/7. El primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/cardiologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/cardiologo',
        name: 'Cardiólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Tu Cardiólogo Online Privado de DoctorGO',
        description:
          'Si necesitas una cita con un cardiólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Cardiología 100% online y 24/7. El primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/dermatologo',
      isIndexPage: false,
      title: 'Dermatólogo Online y sin salir de casa | DoctorGO',
      description:
        'Si necesitas una cita con un dermatólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Dermatología 100% online y 24/7. Primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/dermatologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/dermatologo',
        name: 'Dermatólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Dermatólogo Online y sin salir de casa | DoctorGO',
        description:
          'Si necesitas una cita con un dermatólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Dermatología 100% online y 24/7. Primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/ginecologo',
      isIndexPage: false,
      title: 'Ginecólogo Online. Ginecología sin salir de casa | DoctorGO',
      description:
        'Si necesitas una consulta con un ginecólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Ginecología 100% online y 24/7. Primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/ginecologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/ginecologo',
        name: 'Ginecólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Ginecólogo Online. Ginecología sin salir de casa | DoctorGO',
        description:
          'Si necesitas una consulta con un ginecólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Ginecología 100% online y 24/7. Primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/psicologo',
      isIndexPage: false,
      title: 'Psicólogo Online ¡Primer mes gratis! | DoctorGO',
      description:
        'Si necesitas cita con un psicólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Psicología100% online y 24/7. El primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/psicologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/psicologo',
        name: 'Psicólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Psicólogo Online ¡Primer mes gratis! | DoctorGO',
        description:
          'Si necesitas cita con un psicólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Psicología100% online y 24/7. El primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/sexologo',
      isIndexPage: false,
      title: 'Sexólogo Online. Sexología sin salir de casa | DoctorGO',
      description:
        'Si necesitas cita con un sexólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Sexologia 100% online y 24/7. El primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/sexologo',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/sexologo',
        name: 'Sexólogo Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Sexólogo Online. Sexología sin salir de casa | DoctorGO',
        description:
          'Si necesitas cita con un sexólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Sexologia 100% online y 24/7. El primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/pediatra',
      isIndexPage: false,
      title: 'Pediatra online. Consulta con pediatra en línea | DoctorGO',
      description:
        '¿Necesitas una cita con el pediatra online? aquí puedes hacerlo de una forma rápida y sencilla. ✓Tu pediatra 100% online y 24/7 ¡Disfruta ya de 1 mes GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/pediatra',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/pediatra',
        name: 'Pediatra Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Pediatra online. Consulta con un pediatra en línea | DoctorGO',
        description:
          '¿Necesitas una cita con el pediatra online? aquí puedes hacerlo de una forma rápida y sencilla. ✓Tu pediatra 100% online y 24/7 ¡Disfruta ya de 1 mes GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/medico-general-online',
      isIndexPage: false,
      title: 'Médico Online | Especialistas médicos online | DoctorGO',
      description:
        'Tu médico online a un clic. Con el servicio de telemedicina de DoctorGO cuentas con especialistas médicos las 24h por chat o videollamada ¡sin salir de casa!',
      index: true,
      follow: true,
      keywords: 'Médico online',
      canonical: 'https://doctorgo.es/medico-general-online',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/medico-general-online",
        "name": "Medico General Online",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Médico Online | Especialistas médicos online | DoctorGO",
        "description": "Tu médico online a un clic. Con el servicio de telemedicina de DoctorGO cuentas con especialistas médicos las 24h por chat o videollamada ¡sin salir de casa!",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "800 622 278",
            "areaServed": "ES",
            "contactOption": "TollFree",
            "contactType": "customer service"
          }
        ],
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]

      },
      openGraph: {},
    },
    {
      url: '/entrenador-personal-online',
      isIndexPage: false,
      title: 'Entrenador Personal Online. Primer mes ¡GRATIS! | DoctorGO',
      description:
        'Tu entrenador personal online a golpe de clic con el nuevo servicio de Yoigo Salud. → Accede a los mejores profesionales online. ¡1 mes GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/entrenador-personal-online',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/entrenador-personal-online',
        name: 'Entrenador Personal Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Entrenador Personal Online. Primer mes ¡GRATIS! | DoctorGO',
        description:
          'Tu entrenador personal online a golpe de clic con el nuevo servicio de Yoigo Salud. → Accede a los mejores profesionales online. ¡1 mes GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/receta-medica-electronica',
      isIndexPage: false,
      title: 'Telefarmacia. Pide tu receta electrónica - DoctorGO',
      description:
        'Recibe tus recetas médicas online directamente en el móvil. Fácil y rápido, tus recetas electrónicas en tu bolsillo. ✓ Telefarmacia con Yoigo.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/receta-medica-electronica',
      openGraph: {},
    },
    {
      url: '/nutricionista',
      isIndexPage: false,
      title: 'Nutricionista Online. ¡Primer mes gratis! | DoctorGO',
      description:
        'Si necesitas un nutricionista online, aquí lo tienes, de una forma rápida y sencilla. ✓ Tu experto en nutrición 100% online y 24/7. El primer mes ¡GRATIS!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/nutricionista',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/nutricionista',
        name: 'Nutricionista Online',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Nutricionista Online. ¡Primer mes gratis! | DoctorGO',
        description:
          'Si necesitas un nutricionista online, aquí lo tienes, de una forma rápida y sencilla. ✓ Tu experto en nutrición 100% online y 24/7. El primer mes ¡GRATIS!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/entrenamiento-virtual',
      isIndexPage: false,
      title: 'Entrenamiento con vídeos de la plataforma Entrena Virtual',
      description: "Con DoctorGO puedes entrenar en casa con la plataforma Entrena Virtual, cientos de vídeos y ejercicios variados según la área del cuerpo que quieras trabajar.",
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/entrenamiento-virtual',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/entrenamiento-virtual",
        "name": "Entrenamiento Virtual",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Entrenamiento con vídeos de la plataforma Entrena Virtual",
        "description": "Con DoctorGO puedes entrenar en casa con la plataforma Entrena Virtual, cientos de vídeos y ejercicios variados según la área del cuerpo que quieras trabajar.",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "800 622 278",
            "areaServed": "ES",
            "contactOption": "TollFree",
            "contactType": "customer service"
          }
        ],
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },
    {
      url: '/test-anticuerpos-covid',
      isIndexPage: false,
      title: 'Test Anticuerpos Covid | Test Coronavirus Online | DoctorGO',
      description:
        '¿Necesitas un test de anticuerpos Covid? Pídelo de forma online, rápido y sencillo. Test antígenos después de la vacuna ¡Pide ya tu prueba!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/test-anticuerpos-covid',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/test-anticuerpos-covid',
        name: 'Test Anticuerpos Covid',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Test Anticuerpos Covid | Test Coronavirus Online | DoctorGO',
        description:
          '¿Necesitas un test de anticuerpos Covid? Pídelo de forma online, rápido y sencillo. Test antígenos después de la vacuna ¡Pide ya tu prueba!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/ayuda',
      isIndexPage: false,
      title: 'Preguntas sobre el servicio de DoctorGO - Ayuda DoctorGO',
      description:
        '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/ayuda',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: '¿DoctorGO es un seguro de salud?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No, DoctorGO es un servicio de telemedicina que proporciona asesoramiento inmediato a través de unared de profesionales disponibles las 24 horas del día.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que ser de Yoigo para contratar?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Por el momento, el servicio de DoctorGO sólo está disponible para clientes Yoigo. No obstante, estamos trabajando para abrir el servicio al público. Si no eres parte de Yoigo asegúrate en nuestra lista de espera para recibir comunicaciones sobre cuando el servicio estará disponible para el público.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo darme de baja cuando quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tienes la flexibilidad de cancelar el servicio cuando lo consideres oportuno. Con DoctorGO no tienes ninguna obligación de permanencia y podrás decidir cancelar tu suscripción cuando lo creas necesario.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo usar DoctorGO tofas las veces que quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, el uso de DoctorGO es ilimitado. Puedes contactar con tu médico todas las veces que necesites para resolver todas tus dudas. Si el médico no se encuentra disponible en ese momento, puedes dejar tu consulta y te contestará en cuanto tenga disponibilidad.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo ir a mi farmacia con la receta electrónica de DoctorGO?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, las recetas electrónicas de DoctorGO están homologadas por la Organización Médica Colegial de España y están aceptadas en la mayoría de las farmacias.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo accedo a las citas presenciales?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Para poder acceder a las citas presenciales deberás seguir los siguientes pasos: 1. Pide cita a través de nuestro Portal del Paciente para elegir centro, día y hora. 2. Compra el servicio médico que necesites y recibe el justificante de compra con el código de tu bono. 3. Accede a tu centro y entrega el justificante de compra y el código de tu bono.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo elegir a mi médico?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tendrás la posibilidad de seleccionar entre los distintos médicos generalistas y especialistas para conseguir resolver las dudas con los mejores profesionales.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que pagar algo más aparte de los 6€ al mes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No para disfrutar de los servicios de DoctorGOsolo tendrás que pagar los 8€/mes. Sólo deberás hacer pagos adicionales en el caso de querer solicitar una prueba Covid, videollamadas con especialistas o visitas presenciales a través de la red hospitalaria de Quirón Salud.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo hablo con los doctores?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Puedes hablar con los doctores a través de chat o videollamada. Con DoctorGO el médico se adapta a tus horarios. Simplemente abre la apalicación y contacta por chat con nuestro equipo médico cuando mejor te venga. Tu médico te estará esperando. Si lo prefieres, también tienes la opción de hacer una videollamada con nuestros médicos generalistas.',
            },
          },
        ],
      },
      openGraph: {},
    },
    {
      url: '/ayuda/registro',
      isIndexPage: false,
      title: 'Preguntas sobre el servicio de DoctorGO - Ayuda DoctorGO',
      description:
        '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/ayuda',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: '¿DoctorGO es un seguro de salud?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No, DoctorGO es un servicio de telemedicina que proporciona asesoramiento inmediato a través de unared de profesionales disponibles las 24 horas del día.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que ser de Yoigo para contratar?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Por el momento, el servicio de DoctorGO sólo está disponible para clientes Yoigo. No obstante, estamos trabajando para abrir el servicio al público. Si no eres parte de Yoigo asegúrate en nuestra lista de espera para recibir comunicaciones sobre cuando el servicio estará disponible para el público.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo darme de baja cuando quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tienes la flexibilidad de cancelar el servicio cuando lo consideres oportuno. Con DoctorGO no tienes ninguna obligación de permanencia y podrás decidir cancelar tu suscripción cuando lo creas necesario.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo usar DoctorGO tofas las veces que quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, el uso de DoctorGO es ilimitado. Puedes contactar con tu médico todas las veces que necesites para resolver todas tus dudas. Si el médico no se encuentra disponible en ese momento, puedes dejar tu consulta y te contestará en cuanto tenga disponibilidad.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo ir a mi farmacia con la receta electrónica de DoctorGO?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, las recetas electrónicas de DoctorGO están homologadas por la Organización Médica Colegial de España y están aceptadas en la mayoría de las farmacias.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo accedo a las citas presenciales?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Para poder acceder a las citas presenciales deberás seguir los siguientes pasos: 1. Pide cita a través de nuestro Portal del Paciente para elegir centro, día y hora. 2. Compra el servicio médico que necesites y recibe el justificante de compra con el código de tu bono. 3. Accede a tu centro y entrega el justificante de compra y el código de tu bono.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo elegir a mi médico?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tendrás la posibilidad de seleccionar entre los distintos médicos generalistas y especialistas para conseguir resolver las dudas con los mejores profesionales.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que pagar algo más aparte de los 6€ al mes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No para disfrutar de los servicios de DoctorGOsolo tendrás que pagar los 8€/mes. Sólo deberás hacer pagos adicionales en el caso de querer solicitar una prueba Covid, videollamadas con especialistas o visitas presenciales a través de la red hospitalaria de Quirón Salud.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo hablo con los doctores?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Puedes hablar con los doctores a través de chat o videollamada. Con DoctorGO el médico se adapta a tus horarios. Simplemente abre la apalicación y contacta por chat con nuestro equipo médico cuando mejor te venga. Tu médico te estará esperando. Si lo prefieres, también tienes la opción de hacer una videollamada con nuestros médicos generalistas.',
            },
          },
        ],
      },
      openGraph: {},
    },
    {
      url: '/ayuda/telemedicina',
      isIndexPage: false,
      title: 'Preguntas sobre el servicio de DoctorGO - Ayuda DoctorGO',
      description:
        '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/ayuda',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: '¿DoctorGO es un seguro de salud?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No, DoctorGO es un servicio de telemedicina que proporciona asesoramiento inmediato a través de unared de profesionales disponibles las 24 horas del día.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que ser de Yoigo para contratar?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Por el momento, el servicio de DoctorGO sólo está disponible para clientes Yoigo. No obstante, estamos trabajando para abrir el servicio al público. Si no eres parte de Yoigo asegúrate en nuestra lista de espera para recibir comunicaciones sobre cuando el servicio estará disponible para el público.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo darme de baja cuando quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tienes la flexibilidad de cancelar el servicio cuando lo consideres oportuno. Con DoctorGO no tienes ninguna obligación de permanencia y podrás decidir cancelar tu suscripción cuando lo creas necesario.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo usar DoctorGO tofas las veces que quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, el uso de DoctorGO es ilimitado. Puedes contactar con tu médico todas las veces que necesites para resolver todas tus dudas. Si el médico no se encuentra disponible en ese momento, puedes dejar tu consulta y te contestará en cuanto tenga disponibilidad.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo ir a mi farmacia con la receta electrónica de DoctorGO?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, las recetas electrónicas de DoctorGO están homologadas por la Organización Médica Colegial de España y están aceptadas en la mayoría de las farmacias.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo accedo a las citas presenciales?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Para poder acceder a las citas presenciales deberás seguir los siguientes pasos: 1. Pide cita a través de nuestro Portal del Paciente para elegir centro, día y hora. 2. Compra el servicio médico que necesites y recibe el justificante de compra con el código de tu bono. 3. Accede a tu centro y entrega el justificante de compra y el código de tu bono.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo elegir a mi médico?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tendrás la posibilidad de seleccionar entre los distintos médicos generalistas y especialistas para conseguir resolver las dudas con los mejores profesionales.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que pagar algo más aparte de los 6€ al mes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No para disfrutar de los servicios de DoctorGOsolo tendrás que pagar los 8€/mes. Sólo deberás hacer pagos adicionales en el caso de querer solicitar una prueba Covid, videollamadas con especialistas o visitas presenciales a través de la red hospitalaria de Quirón Salud.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo hablo con los doctores?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Puedes hablar con los doctores a través de chat o videollamada. Con DoctorGO el médico se adapta a tus horarios. Simplemente abre la apalicación y contacta por chat con nuestro equipo médico cuando mejor te venga. Tu médico te estará esperando. Si lo prefieres, también tienes la opción de hacer una videollamada con nuestros médicos generalistas.',
            },
          },
        ],
      },
      openGraph: {},
    },
    {
      url: '/ayuda/otros-servicios',
      isIndexPage: false,
      title: 'Preguntas sobre el servicio de DoctorGO - Ayuda DoctorGO',
      description:
        '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/ayuda',
      microdata: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: '¿DoctorGO es un seguro de salud?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No, DoctorGO es un servicio de telemedicina que proporciona asesoramiento inmediato a través de unared de profesionales disponibles las 24 horas del día.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que ser de Yoigo para contratar?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Por el momento, el servicio de DoctorGO sólo está disponible para clientes Yoigo. No obstante, estamos trabajando para abrir el servicio al público. Si no eres parte de Yoigo asegúrate en nuestra lista de espera para recibir comunicaciones sobre cuando el servicio estará disponible para el público.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo darme de baja cuando quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tienes la flexibilidad de cancelar el servicio cuando lo consideres oportuno. Con DoctorGO no tienes ninguna obligación de permanencia y podrás decidir cancelar tu suscripción cuando lo creas necesario.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo usar DoctorGO tofas las veces que quiera?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, el uso de DoctorGO es ilimitado. Puedes contactar con tu médico todas las veces que necesites para resolver todas tus dudas. Si el médico no se encuentra disponible en ese momento, puedes dejar tu consulta y te contestará en cuanto tenga disponibilidad.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo ir a mi farmacia con la receta electrónica de DoctorGO?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, las recetas electrónicas de DoctorGO están homologadas por la Organización Médica Colegial de España y están aceptadas en la mayoría de las farmacias.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo accedo a las citas presenciales?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Para poder acceder a las citas presenciales deberás seguir los siguientes pasos: 1. Pide cita a través de nuestro Portal del Paciente para elegir centro, día y hora. 2. Compra el servicio médico que necesites y recibe el justificante de compra con el código de tu bono. 3. Accede a tu centro y entrega el justificante de compra y el código de tu bono.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Puedo elegir a mi médico?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sí, tendrás la posibilidad de seleccionar entre los distintos médicos generalistas y especialistas para conseguir resolver las dudas con los mejores profesionales.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Tengo que pagar algo más aparte de los 6€ al mes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No para disfrutar de los servicios de DoctorGOsolo tendrás que pagar los 8€/mes. Sólo deberás hacer pagos adicionales en el caso de querer solicitar una prueba Covid, videollamadas con especialistas o visitas presenciales a través de la red hospitalaria de Quirón Salud.',
            },
          },
          {
            '@type': 'Question',
            name: '¿Cómo hablo con los doctores?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Puedes hablar con los doctores a través de chat o videollamada. Con DoctorGO el médico se adapta a tus horarios. Simplemente abre la apalicación y contacta por chat con nuestro equipo médico cuando mejor te venga. Tu médico te estará esperando. Si lo prefieres, también tienes la opción de hacer una videollamada con nuestros médicos generalistas.',
            },
          },
        ],
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos',
      isIndexPage: true,
      title: 'DoctorGO: el nuevo seguro médico sin copagos de Yoigo',
      description:
        'DoctorGO es el seguro de salud sin copago con consultas ilimitadas y médico online 24h. Seguro familiar de salud para todos ¡Sin cuestionarios!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "brand": "DoctorGO",
        "description": "DoctorGO es el seguro de salud sin copago con consultas ilimitadas y médico online 24h. Seguro familiar de salud para todos ¡Sin cuestionarios!",
        "hasOfferCatalog": {

          "@type": "OfferCatalog",
          "name": "Seguros Médicos",
          "itemListElement": [
            {
              "@type": "OfferCatalog",
              "name": "Seguro Dental"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Ginegológico"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico para Niños"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico en Oftalmología"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico en Dermatología"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico en Traumatología"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico en Enfermería"
            },
            {
              "@type": "OfferCatalog",
              "name": "Seguro Médico en Medicina General"
            }
          ],
          "sameAs": [
            "https://twitter.com/DoctorGo_",
            "https://www.instagram.com/doctorgo.es/",
            "https://www.facebook.com/DoctorGO.es"
          ]
        }

      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/dental',
      isIndexPage: true,
      title: 'Seguro dental DoctorGO: Mejora tu salud dental con Yoigo',
      description:
        'El seguro dental de DoctorGO de amplia cobertura para ti y tu familia. Hazte una revisión dental hoy mismo ¡No esperes más para cuidar de tu salud dental!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/dental',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/seguros-medicos/dental',
        name: 'Seguro Médico Dental',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Seguro dental DoctorGO: Mejora tu salud dental con Yoigo',
        description:
          'El seguro dental de DoctorGO de amplia cobertura para ti y tu familia. Hazte una revisión dental hoy mismo ¡No esperes más para cuidar de tu salud dental!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/ginecologico',
      isIndexPage: true,
      title: 'DoctorGO: el nuevo seguro ginecológico de Yoigo',
      description:
        'Con el seguro ginecológico de DoctorGO tendrás consultas ilimitadas para resolver todas tus dudas. Incluye revisión anual completa ¡Pide tu cita ya!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/ginecologico',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/seguros-medicos/ginecologico',
        name: 'Seguro Médico Ginecológico',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'DoctorGo: el nuevo seguro ginecológico de Yoigo',
        description:
          'Con el seguro ginecológico de DoctorGO tendrás consultas ilimitadas para resolver todas tus dudas. Incluye revisión anual completa ¡Pide tu cita ya!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/primeros-pasos-watch',
      isIndexPage: true,
      title: 'Primeros Pasos Watch - DoctorGO',
      description: '',
      index: false,
      follow: false,
      canonical: 'https://doctorgo.es/primeros-pasos-watch',
      microdata: null,
      openGraph: {},
    },

    {
      url: '/eu',
      isIndexPage: true,
      title: 'DoctorGO',
      description: '',
      index: false,
      follow: false,
      canonical: 'https://doctorgo.es/eu',
      microdata: null,
      openGraph: {},
    },
    {
      url: '/clientes-vitaldent',
      isIndexPage: true,
      title: 'Beneficios Mi Vitaldent - DoctorGO',
      description: '',
      index: false,
      follow: false,
      canonical: 'https://doctorgo.es/clientes-vitaldent',
      microdata: null,
      openGraph: {},
    },
    {
      url: '/reloj-teleasistencia',
      isIndexPage: true,
      title: 'Reloj Teleasistencia - DoctorGO',
      description: '',
      index: false,
      follow: false,
      canonical: 'https://doctorgo.es/reloj-teleasistencia',
      microdata: null,
      openGraph: {},
    },
    {
      url: '/seguros-medicos/seguro-integral-dkv',
      isIndexPage: true,
      title: 'Seguro Integral DKV - DoctorGO',
      description: '',
      index: false,
      follow: false,
      canonical: 'https://doctorgo.es/seguros-medicos/seguro-integral-dkv',
      microdata: null,
      openGraph: {},
    },
    {
      url: '/seguros-medicos/ninos',
      isIndexPage: true,
      title: 'Nuevo seguro médico para niños sin copagos de DoctorGO',
      description:
        'Con el seguro médico de niños de DoctorGO tendrás consultas ilimitadas con un pediatra y ¡Sin Esperas! Seguro de salud para niños SIN COPAGO de Yoigo.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/ninos',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/seguros-medicos/ninos',
        name: 'Seguro Médico Para Niños',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Nuevo seguro médico para niños sin copagos de DoctorGO',
        description:
          'Con el seguro médico de niños de DoctorGO tendrás consultas ilimitadas con un pediatra y ¡Sin Esperas! Seguro de salud para niños SIN COPAGO de Yoigo.',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/oftalmologia',
      isIndexPage: true,
      title: 'Seguro de Oftalmología con Revisión Incluida | DoctorGO',
      description:
        'Con el seguro de oftalmologia de DoctorGO, tendrás la revisión oftalmológica gratis para ti y toda tu familia ¡Pide cita hoy mismo!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/oftalmologia',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/seguros-medicos/oftalmologia',
        name: 'Seguro Oftalmología',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename:
          'Seguro de Oftalmología con Revisión Incluida | DoctorGO',
        description:
          'Con el seguro de oftalmologia de DoctorGO, tendrás la revisión oftalmológica gratis para ti y toda tu familia ¡Pide cita hoy mismo!',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/dermatologia',
      isIndexPage: true,
      title: 'Consultas de dermatología incluidas en tu seguro DoctorGO',
      description:
        'Consultas de dermatología incluidas en tu seguro de DoctorGO. Consultas presenciales ilimitadas con dermatólogos en los centros médicos de DKV.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/dermatologia',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/seguros-medicos/dermatologia",
        "name": "Seguro Dermatología",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Consultas de dermatología incluidas en tu seguro DoctorGO",
        "description": "Consultas de dermatología incluidas en tu seguro de DoctorGO. Consultas presenciales ilimitadas con dermatólogos en los centros médicos de DKV.",
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/traumatologia',
      isIndexPage: true,
      title: 'Consultas con el traumatólogo incluidas en tu seguro DoctorGO',
      description:
        'El seguro de DoctorGO incluye consultas y revisiones con un traumatólogo privado de los centros médicos DKV. ¡Pide cita ahora y hazte una revisión!',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/traumatologia',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/seguros-medicos/traumatologia",
        "name": "Seguro Traumatología",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Consultas con el traumatólogo incluidas en tu seguro DoctorGO",
        "description": "El seguro de DoctorGO incluye consultas y revisiones con un traumatólogo privado de los centros médicos DKV. ¡Pide cita ahora y hazte una revisión!",
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/enfermeria',
      isIndexPage: true,
      title: 'Consultas de enfermería y curas incluidas con tu seguro DoctorGO',
      description:
        'Consultas y revisiones de enfermeria incluidas en el seguro médico de DoctorGO. Servicios de enfermeria como curas ambulatorias en centros médicos de DKV.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/enfermeria',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/seguros-medicos/enfermeria",
        "name": "Seguro Enfermería",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Consultas de enfermería y curas incluidas con tu seguro DoctorGO",
        "description": "Consultas y revisiones de enfermeria incluidas en el seguro médico de DoctorGO. Servicios de enfermeria como curas ambulatorias en centros médicos de DKV.",
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },
    {
      url: '/seguros-medicos/medicina-general',
      isIndexPage: true,
      title: 'Consultas de atención primaria incluidas en tu seguro DoctorGO',
      description:
        'Consultas ilimitadas con un médico general privado en tu seguro DoctorGO. Citas sin esperas con médicos de atención primaria de los centros médicos de DKV.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/seguros-medicos/medicina-general',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/seguros-medicos/medicina-general",
        "name": "Seguro Medicina General",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Consultas de atención primaria incluidas en tu seguro DoctorGO",
        "description": "Consultas ilimitadas con un médico general privado en tu seguro DoctorGO. Citas sin esperas con médicos de atención primaria de los centros médicos de DKV.",
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es"
        ]
      },
      openGraph: {},
    },

    {
      url: '/cuadro-medico-dkv',
      isIndexPage: true,
      title: 'Cuadro Médico de DoctorGO - Garantía DKV',
      description:
        'Encuentra en el cuadro médico de DoctorGO el especialista y centro médico que necesitas cerca de ti. Con la garantía y profesionalidad de DKV.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/cuadro-medico-dkv',
      microdata: {
        '@context': 'http://schema.org/',
        '@type': 'Service',
        url: 'https://doctorgo.es/cuadro-medico-dkv',
        name: 'Cuadro Médico',
        logo: 'https://doctorgo.es/assets/images/doctor-go-logo.svg',
        alternatename: 'Cuadro Médico de DoctorGO - Garantía DKV',
        description:
          'Encuentra en el cuadro médico de DoctorGO x DKV el especialista y centro médico que necesitas cerca de ti. Con la garantía y profesionalidad de DKV.',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '800 622 278',
            areaServed: 'ES',
            contactOption: 'TollFree',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://twitter.com/DoctorGo_',
          'https://www.instagram.com/doctorgo.es/',
          'https://www.facebook.com/DoctorGO.es',
        ],
      },

      openGraph: {},
    },
    {
      url: '/contacto',
      isIndexPage: true,
      title: 'Contacto | DoctorGO',
      description:
        'Contacta con DoctorGO por teléfono, mail o redes sociales para resolver tus dudas.',
      index: true,
      follow: true,
      canonical: 'https://doctorgo.es/contacto',
      microdata: {
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "+34 622",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "ES"
          }
        ],
        "@type": "Corporation",
        "name": "DoctorGO",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "@context": "https://schema.org",
        "url": "https://doctorgo.es/contacto"
      },
      openGraph: {},
    },
    {
      url: '/sorteos',
      isIndexPage: true,
      index: false,
      follow: false,
      title: 'DoctorGO sortea un iPhone 13 para tus consultas médicas',
      canonical: `https://doctorgo.es/sorteos`,
      description:
        'Entra ahora en DoctorGO y llévate un iPhone 13 que sorteamos para que puedas acceder a todas tus consultas médicas online y servicios de telemedicina.',
      openGraph: {},
    },
    {
      url: '/cookies',
      isIndexPage: true,
      index: false,
      follow: false,
      title: 'Política de Cookies - DoctorGO',
      canonical: `https://doctorgo.es/cookies`,
      openGraph: {},
    },
    {
      url: '/mascotas',
      isIndexPage: true,
      index: false,
      follow: false,
      title: 'Mascotas - DoctorGO',
      canonical: `https://doctorgo.es/mascotas`,
      openGraph: {},
    },
    {
      url: '/dentista-online',
      isIndexPage: true,
      index: true,
      follow: true,
      title: 'Dentista Online. Tu denstista sin salir de casa | DoctorGO',
      canonical: 'https://doctorgo.es/dentista-online',
      description: '¿Necesitas cita con un dentista online? Con el servicio de Mi Vitaldent de DoctorGO tienes consultas ilimitadas con dentistas mediante Chat y Videollamadas.',
      microdata: {
        "@context": "http://schema.org/",
        "@type": "Service",
        "url": "https://doctorgo.es/dentista-online",
        "name": "Dentista Online",
        "logo": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
        "alternatename": "Dentista Online. Tu denstista sin salir de casa | DoctorGO",
        "description": "¿Necesitas cita con un dentista online? Con el servicio de Mi Vitaldent de DoctorGO tienes consultas ilimitadas con dentistas mediante Chat y Videollamadas.",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "800 622 278",
            "areaServed": "ES",
            "contactOption": "TollFree",
            "contactType": "customer service"
          }
        ],
        "sameAs": [
          "https://twitter.com/DoctorGo_",
          "https://www.instagram.com/doctorgo.es/",
          "https://www.facebook.com/DoctorGO.es",
        ]
      },
      openGraph: {}
    }
  ];
  titleElement: any;

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.titleElement = doc.getElementsByTagName('title')[0];
    // console.log('Title element', this.titleElement[0]);
  }

  setSEOByUrl() {
    let urlSeo = this.urls.find((url) => url.url === this.router.url);
    if (!urlSeo && this.router.url.includes('?')) {
      urlSeo = this.urls.find(
        (url) => url.url === this.router.url.split('?')[0]
      );
    }

    if (!urlSeo) {
      return;
    }

    if (urlSeo) {
      if (urlSeo.title)
        this.title.setTitle(urlSeo.title);
      else
        this.title.setTitle('Doctor GO')

      if (urlSeo.description) {
        this.meta.updateTag({ name: 'description', content: urlSeo.description });
      } else {
        this.meta.removeTag('description');
      }

      if (urlSeo.keywords) {
        this.meta.updateTag({ name: 'keywords', content: urlSeo.keywords })
      }


      if (urlSeo.canonical)
        this.setLinkForCanonicalURL(urlSeo);
      else
        this.doc.querySelectorAll('[rel="canonical"]').forEach((el) => el.remove());

      if (urlSeo.microdata) {
        this.setMicroData(urlSeo);
      } else {
        this.doc
          .querySelectorAll('[type="application/ld+json"]')
          .forEach((el) => el.remove());
      }


      if (urlSeo.index !== undefined && urlSeo.follow !== undefined)
        this.setRobots(urlSeo);
      else
        this.meta.removeTag('robots');
    }





    // if (isPlatformBrowser(this.platformId)) {
    switch (urlSeo.url) {
      case '/?cliente=false':
        urlSeo.openGraph = {
          'og:title': 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es',
          'og:description':
            'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'og:type': 'website',
          'twitter:site': '@yoigo',
          'twitter:creator': '@yoigo',
          'twitter:description':
            'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'twitter:title':
            'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/contratacion':
        urlSeo.openGraph = {
          'og:title': 'DoctorGO: Contratación Seguro Pack Familiar',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es',
          'og:description':
            'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'og:type': 'website',
          'twitter:site': '@yoigo',
          'twitter:creator': '@yoigo',
          'twitter:description':
            'DoctorGO el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'twitter:title': 'DoctorGO: Contratación Seguro Pack Familiar',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '//programas-salud':
        urlSeo.openGraph = {
          'og:title': 'Descubre por qué contratar DoctorGO de Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/programas-salud',
          'og:description':
            'Llega DoctorGO, el nuevo servicio de salud a lo Yoigo. Servicio inmediato con grandes especialistas. Descubre nuestra oferta y disfruta de ¡1 mes GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Llega DoctorGO, el nuevo servicio de salud a lo Yoigo. Servicio inmediato con grandes especialistas. Descubre nuestra oferta y disfruta de ¡1 mes GRATIS!',
          'twitter:title': 'Descubre por qué contratar DoctorGO de Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;

      case '//analitica-sangre-y-orina':
        urlSeo.openGraph = {
          "og:title": "Analítica de sangre | Analítica de orina | DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/analitica-sangre-y-orina",
          "og:description": "¿Necesitas una analítica completa? Gratis con DoctorGO una analítica de sangre y orina para conocer el estado de tu salud y detectar posibles enfermedades.",
          "og:type": "service",
          "twitter:site": "DoctorGo_",
          "twitter:creator": "DoctorGo_",
          "twitter:description": "¿Necesitas una analítica completa? Gratis con DoctorGO una analítica de sangre y orina para conocer el estado de tu salud y detectar posibles enfermedades.",
          "twitter:title": "Analítica de sangre | Analítica de orina | DoctorGO",
          "twitter:card": "summary",
          "og:image": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
          "twitter:image": "https://doctorgo.es/assets/images/doctor-go-logo.svg",
          "og:locale": "ES"
        };
        break;

      case '/telefarmacia-y-receta-electronica':
        urlSeo.openGraph = {
          'og:title':
            'Telefarmacia. Consigue tu receta electronica con DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url':
            'https://doctorgo.es/telefarmacia-y-receta-electronica',
          'og:description':
            'Con DoctorGO tendrás siempre tu receta electrónica en el móvil y servicio de telefarmacia GRATIS para llevarte tus medicamentos sin salir de casa.',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Con DoctorGO tendrás siempre tu receta electrónica en el móvil y servicio de telefarmacia GRATIS para llevarte tus medicamentos sin salir de casa.',
          'twitter:title':
            'Telefarmacia. Consigue tu receta electronica con DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/alergologo':
        urlSeo.openGraph = {
          'og:title':
            'Alergólogo Online. Alergología sin salir de casa | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/alergologo',
          'og:description':
            '¿Necesitas una cita con un alergólogo online? Aquí puedes hacerlo de una forma rápida y sencilla. ✓Alergología 100% online y 24/7. El primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            '¿Necesitas una cita con un alergólogo online? Aquí puedes hacerlo de una forma rápida y sencilla. ✓Alergología 100% online y 24/7. El primer mes ¡GRATIS!',
          'twitter:title':
            'Alergólogo Online. Alergología sin salir de casa | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/cardiologo':
        urlSeo.openGraph = {
          'og:title': 'Tu Cardiólogo Online Privado de DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/cardiologo',
          'og:description':
            'Si necesitas una cita con un cardiólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Cardiología 100% online y 24/7. El primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas una cita con un cardiólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Cardiología 100% online y 24/7. El primer mes ¡GRATIS!',
          'twitter:title': 'Tu Cardiólogo Online Privado de DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/dermatologo':
        urlSeo.openGraph = {
          'og:title': 'Dermatólogo Online y sin salir de casa | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/dermatologo',
          'og:description':
            'Si necesitas una cita con un dermatólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Dermatología 100% online y 24/7. Primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas una cita con un dermatólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Dermatología 100% online y 24/7. Primer mes ¡GRATIS!',
          'twitter:title': 'Dermatólogo Online y sin salir de casa | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/ginecologo':
        urlSeo.openGraph = {
          'og:title':
            'Ginecólogo Online. Ginecología sin salir de casa | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/ginecologo',
          'og:description':
            'Si necesitas una consulta con un ginecólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Ginecología 100% online y 24/7. Primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas una consulta con un ginecólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Ginecología 100% online y 24/7. Primer mes ¡GRATIS!',
          'twitter:title':
            'Ginecólogo Online. Ginecología sin salir de casa | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/psicologo':
        urlSeo.openGraph = {
          'og:title': 'Psicólogo Online ¡Primer mes gratis! | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/psicologo',
          'og:description':
            'Si necesitas cita con un psicólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Psicología100% online y 24/7. El primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas cita con un psicólogo online, aquí puedes hacerlo de una forma rápida y sencilla. ✓Psicología100% online y 24/7. El primer mes ¡GRATIS!',
          'twitter:title': 'Psicólogo Online ¡Primer mes gratis! | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/sexologo':
        urlSeo.openGraph = {
          'og:title': 'Sexólogo Online. Sexología sin salir de casa | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/sexologo',
          'og:description':
            'Si necesitas cita con un sexólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Sexologia 100% online y 24/7. El primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas cita con un sexólogo online, aquí puedes hacerlo de forma rápida y sencilla. ✓Sexologia 100% online y 24/7. El primer mes ¡GRATIS!',
          'twitter:title':
            'Sexólogo Online. Sexología sin salir de casa | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/':
        urlSeo.openGraph = {
          'og:title': 'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es',
          'og:description':
            'DoctorGo el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'og:type': 'website',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'DoctorGo el nuevo servicio de salud y App de telemedicina de Yoigo. Con acceso a diferentes especialistas sin moverte de casa 100% online y 24/7.',
          'twitter:title':
            'DoctorGO: Servicio de Salud y Telemedicina de Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/pediatra':
        urlSeo.openGraph = {
          'og:title':
            'Pediatra online. Consulta con un pediatra en línea | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/pediatra',
          'og:description':
            '¿Necesitas una cita con el pediatra online? aquí puedes hacerlo de una forma rápida y sencilla. ✓Tu pediatra 100% online y 24/7 ¡Disfruta ya de 1 mes GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            '¿Necesitas una cita con el pediatra online? aquí puedes hacerlo de una forma rápida y sencilla. ✓Tu pediatra 100% online y 24/7 ¡Disfruta ya de 1 mes GRATIS!',
          'twitter:title':
            'Pediatra online. Consulta con pediatra en línea | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/medico-general-online':
        urlSeo.openGraph = {
          'og:title': 'Médico Online | Especialistas médicos online | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/medico-general-online',
          'og:description':
            'Tu médico online a un clic. Con el servicio de telemedicina de DoctorGO cuentas con especialistas médicos las 24h por chat o videollamada ¡sin salir de casa!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Tu médico online a un clic. Con el servicio de telemedicina de DoctorGO cuentas con especialistas médicos las 24h por chat o videollamada ¡sin salir de casa!',
          'twitter:title':
            'Médico Online | Especialistas médicos online | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/entrenador-personal-online':
        urlSeo.openGraph = {
          'og:title':
            'Entrenador Personal Online. Primer mes ¡GRATIS! | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url':
            'https://doctorgo.es/entrenador-personal-online',
          'og:description':
            'Tu entrenador personal online a golpe de clic con el nuevo servicio de Yoigo Salud. → Accede a los mejores profesionales online. ¡1 mes GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Tu entrenador personal online a golpe de clic con el nuevo servicio de Yoigo Salud. → Accede a los mejores profesionales online. ¡1 mes GRATIS!',
          'twitter:title':
            'Entrenador Personal Online. Primer mes ¡GRATIS! | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/nutricionista':
        urlSeo.openGraph = {
          'og:title': 'Nutricionista Online. ¡Primer mes gratis! | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/nutricionista',
          'og:description':
            'Si necesitas un nutricionista online, aquí lo tienes, de una forma rápida y sencilla. ✓ Tu experto en nutrición 100% online y 24/7. El primer mes ¡GRATIS!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Si necesitas un nutricionista online, aquí lo tienes, de una forma rápida y sencilla. ✓ Tu experto en nutrición 100% online y 24/7. El primer mes ¡GRATIS!',
          'twitter:title':
            'Nutricionista Online. ¡Primer mes gratis! | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/entrenamiento-virtual':
        urlSeo.openGraph = {
          "og:title": "Entrenamiento con vídeos de la plataforma Entrena Virtual",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/entrenamiento-virtual",
          "og:description": "Con DoctorGO puedes entrenar en casa con la plataforma Entrena Virtual, cientos de vídeos y ejercicios variados según la área del cuerpo que quieras trabajar.",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "Con DoctorGO puedes entrenar en casa con la plataforma Entrena Virtual, cientos de vídeos y ejercicios variados según la área del cuerpo que quieras trabajar.",
          "twitter:title": "Entrenamiento con vídeos de la plataforma Entrena Virtual",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES",
        };
        break;
      case '/receta-medica-electronica':
        urlSeo.openGraph = {
          'og:title': 'Telefarmacia. Pide tu receta electrónica - DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/receta-medica-electronica',
          'og:description':
            'Recibe tus recetas médicas online directamente en el móvil. Fácil y rápido, tus recetas electrónicas en tu bolsillo. ✓ Telefarmacia con Yoigo.',
          'og:type': 'product',
          'twitter:site': '@yoigo',
          'twitter:creator': '@yoigo',
          'twitter:description':
            'Recibe tus recetas médicas online directamente en el móvil. Fácil y rápido, tus recetas electrónicas en tu bolsillo. ✓ Telefarmacia con Yoigo.',
          'twitter:title':
            'Telefarmacia. Pide tu receta electrónica - DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '//test-anticuerpos-covid':
        urlSeo.openGraph = {
          'og:title':
            'Test Anticuerpos Covid | Test Coronavirus Online | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/test-anticuerpos-covid',
          'og:description':
            '¿Necesitas un test de anticuerpos Covid? Pídelo de forma online, rápido y sencillo. Test antígenos después de la vacuna ¡Pide ya tu prueba!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            '¿Necesitas un test de anticuerpos Covid? Pídelo de forma online, rápido y sencillo. Test antígenos después de la vacuna ¡Pide ya tu prueba!',
          'twitter:title':
            'Test Anticuerpos Covid | Test Coronavirus Online | DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/ayuda':
      case '/ayuda/registro':
      case '/ayuda/telemedicina':
      case '/ayuda/otros-servicios':
        urlSeo.openGraph = {
          'og:title':
            'Preguntas sobre el servicio de DoctorGO | Ayuda DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/ayuda',
          'og:description':
            '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            '¿Tienes alguna duda sobre el servicio que ofrece DoctorGO de Yoigo? Resuelve aquí todas tus preguntas. ¡Te esperamos!',
          'twitter:title':
            'Preguntas sobre el servicio de DoctorGO | Ayuda DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/seguros-medicos':
        urlSeo.openGraph = {
          'og:title': 'DoctorGo: el nuevo seguro médico sin copagos de Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/seguros-medicos',
          'og:description':
            'DoctorGO es el seguro de salud sin copago con consultas ilimitadas y médico online 24h. Seguro familiar de salud para todos ¡Sin cuestionarios!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'DoctorGO es el seguro de salud sin copago con consultas ilimitadas y médico online 24h. Seguro familiar de salud para todos ¡Sin cuestionarios!',
          'twitter:title':
            'DoctorGo: el nuevo seguro médico sin copagos de Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/seguros-medicos/dental':
        urlSeo.openGraph = {
          'og:title':
            'Seguro dental DoctorGO: Mejora tu salud dental con Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/seguros-medicos/dental',
          'og:description':
            'El seguro dental de DoctorGO de amplia cobertura para ti y tu familia. Hazte una revisión dental hoy mismo ¡No esperes más para cuidar de tu salud dental!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'El seguro dental de DoctorGO de amplia cobertura para ti y tu familia. Hazte una revisión dental hoy mismo ¡No esperes más para cuidar de tu salud dental!',
          'twitter:title':
            'Seguro dental DoctorGO: Mejora tu salud dental con Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/seguros-medicos/ginecologico':
        urlSeo.openGraph = {
          'og:title': 'DoctorGo: el nuevo seguro ginecológico de Yoigo',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/seguros-medicos/ginecologico',
          'og:description':
            'Con el seguro ginecológico de DoctorGO tendrás consultas ilimitadas para resolver todas tus dudas. Incluye revisión anual completa ¡Pide tu cita ya!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Con el seguro ginecológico de DoctorGO tendrás consultas ilimitadas para resolver todas tus dudas. Incluye revisión anual completa ¡Pide tu cita ya!',
          'twitter:title': 'DoctorGo: el nuevo seguro ginecológico de Yoigo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/seguros-medicos/ninos':
        urlSeo.openGraph = {
          'og:title': 'Nuevo seguro médico para niños sin copagos de DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/seguros-medicos/ninos',
          'og:description':
            'Con el seguro médico de niños de DoctorGO tendrás consultas ilimitadas con un pediatra y ¡Sin Esperas! Seguro de salud para niños SIN COPAGO de Yoigo.',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Con el seguro médico de niños de DoctorGO tendrás consultas ilimitadas con un pediatra y ¡Sin Esperas! Seguro de salud para niños SIN COPAGO de Yoigo.',
          'twitter:title':
            'Nuevo seguro médico para niños sin copagos de DoctorGO',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;
      case '/seguros-medicos/oftalmologia':
        urlSeo.openGraph = {
          'og:title': 'Seguro de Oftalmología con Revisión Incluida | DoctorGO',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/seguros-medicos/oftalmologia',
          'og:description':
            'Con el seguro de oftalmologia de DoctorGO, tendrás la revisión oftalmológica gratis para ti y toda tu familia ¡Pide cita hoy mismo!',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Con el seguro de oftalmologia de DoctorGO, tendrás la revisión oftalmológica gratis para ti y toda tu familia ¡Pide cita hoy mismo!',
          'twitter:title':
            'Seguro de Oftalmología con Revisión Incluida | DoctorGo',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;

      case '/seguros-medicos/dermatologia':
        urlSeo.openGraph = {
          "og:title": "Consultas de dermatología incluidas en tu seguro DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/seguros-medicos/dermatologia",
          "og:description": "Consultas de dermatología incluidas en tu seguro de DoctorGO. Consultas presenciales ilimitadas con dermatólogos en los centros médicos de DKV.",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "Consultas de dermatología incluidas en tu seguro de DoctorGO. Consultas presenciales ilimitadas con dermatólogos en los centros médicos de DKV.",
          "twitter:title": "Consultas de dermatología incluidas en tu seguro DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES"
        };
        break;

      case '/seguros-medicos/traumatologia':
        urlSeo.openGraph = {
          "og:title": "Consultas con el traumatólogo incluidas en tu seguro DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/seguros-medicos/traumatologia",
          "og:description": "El seguro de DoctorGO incluye consultas y revisiones con un traumatólogo privado de los centros médicos DKV. ¡Pide cita ahora y hazte una revisión!",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "El seguro de DoctorGO incluye consultas y revisiones con un traumatólogo privado de los centros médicos DKV. ¡Pide cita ahora y hazte una revisión!",
          "twitter:title": "Consultas con el traumatólogo incluidas en tu seguro DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES"

        };
        break;

      case '/seguros-medicos/enfermeria':
        urlSeo.openGraph = {
          "og:title": "Consultas de enfermería y curas incluidas con tu seguro DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/seguros-medicos/enfermeria",
          "og:description": "Consultas y revisiones de enfermeria incluidas en el seguro médico de DoctorGO. Servicios de enfermeria como curas ambulatorias en centros médicos de DKV.",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "Consultas y revisiones de enfermeria incluidas en el seguro médico de DoctorGO. Servicios de enfermeria como curas ambulatorias en centros médicos de DKV.",
          "twitter:title": "Consultas de enfermería y curas incluidas con tu seguro DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES"
        };
        break;

      case '/seguros-medicos/medicina-general':
        urlSeo.openGraph = {
          "og:title": "Consultas de atención primaria incluidas en tu seguro DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/seguros-medicos/medicina-general",
          "og:description": "Consultas ilimitadas con un médico general privado en tu seguro DoctorGO. Citas sin esperas con médicos de atención primaria de los centros médicos de DKV.",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "Consultas ilimitadas con un médico general privado en tu seguro DoctorGO. Citas sin esperas con médicos de atención primaria de los centros médicos de DKV.",
          "twitter:title": "Consultas de atención primaria incluidas en tu seguro DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES"
        };
        break;

      case '/cuadro-medico-dkv':
        urlSeo.openGraph = {
          'og:title': 'Cuadro Médico de DoctorGO - Garantía DKV',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/cuadro-medico-dkv',
          'og:description':
            'Encuentra en el cuadro médico de DoctorGO x DKV el especialista y centro médico que necesitas cerca de ti. Con la garantía y profesionalidad de DKV.',
          'og:type': 'service',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Encuentra en el cuadro médico de DoctorGO x DKV el especialista y centro médico que necesitas cerca de ti. Con la garantía y profesionalidad de DKV.',
          'twitter:title': 'Cuadro Médico de DoctorGO - Garantía DKV',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };
        break;

      case '/sorteos':
        urlSeo.openGraph = {
          'og:title': 'DoctorGO sortea un iPhone 13 para tus consultas médicas',
          'og:site_name': 'DoctorGO',
          'og:url': 'https://doctorgo.es/sorteos',
          'og:description':
            'Entra ahora en DoctorGO y llévate un iPhone 13 que sorteamos para que puedas acceder a todas tus consultas médicas online y servicios de telemedicina.',
          'og:type': 'website',
          'twitter:site': '@DoctorGo_',
          'twitter:creator': '@DoctorGo_',
          'twitter:description':
            'Entra ahora en DoctorGO y llévate un iPhone 13 que sorteamos para que puedas acceder a todas tus consultas médicas online y servicios de telemedicina.',
          'twitter:title':
            'DoctorGO sortea un iPhone 13 para tus consultas médicas',
          'twitter:card': 'summary',
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'og:locale': 'ES',
        };

        break;
      case '/contacto':
        urlSeo.openGraph = {
          "og:title": "Contacto | DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/contacto",
          "og:description": "Contacta con DoctorGO por teléfono, mail o redes sociales para resolver tus dudas.",
          "og:type": "product",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "Contacta con DoctorGO por teléfono, mail o redes sociales para resolver tus dudas.",
          "twitter:title": "Contacto | DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES"
        };
        break;
      case '/dentista-online':
        urlSeo.openGraph = {
          "og:title": "Dentista Online. Tu denstista sin salir de casa | DoctorGO",
          "og:site_name": "DoctorGO",
          "og:url": "https://doctorgo.es/dentista-online",
          "og:description": "¿Necesitas cita con un dentista online? Con el servicio de Mi Vitaldent de DoctorGO tienes consultas ilimitadas con dentistas mediante Chat y Videollamadas.",
          "og:type": "service",
          "twitter:site": "@DoctorGo_",
          "twitter:creator": "@DoctorGo_",
          "twitter:description": "¿Necesitas cita con un dentista online? Con el servicio de Mi Vitaldent de DoctorGO tienes consultas ilimitadas con dentistas mediante Chat y Videollamadas.",
          "twitter:title": "Dentista Online. Tu denstista sin salir de casa | DoctorGO",
          "twitter:card": "summary",
          'og:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          'twitter:image': `${environment.metaImageBaseUrl}/assets/images/doctor-go-logo.svg`,
          "og:locale": "ES",
        }
        break;
    }
    // }
    this.setOpenGraph(urlSeo);
  }

  setRobots(urlSeo) {
    if (environment.environment === 'prod') {
      if (!urlSeo.index && !urlSeo.follow)
        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
      if (urlSeo.index && !urlSeo.follow)
        this.meta.updateTag({ name: 'robots', content: 'index, nofollow' });
      if (!urlSeo.index && urlSeo.follow)
        this.meta.updateTag({ name: 'robots', content: 'noindex, follow' });
      if (urlSeo.index && urlSeo.follow)
        this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    } else {
      this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }

  setLinkForCanonicalURL(urlSeo: any) {
    this.doc.querySelectorAll('[rel="canonical"]').forEach((el) => el.remove());
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    // this.doc.head.appendChild(link);
    this.titleElement.parentNode.insertBefore(
      link,
      this.titleElement.nextSibling
    );
    link.setAttribute('href', urlSeo.canonical);
  }

  public setMicroData(urlSeo: any): void {
    this.doc
      .querySelectorAll('[type="application/ld+json"]')
      .forEach((el) => el.remove());
    if (urlSeo.microdata) {
      let script = this.doc.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(urlSeo.microdata);
      // this.doc.head.appendChild(script);
      this.titleElement.parentNode.insertBefore(
        script,
        this.titleElement.nextSibling
      );
    }
  }

  public setOpenGraph(urlSeo: any) {
    for (let [key, value] of Object.entries(urlSeo.openGraph)) {
      this.meta.updateTag({ property: key, content: String(value) });
    }
    const metas = this.doc.querySelectorAll('meta');
    let metasToArr = Array.from(metas);
    metasToArr = metasToArr.reverse();

    metasToArr.forEach((element) => {
      this.titleElement.parentNode.insertBefore(
        element,
        this.titleElement.nextSibling
      );
    });
  }
}
