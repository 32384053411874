import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class TeleasistenciaService {
    constructor(public http: HttpClient) {}

    sendPhoneData(phone: number, name: string){
        return this.http.post(`api/teleasistencia/info/`, {phone, name})
        .toPromise();
    }
}
