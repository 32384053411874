import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { ActivationEnd, ActivationStart, ChildActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

  @Input() navbarOpen = false;
  public data = [];
  public display = false;

  blackList = [
    '/',
  ]

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) public platformId: string
  ) { }

  ngOnChanges() {
    this.display = !this.navbarOpen && this.data.length > 0;
  }

  ngAfterViewInit(){
    this.startToSubscribe()
  }

  startToSubscribe() {
    this.router.events.subscribe(
      (event: any) => {

        if (event instanceof ActivationStart) {
          this.data = [];
        }

        if (event instanceof ActivationEnd) {
          if (
            event.snapshot.data.hasOwnProperty('title')
          && !this.blackList.includes(this.router.url.split('?')[0])) {
            if (event.snapshot.url.length > 0) {
              this.display = true;
              this.data.unshift({ title: event?.snapshot.data?.title || '', route: event.snapshot.url[0].path });
              return
            }
          } else {
            this.display = false;
            this.data = [];
            return
          }
        }

      });
  }

}
