import { Ecommerce } from "./ecommerce";
import { General } from "./general";
import { GeneralEventModel } from "./generaleventmodel";

export class EventDetailLeadError {
  content_type: string;
  type: string;

  constructor(partial: Partial<EventDetailLeadError> = null){
    Object.assign(this, partial);
  }
}
