import { Ecommerce } from "./ecommerce";
import { General } from "./general";
import { GeneralEventModel } from "./generaleventmodel";

export class EventDetail {
  content_type: string;
  outbound?: boolean;

  constructor(partial: Partial<EventDetail> = null){
    Object.assign(this, partial);
  }
}
