import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-sign-up-footer',
  templateUrl: './sign-up-footer.component.html',
  styleUrls: ['./sign-up-footer.component.scss'],
})
export class SignUpFooterComponent implements OnInit {
  @Input() hasCode = false;
  @Input() linkText: string;
  @Input() linkUrl: string;
  @Input() buttonText: string;
  @Input() buttonValid: boolean;
  @Input() checkboxes: {
    text: string; value: boolean; required: boolean, control?: AbstractControl
   }[];

  @Output() buttonClick = new EventEmitter();
  @Output() linkClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public requiredCheckboxes() {
    let ret = true;
    this.checkboxes.forEach((r) => {
      if (r.required && !r.value) {
        ret = false;
      }
    });
    return ret;
  }

  onChange(event: MatCheckboxChange, check: any) {
    check.value = event.checked;
  }
}
