export enum PriceSelector {
    DOCTOR_INSURANCE = 'DOCTOR_INSURANCE',
    DOCTOR_APP = 'DOCTOR_APP',
    DOCTOR_INSURANCE_NO_CUSTOMER = 'DOCTOR_INSURANCE_NO_CUSTOMER',
    DOCTOR_APP_NO_CUSTOMER = 'DOCTOR_APP_NO_CUSTOMER',
    DKV_COMPLET_OLD = 'DKV_COMPLET_OLD',
    DKV_COMPLET_NEW = 'DKV_COMPLET_NEW',
    DKV_CLASIC_OLD = 'DKV_CLASIC_OLD',
    DKV_CLASIC_NEW = 'DKV_CLASIC_NEW',
    DKV_ELITE_OLD = 'DKV_ELITE_OLD',
    DKV_ELITE_NEW = 'DKV_ELITE_NEW'
}