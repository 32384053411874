import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

export interface Title {
  title: string;
}

export class BreadcrumbResolver implements Resolve<Title> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Title> {

    return of({ title: 'Seguro Médico Familiar DKV / Coberturas' });

  }
}
