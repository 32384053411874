<div class="header">
  <div class="title">{{title[onboardingStateService.userType].title}}</div>
  <div class="body">
    <div>
      {{title[onboardingStateService.userType].subtitle}}
    </div>
  </div>
</div>
<app-onboarding-blue-box class="blue-box">
  <div class="item">
    <div>
      <img src="../../../../../../assets/images/onboarding/check.svg" alt="N/A" />
    </div>
    <div class="item-text">
      <div class="bolded">
        <b>{{onboardingStateService.getMonth(true)}}</b> <span class="light-blue">Gratis</span>
      </div>
    Disfruta de todos los servicios de DoctorGO totalmente gratis
    </div>
  </div>
  <div class="item">
    <div>
      <img src="../../../../../../assets/images/onboarding/check.svg" alt="N/A" />
    </div>
    <div class="item-text">
      <div class="bolded">
        <b>Si decides mantenerlo,</b>
        <span class="light-blue"> {{onboardingStateService.getPrice()}}€/mes</span>
      </div>

      {{
        onboardingStateService.isYoigo.getValue() === false
          ? 'El servicio se cobra mes a mes.'
          : 'Además, 2€ de descuento que incluiremos en tu factura de teléfono para que te sea más cómodo.'
      }}

    </div>
  </div>
  <div class="item">
    <div>
      <img src="../../../../../../assets/images/onboarding/check.svg" alt="N/A" />
    </div>
    <div class="item-text">
      <div class="bolded">
        <b>Y sin compromiso</b>
      </div>
      Puedes cancelar la suscripción cuando quieras, sin ninguna penalización.
    </div>
  </div>
</app-onboarding-blue-box>

<app-onboarding-form-error *ngIf="error">{{error}}</app-onboarding-form-error>



<app-sign-up-footer buttonText="CONFIRMAR Y ACCEDER" [buttonValid]="true"
  (buttonClick)="continueDebouncer.next()"></app-sign-up-footer>
