import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FunctionUtilsService {

  protected isIndexPage: boolean;
  private hasDKVStyle = false;
  subIsIndex = new Subject;

  popupIsOpen = new Subject<boolean>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
  ) {

  }

  setIsIndexPage(isIndex: boolean): void {
    this.isIndexPage = isIndex;
    this.getIsIndexPage();
  }

  getIsOpen(): Observable<boolean> {
    return this.popupIsOpen.asObservable()
  }

  getIsIndexPage(): void {
    this.subIsIndex.next(this.isIndexPage);
  }

  getIsMobile() {
    return (
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/Android/i)) ||
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/webOS/i)) ||
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/iPhone/i)) ||
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/iPod/i)) ||
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/iPad/i)) ||
      (isPlatformBrowser(this.platformId) && navigator.userAgent.match(/BlackBerry/i))
    ) ? true : false;
  }

  getWidth() {
    return isPlatformBrowser(this.platformId) && window.innerWidth
  }



  hireOnline(box: number) {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof window["bysideWebcare_triggerevent"] == 'function') {
        window["bysideWebcare_triggerevent"](box); // 189
      }
    }
  }


  xml2json(srcDOM) {
    let children = [...srcDOM.children];

    // base case for recursion.
    if (!children.length) {
      return srcDOM.innerHTML
    }

    // initializing object to be returned.
    let jsonResult = {};

    for (let child of children) {

      // checking is child has siblings of same name.
      let childIsArray = children.filter(eachChild => eachChild.nodeName === child.nodeName).length > 1;

      // if child is array, save the values as array, else as strings.
      if (childIsArray) {
        if (jsonResult[child.nodeName] === undefined) {
          jsonResult[child.nodeName] = [this.xml2json(child)];
        } else {
          jsonResult[child.nodeName].push(this.xml2json(child));
        }
      } else {
        jsonResult[child.nodeName] = this.xml2json(child);
      }
    }

    return jsonResult;
  }


}

