import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionUtilsService } from 'src/app/services/function-utils.service';

@Component({
  selector: 'app-invitation-code',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationCodeComponent implements OnInit {

  constructor(private utils: FunctionUtilsService, private router: Router) { }

  ngOnInit(): void {

    if (!this.utils.getIsMobile())
      this.router.navigateByUrl('/')

  }

}
